export enum optionsNewWidgetComponentIds {
  TopContainer = '#topContainer',
  Repeater = '#repeater',
}

export enum optionsNewWidgetItemComponentIds {
  MultiStateBox = '#multiStateBox',
  ColorOption = '#colorOption1',
  DropdownOption = '#dropdownOption1',
}

export enum OptionsWidgetEvents {
  Change = 'change',
}

export enum OptionsStates {
  ColorState = 'colorState',
  DropdownState = 'dropdownState',
}
