import {WidgetBuilder, ConnectedComponentsBuilder} from '../../editor.app.types';
import {NavigationWidgetComponentIds as ComponentIds} from '../../../components/navigationWidget/config/constants';
import {getRole} from '../global-design/getRole';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {stylableButtonsPanel} from '../../panels/stylableButtonPanels';
import {setComponentsAsUnselectable} from './helpers/setComponentsAsUnselectable';

export const setNavigationButtonsStageBehavior = (wb: WidgetBuilder, t: ILocaleKeys): void => {
  wb.configureConnectedComponents(getRole(ComponentIds.prevIcon), (innerWidgetBuilder: ConnectedComponentsBuilder) => {
    innerWidgetBuilder.set({displayName: t.productPage.prevNextWidget.previousButton.displayName()});
    stylableButtonsPanel.setPanelWithHiddenLink(innerWidgetBuilder);
    innerWidgetBuilder.behavior().set({closed: {selectable: true}});
  })
    .configureConnectedComponents(getRole(ComponentIds.nextIcon), (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.prevNextWidget.nextButton.displayName()});
      stylableButtonsPanel.setPanelWithHiddenLink(innerWidgetBuilder);
      innerWidgetBuilder.behavior().set({closed: {selectable: true}});
    })
    .configureConnectedComponents(getRole(ComponentIds.divider), (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.prevNextWidget.divider.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.container), (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerWidgetBuilder.set({displayName: ''});
    });
  setComponentsAsUnselectable([ComponentIds.divider, ComponentIds.container], wb);
};
