import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {WidgetBuilder, ConnectedComponentsBuilder} from '../../editor.app.types';
import {getRole} from '../global-design/getRole';
import {
  SubscriptionWidgetComponentIds as ComponentIds,
  SubscriptionSelectableRepeaterItemComponentIds as RepeaterComponentIds,
  SubscriptionRepeaterContainers,
} from '../../../components/subscriptionWidget/config/constants';
import {setRichTextSettingsControls, setRichTextSettingsSections} from '../../panels/richTextPanels';
import {setComponentsAsUnselectable} from './helpers/setComponentsAsUnselectable';

export const setSubscriptionInnerComponentsStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  const innerBuilderForTextComponents = (innerWidgetBuilder: ConnectedComponentsBuilder, displayName: string) => {
    setRichTextSettingsSections(innerWidgetBuilder);
    setRichTextSettingsControls(innerWidgetBuilder);
    innerWidgetBuilder.behavior().set({
      closed: {
        selectable: false,
        hideFromHierarchy: true,
      },
    });
    innerWidgetBuilder.set({displayName});
  };

  setComponentsAsUnselectable(
    [
      SubscriptionRepeaterContainers.PriceContainer,
      SubscriptionRepeaterContainers.ItemContainer,
      RepeaterComponentIds.SelectableContainer1,
      RepeaterComponentIds.SelectableContainerInput1,
      ComponentIds.ErrorSection,
      ComponentIds.Repeater,
      ComponentIds.TopContainer,
      ComponentIds.SelectableRepeater,
    ],
    widgetBuilder,
  );
  widgetBuilder.configureConnectedComponents(
    getRole(ComponentIds.Label),
    (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerBuilderForTextComponents(innerWidgetBuilder, t.productPage.subscriptionsWidget.title.displayName());
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(ComponentIds.ErrorMessage),
    (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerBuilderForTextComponents(innerWidgetBuilder, '');
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(RepeaterComponentIds.Title),
    (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerBuilderForTextComponents(innerWidgetBuilder, t.productPage.subscriptionsWidget.optionName.displayName());
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(RepeaterComponentIds.Frequency),
    (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerBuilderForTextComponents(
        innerWidgetBuilder,
        t.productPage.subscriptionsWidget.frequencyDuration.displayName(),
      );
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(RepeaterComponentIds.Price),
    (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerBuilderForTextComponents(innerWidgetBuilder, t.productPage.subscriptionsWidget.price.displayName());
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(RepeaterComponentIds.Tagline),
    (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerBuilderForTextComponents(innerWidgetBuilder, t.productPage.subscriptionsWidget.tagline.displayName());
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(RepeaterComponentIds.SelectableContainerInput1),
    (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerWidgetBuilder.behavior().set({
        closed: {
          selectable: false,
          hideFromHierarchy: true,
        },
      });
      innerWidgetBuilder.set({displayName: t.productPage.subscriptionsWidget.radioButton.displayName()});
    },
  );
};
