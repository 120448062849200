import {WidgetBuilder} from '../../editor.app.types';
import {infoSectionDesignBuilderFn} from '../global-design/infoSections.widget.design';
import {helpIds} from '../../../constants/app';
import {ActionIds} from '../../../constants/gfpp';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';

export const setInfoSectionWidgetStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder.set({displayName: t.productPage.infoSectionWidget.displayName()});

  widgetBuilder
    .gfpp()
    .set('layout', {actionId: ActionIds.INFO_SECTIONS_LAYOUT_DESKTOP})
    .set('mainAction1', {label: t.productPage.infoSectionWidget.gfpp.design(), actionType: 'design', actionId: ''})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('connect', {behavior: 'HIDE'})
    .set('add', {behavior: 'HIDE'})
    .set('help', {id: helpIds.INFO_SECTIONS});

  widgetBuilder
    .gfpp('mobile')
    .set('layout', {actionId: ActionIds.INFO_SECTIONS_LAYOUT_DESKTOP})
    .set('mainAction1', {behavior: 'HIDE'})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('help', {id: helpIds.INFO_SECTIONS})
    .set('settings', {behavior: 'HIDE'});

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => infoSectionDesignBuilderFn(widgetDesignBuilder, t));
};
