import {createBlocksModel} from '@wix/yoshi-flow-editor';

export default createBlocksModel({
  widgetName: 'skuWidget',
  props: {
    sku: {
      type: 'text',
      defaultValue: undefined,
    },
    label: {
      type: 'text',
      defaultValue: undefined,
    },
  },
  methods: {},
  events: {},
});
