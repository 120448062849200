import {ComponentRef, FlowAPI, FlowEditorSDK, ViewportType} from '../../../editor.app.types';
import {rootWidgetPresetsIds} from '../../../../components/rootWidget/config/presets';

export const handleWidgetReset = async (editorSDK: FlowEditorSDK, flowAPI: FlowAPI, componentRef: ComponentRef) => {
  const isRTL = flowAPI.environment.isSiteLanguageRTL;

  switch (isRTL) {
    case true:
      updateWidgetPreset(
        editorSDK,
        componentRef,
        rootWidgetPresetsIds['RTL 2 columns - 66:33 - right align'],
        'DESKTOP',
      );
      updateWidgetPreset(
        editorSDK,
        componentRef,
        rootWidgetPresetsIds['RTL 2 columns - 66:33 - right align'],
        'TABLET',
      );
      updateWidgetPreset(editorSDK, componentRef, rootWidgetPresetsIds['RTL column / mobile'], 'MOBILE');
      break;
    case false:
      updateWidgetPreset(editorSDK, componentRef, rootWidgetPresetsIds['2 columns - 66:33 - left align'], 'DESKTOP');
      updateWidgetPreset(editorSDK, componentRef, rootWidgetPresetsIds['2 columns - 66:33 - left align'], 'TABLET');
      updateWidgetPreset(editorSDK, componentRef, rootWidgetPresetsIds['1 column / mobile'], 'MOBILE');
  }
};

const updateWidgetPreset = (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
  presetId: string,
  viewport: ViewportType,
) =>
  editorSDK.application.appStudioWidgets.changePreset('', {
    context: {viewport},
    componentRef,
    stylePresetId: presetId,
    layoutPresetId: presetId,
  });
