import {WidgetBuilder} from '../../../editor.app.types';

export const hideInnerWidgetMobileGfpp = (widgetBuilder: WidgetBuilder): void => {
  widgetBuilder
    .gfpp('mobile')
    .set('mainAction1', {behavior: 'HIDE'})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('layout', {behavior: 'HIDE'})
    .set('settings', {behavior: 'HIDE'})
    .set('hide', {behavior: 'HIDE'});
};
