export enum CtaButtonsWidgetComponentIds {
  TopContainer = '#box1',
  MultiStateBox = '#multiStateBox',
  AddToCartBtn = '#addToCartButton',
  BuyNowBtn = '#buyNowButton',
  SubscribeNowBtn = '#subscribeNowButton',
  NotifyMeBtn = '#notifyMeButton',
  OutOfStockBtn = '#oosButton',
  WishlistSlotPlaceholder = '#slotsPlaceholder1',
  PreOrderButton = '#PObutton',
  PreOrderNowButton = '#PONbutton',
  PreOrderMessage = '#text2',
}

export enum CtaContainersComponentsIds {
  AddToCartBox = '#addBox',
  BuyNowBox = '#buyBox',
  SubscribeNowBox = '#Subscribe',
  NotifyMeBox = '#NotifyMe',
  OutOfStockBox = '#OOS',
  SlotPlaceholderBox = '#slotBox',
  AddBuy = '#AddBuy',
  AddBuyLayout = '#addBuyLayout',
  CTAandSlot = '#CTAandSlot',
  CTABox = '#CTAbox',
  PreOrderBox = '#preOrder',
}

export enum CtaButtonStates {
  OutOfStock = 'OOS',
  Subscribe = 'Subscribe',
  AddBuy = 'AddBuy',
  NotifyMe = 'NotifyMe',
  PreOrder = 'preOrder',
}

export enum CtaButtonsWidgetEvents {
  AddToCartClick = 'addToCartClick',
  BuyNowClick = 'buyNowClick',
  SubscribeNowClick = 'subscribeNowClick',
  NotifyMeClick = 'notifyMeClick',
  WishlistClick = 'wishlistClick',
}

export enum AfterAddToCartChoices {
  MINI_CART = 'mini-cart',
  TINY_CART = 'tiny-cart',
  CART = 'cart',
  NONE = 'none',
  CHECKOUT = 'checkout',
}

export const defaultCtaEnglishLabels = [
  'Add to Cart',
  'Pre Order',
  'Buy Now',
  'Subscribe Now',
  'Notify When Available',
  'Out of Stock',
];

export const checkMarkIcon = '✓';
