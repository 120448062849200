export const addTextInputField = (sectionBuilder, withPlaceHolder: boolean = true) => {
  sectionBuilder.set({
    state: 'active',
    category: 'text',
    label: 'Input text',
    priority: 1,
    roles: [],
    states: ['regular', 'hover', 'disabled'],
    styleParam: 'fnt',
  });

  sectionBuilder.addSubSection({
    label: 'Text color',
    priority: -1,
    styleParam: 'txt',
  });

  if (withPlaceHolder) {
    sectionBuilder.addSubSection({
      label: 'Placeholder color',
      priority: -2,
      styleParam: 'txt2',
    });
  }
};

export const addDropDownInputField = (sectionBuilder) => {
  sectionBuilder.set({
    state: 'active',
    category: 'text',
    label: 'Dropdown list',
    priority: 2,
    roles: [],
    states: ['regular', 'hover', 'disabled'],
    styleParam: 'dropdownListFont',
  });

  sectionBuilder.addSubSection({
    label: 'Text color',
    priority: -1,
    styleParam: 'dropdownListTextColor',
  });
};
