import {WidgetBuilder} from '../../editor.app.types';
import {hideInnerWidgetMobileGfpp} from './helpers/hideInnerWidgetMobileGfpp';
import {customTextFieldsDesignBuilderFn} from '../global-design/customTextFields.widget.design';
import {helpIds} from '../../../constants/app';
import {ActionIds} from '../../../constants/gfpp';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';

export const setCustomTextFieldsWidgetStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder.set({displayName: t.productPage.customTextWidget.displayName()});
  widgetBuilder
    .gfpp()
    .set('settings', {actionId: ActionIds.CUSTOM_TEXT_FIELD_SETTINGS})
    .set('mainAction1', {label: t.productPage.customTextWidget.gfpp.design(), actionType: 'design', actionId: ''})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('connect', {behavior: 'HIDE'})
    .set('layout', {behavior: 'HIDE'})
    .set('add', {behavior: 'HIDE'})
    .set('help', {id: helpIds.CUSTOM_TEXT_FIELDS});

  hideInnerWidgetMobileGfpp(widgetBuilder);

  widgetBuilder.configureWidgetDesign((wb) => customTextFieldsDesignBuilderFn(wb, t));
};
