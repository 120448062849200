import {QuantityWidgetComponentIds} from '../../../components/quantityWidget/config/constants';
import {getRole} from './getRole';
import {helpIds} from '../../../constants/app';
import {WidgetDesignBuilderFn} from '../../editor.app.types';
import {addTextInputField} from './addTextInputField';

export const quantityDesignBuilderFn: WidgetDesignBuilderFn = (widgetDesignBuilder, t): void => {
  widgetDesignBuilder.set({
    title: t.productPage.quantityWidget.design.panelHeader(),
    customHelpId: helpIds.QUANTITY,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.quantityWidget.design.title.sectionName(),
          dependents: [QuantityWidgetComponentIds.Label].map(getRole),
        })
        .groups()
        .set({
          roles: [QuantityWidgetComponentIds.Label].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .setStyleParamOverride('wysiwyg.viewer.components.inputs.TextInput', {txt: ['txt2', 'txt']})
        .set({
          label: t.productPage.quantityWidget.design.inputField.sectionName(),
        })
        .groups()
        .set({
          roles: [QuantityWidgetComponentIds.Input].map(getRole),
        });

      tabBuilder.addSection((sectionBuilder) => addTextInputField(sectionBuilder, false));
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({label: t.productPage.quantityWidget.design.minusButton.sectionName()})
        .groups()
        .set({roles: [QuantityWidgetComponentIds.Decrease].map(getRole)});
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({label: t.productPage.quantityWidget.design.plusButton.sectionName()})
        .groups()
        .set({roles: [QuantityWidgetComponentIds.Increase].map(getRole)});
    });
};
