import {PriceWidgetComponentIds} from '../../../components/priceWidget/config/constants';
import {DropdownComponentIds} from '../../../components/dropdownWidget/config/constants';
import {ColorWidgetComponentIds} from '../../../components/colorPickerWidget/config/constants';
import {QuantityWidgetComponentIds} from '../../../components/quantityWidget/config/constants';
import {
  CustomTextFieldsWidgetComponentIds,
  CustomTextFieldsItemComponentIds,
} from '../../../components/customTextFieldsWidget/config/constants';
import {InfoSectionItemComponentIds} from '../../../components/infoSectionsWidget/config/constants';
import {
  SubscriptionSelectableRepeaterItemComponentIds,
  SubscriptionWidgetComponentIds,
} from '../../../components/subscriptionWidget/config/constants';
import {getRole} from './getRole';
import {SkuWidgetComponentIds} from '../../../components/skuWidget/config/constants';
import {RootWidgetComponentIds} from '../../../components/rootWidget/config/constants';
import {helpIds} from '../../../constants/app';
import {WidgetDesignBuilderFn} from '../../editor.app.types';
import {CtaButtonsWidgetComponentIds} from '../../../components/ctaButtonsWidget/config/constants';
import {addTextInputField, addDropDownInputField} from './addTextInputField';

export const productPageDesignBuilderFn: WidgetDesignBuilderFn = (widgetDesignBuilder, t): void => {
  widgetDesignBuilder.set({
    title: t.productPage.productPageWidget.design.panelHeader(),
    customHelpId: helpIds.MAIN_WIDGET,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.productPageWidget.design.productName.sectionName(),
        })
        .groups()
        .set({
          roles: [RootWidgetComponentIds.ProductTitle].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.productPageWidget.design.bodyText.sectionName(),
          tooltip: t.productPage.productPageWidget.design.bodyText.tooltip.text(),
        })
        .groups()
        .set({
          roles: [
            PriceWidgetComponentIds.FromDefaultLang,
            PriceWidgetComponentIds.FromSpecialLang,
            PriceWidgetComponentIds.ActualPriceDefaultLang,
            PriceWidgetComponentIds.ActualPriceSpecialLang,
            PriceWidgetComponentIds.OriginalPriceDefaultLang,
            PriceWidgetComponentIds.OriginalPriceSpecialLang,
            PriceWidgetComponentIds.FrequencyDefaultLang,
            PriceWidgetComponentIds.FrequencySpecialLang,
            InfoSectionItemComponentIds.Title,
            InfoSectionItemComponentIds.Paragraph,
            SubscriptionSelectableRepeaterItemComponentIds.Title,
            SubscriptionSelectableRepeaterItemComponentIds.Price,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.productPageWidget.design.secondaryText.sectionName(),
          tooltip: t.productPage.productPageWidget.design.secondaryText.tooltip.text(),
        })
        .groups()
        .set({
          roles: [
            SkuWidgetComponentIds.Label,
            SkuWidgetComponentIds.Value,
            PriceWidgetComponentIds.PricePerUnit,
            PriceWidgetComponentIds.TaxDisclaimer,
            PriceWidgetComponentIds.DisclaimerSeparator,
            PriceWidgetComponentIds.ShippingDisclaimer,
            CustomTextFieldsItemComponentIds.CharCounter,
            SubscriptionSelectableRepeaterItemComponentIds.Tagline,
            SubscriptionSelectableRepeaterItemComponentIds.Frequency,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.productPageWidget.design.titles.sectionName(),
          tooltip: t.productPage.productPageWidget.design.titles.tooltip.text(),
        })
        .groups()
        .set({
          roles: [
            ColorWidgetComponentIds.Label,
            DropdownComponentIds.Label,
            QuantityWidgetComponentIds.Label,
            CustomTextFieldsItemComponentIds.Label,
            SubscriptionWidgetComponentIds.Label,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.productPageWidget.design.inputFields.sectionName(),
          tooltip: t.productPage.productPageWidget.design.inputFields.tooltip.text(),
        })
        .groups()
        .set({
          roles: [
            QuantityWidgetComponentIds.Input,
            CustomTextFieldsItemComponentIds.TextBox,
            DropdownComponentIds.Dropdown,
          ].map(getRole),
        });

      tabBuilder.addSection((sectionBuilder) => addTextInputField(sectionBuilder));
      tabBuilder.addSection((sectionBuilder) => addDropDownInputField(sectionBuilder));
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.productPageWidget.design.actionButtons.sectionName(),
          tooltip: t.productPage.productPageWidget.design.actionButtons.tooltip.text(),
        })
        .groups()
        .set({
          roles: [
            CtaButtonsWidgetComponentIds.AddToCartBtn,
            CtaButtonsWidgetComponentIds.BuyNowBtn,
            CtaButtonsWidgetComponentIds.PreOrderButton,
            CtaButtonsWidgetComponentIds.PreOrderNowButton,
            CtaButtonsWidgetComponentIds.NotifyMeBtn,
            CtaButtonsWidgetComponentIds.OutOfStockBtn,
            CtaButtonsWidgetComponentIds.SubscribeNowBtn,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({label: t.productPage.productPageWidget.design.background.sectionName()})
        .groups()
        .set({
          roles: [RootWidgetComponentIds.RootWidgetContainer].map(getRole),
        });
    });
};
