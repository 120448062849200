import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {AfterAddToCartChoices, CtaButtonsWidgetEvents as Events} from './config/constants';

type Settings = {
  afterAddToCart: AfterAddToCartChoices;
  afterAddToCartMobile?: AfterAddToCartChoices;
  afterPreOrder: AfterAddToCartChoices;
  otherSetting: string;
  activeState: string | null;
};

type LabelOverrides = {
  addToCart: string | null;
  buyNow: string | null;
};

const DEFAULT_SETTINGS = {
  afterAddToCart: AfterAddToCartChoices.MINI_CART,
  afterAddToCartMobile: undefined,
  afterPreOrder: AfterAddToCartChoices.CART,
  otherSetting: 'default',
  activeState: null,
} as Settings;

export default createBlocksModel({
  widgetName: 'ctaButtonsWidget',
  props: {
    inStock: {
      type: 'boolean',
      defaultValue: false,
    },
    enableNotifyMe: {
      type: 'boolean',
      defaultValue: false,
    },
    isSubscribeNow: {
      type: 'boolean',
      defaultValue: false,
    },
    settings: {
      type: undefined,
      defaultValue: DEFAULT_SETTINGS,
    },
    productId: {
      type: 'text',
      defaultValue: null,
    },
    isAddToCartInProgress: {
      type: 'boolean',
      defaultValue: false,
    },
    isPreOrder: {
      type: 'boolean',
      defaultValue: false,
    },
    preOrderMessage: {
      type: 'text',
      defaultValue: '',
    },
    labelOverrides: {
      type: undefined,
      defaultValue: null as LabelOverrides | null,
    },
    shouldShowPreOrderMessage: {
      type: 'boolean',
      defaultValue: false,
    },
  },
  methods: {
    indicateAddToCartCompleted: {},
  },
  events: {
    [Events.AddToCartClick]: {},
    [Events.BuyNowClick]: {},
    [Events.SubscribeNowClick]: {},
    [Events.NotifyMeClick]: {},
    [Events.WishlistClick]: {},
  },
});
