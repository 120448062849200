import {PriceWidgetComponentIds} from '../../../components/priceWidget/config/constants';
import {getRole} from './getRole';
import {helpIds} from '../../../constants/app';
import {WidgetDesignBuilderFn} from '../../editor.app.types';

export const priceDesignBuilderFn: WidgetDesignBuilderFn = (widgetDesignBuilder, t): void => {
  widgetDesignBuilder.set({
    title: t.productPage.pricingWidget.design.panelHeader(),
    customHelpId: helpIds.PRICING,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({label: t.productPage.pricingWidget.design.finalPrice.sectionName()})
        .groups()
        .set({
          roles: [PriceWidgetComponentIds.ActualPriceDefaultLang, PriceWidgetComponentIds.ActualPriceSpecialLang].map(
            getRole,
          ),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.pricingWidget.design.fromLabel.sectionName(),
          tooltip: t.productPage.pricingWidget.design.fromLabel.tooltip.text(),
        })
        .groups()
        .set({
          roles: [PriceWidgetComponentIds.FromDefaultLang, PriceWidgetComponentIds.FromSpecialLang].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.pricingWidget.design.strikethroughPrice.sectionName(),
          tooltip: t.productPage.pricingWidget.design.strikethroughPrice.tooltip.text(),
        })
        .groups()
        .set({
          roles: [
            PriceWidgetComponentIds.OriginalPriceDefaultLang,
            PriceWidgetComponentIds.OriginalPriceSpecialLang,
          ].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.pricingWidget.design.discountName.sectionName(),
          tooltip: t.productPage.pricingWidget.design.discountName.tooltip.text(),
        })
        .groups()
        .set({
          roles: [PriceWidgetComponentIds.DiscountTitle].map(getRole),
        });
    });
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.pricingWidget.design.subscription.sectionName(),
          tooltip: t.productPage.pricingWidget.design.subscription.tooltip.text(),
        })
        .groups()
        .set({
          roles: [PriceWidgetComponentIds.FrequencyDefaultLang, PriceWidgetComponentIds.FrequencySpecialLang].map(
            getRole,
          ),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.pricingWidget.design.pricePerUnit.sectionName(),
          tooltip: t.productPage.pricingWidget.design.pricePerUnit.tooltip.text(),
        })
        .groups()
        .set({
          roles: [PriceWidgetComponentIds.PricePerUnit].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.pricingWidget.design.taxShippingInfo.sectionName(),
          tooltip: t.productPage.pricingWidget.design.taxShippingInfo.tooltip.text(),
        })
        .groups()
        .set({
          roles: [
            PriceWidgetComponentIds.TaxDisclaimer,
            PriceWidgetComponentIds.DisclaimerSeparator,
            PriceWidgetComponentIds.ShippingDisclaimer,
          ].map(getRole),
        });
    });
};
