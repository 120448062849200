import {ROOT_WIDGET_ID} from '../../constants/app';

import {Method, Methods} from '../../types/type';

export const removeRootConnections = (baseManifest) => {
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections.sku;
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections.ctaButtons;
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections.navigation;
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections.customTextFields;
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections.infoSection;
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections.optWrapper;
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections.optionsWrapperRepeater1;
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections.productQuantity;
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections.stockIndicator1;
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections.productPrice;
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections.socialShare;
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections.subscription;
  delete baseManifest.controllersStageData[ROOT_WIDGET_ID].default.connections['*'];
  return baseManifest;
};

export const buildTypeDef = (methods: Methods) => {
  const methodsArray = Object.keys(methods)
    .map((method) => buildMethodDoc(methods[method], method))
    .filter((method) => method !== '');

  return `{${methodsArray.join(' ')}} & $w.Node & $w.CollapsedMixin & $w.HiddenMixin`;
};
export const buildMethodDoc = (method: Method, methodName: string) => {
  return method.public
    ? `
/**
         * ${method.description ? method.description : ``}.
         * \t${method.documentation ? `[${method.documentation.label}](${method.documentation.link})` : ``}
         */
       ${methodName}(${buildParams(method)}): ${method.returnsDescription},`
    : '';
};

const buildParams = (method: Method) => {
  return method.params.map((param) => {
    return `${param.name}: ${param.type}`;
  });
};
