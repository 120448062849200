/* eslint-disable */
/* tslint:disable */
export function LocaleKeys<R extends string>(t: (...args: unknown[]) => R) {
  return {
    ADD_TO_CART_BUTTON: () => t('ADD_TO_CART_BUTTON'), /* Add to Cart */
    PRODUCT_OUT_OF_STOCK_BUTTON: () => t('PRODUCT_OUT_OF_STOCK_BUTTON'), /* Out of Stock */
    QUANTITY_LABEL: () => t('QUANTITY_LABEL'), /* Quantity */
    SELECT_PLACE_HOLDER: () => t('SELECT_PLACE_HOLDER'), /* Select */
    SKU_LABEL: () => t('SKU_LABEL'), /* SKU: */
    SELECT_OPTION_WARNING: (data: Record<'optionTitle', unknown>) => t('SELECT_OPTION_WARNING', data), /* Select {optionTitle} */
    PRODUCT_PAGE_PURCHASE_OPTIONS_TITLE: () => t('PRODUCT_PAGE_PURCHASE_OPTIONS_TITLE'), /* Price Options */
    PRODUCT_PAGE_ONE_TIME_PURCHASE_LABEL: () => t('PRODUCT_PAGE_ONE_TIME_PURCHASE_LABEL'), /* One-time purchase */
    PRODUCT_PAGE_SUBSCRIBE_NOW_BUTTON: () => t('PRODUCT_PAGE_SUBSCRIBE_NOW_BUTTON'), /* Subscribe Now */
    PRODUCT_PAGE_PLAN_MISSING_ERROR_TOOLTIP: () => t('PRODUCT_PAGE_PLAN_MISSING_ERROR_TOOLTIP'), /* Select a price option. */
    BUY_NOW_BUTTON: () => t('BUY_NOW_BUTTON'), /* Buy Now */
    sr: {
      addToCartSuccess: (data: Record<'productName', unknown>) => t('sr.addToCartSuccess', data), /* {productName} was added to cart */
    },
    PRODUCT_PAGE_BASE_UNIT_PRICE: (data: Record<'basePrice' | 'units', unknown>) => t('PRODUCT_PAGE_BASE_UNIT_PRICE', data), /* {basePrice} / {units} */
    SR_PRODUCT_PAGE_BASE_UNIT_PRICE: (data: Record<'basePrice' | 'units', unknown>) => t('SR_PRODUCT_PAGE_BASE_UNIT_PRICE', data), /* {basePrice} per {units} */
    SR_PRODUCT_PAGE_UNIT_CUBICMETERS: () => t('SR_PRODUCT_PAGE_UNIT_CUBICMETERS'), /* Cubic meters */
    SR_PRODUCT_PAGE_UNIT_LITERS: () => t('SR_PRODUCT_PAGE_UNIT_LITERS'), /* Liters */
    SR_PRODUCT_PAGE_UNIT_KILOGRAM: () => t('SR_PRODUCT_PAGE_UNIT_KILOGRAM'), /* Kilogram */
    SR_PRODUCT_PAGE_UNIT_CENTILITERS: () => t('SR_PRODUCT_PAGE_UNIT_CENTILITERS'), /* Centiliters */
    SR_PRODUCT_PAGE_UNIT_CENTILITER: () => t('SR_PRODUCT_PAGE_UNIT_CENTILITER'), /* Centiliter */
    SR_PRODUCT_PAGE_UNIT_GRAMS: () => t('SR_PRODUCT_PAGE_UNIT_GRAMS'), /* Grams */
    SR_PRODUCT_PAGE_UNIT_MILLIGRAM: () => t('SR_PRODUCT_PAGE_UNIT_MILLIGRAM'), /* Milligram */
    SR_PRODUCT_PAGE_UNIT_MILLILITER: () => t('SR_PRODUCT_PAGE_UNIT_MILLILITER'), /* Milliliter */
    SR_PRODUCT_PAGE_UNIT_KILOGRAMS: () => t('SR_PRODUCT_PAGE_UNIT_KILOGRAMS'), /* Kilograms */
    SR_PRODUCT_PAGE_UNIT_MILLIGRAMS: () => t('SR_PRODUCT_PAGE_UNIT_MILLIGRAMS'), /* Milligrams */
    SR_PRODUCT_PAGE_UNIT_CUBICMETER: () => t('SR_PRODUCT_PAGE_UNIT_CUBICMETER'), /* Cubic meter */
    SR_PRODUCT_PAGE_UNIT_GRAM: () => t('SR_PRODUCT_PAGE_UNIT_GRAM'), /* Gram */
    SR_PRODUCT_PAGE_UNIT_LITER: () => t('SR_PRODUCT_PAGE_UNIT_LITER'), /* Liter */
    SR_PRODUCT_PAGE_UNIT_MILLILITERS: () => t('SR_PRODUCT_PAGE_UNIT_MILLILITERS'), /* Milliliters */
    SR_PRODUCT_PAGE_UNIT_CENTIMETERS: () => t('SR_PRODUCT_PAGE_UNIT_CENTIMETERS'), /* Centimeters */
    SR_PRODUCT_PAGE_UNIT_METERS: () => t('SR_PRODUCT_PAGE_UNIT_METERS'), /* Meters */
    SR_PRODUCT_PAGE_UNIT_MILLIMETERS: () => t('SR_PRODUCT_PAGE_UNIT_MILLIMETERS'), /* Millimeters */
    SR_PRODUCT_PAGE_UNIT_METER: () => t('SR_PRODUCT_PAGE_UNIT_METER'), /* Meter */
    SR_PRODUCT_PAGE_UNIT_CENTIMETER: () => t('SR_PRODUCT_PAGE_UNIT_CENTIMETER'), /* Centimeter */
    SR_PRODUCT_PAGE_UNIT_MILLIMETER: () => t('SR_PRODUCT_PAGE_UNIT_MILLIMETER'), /* Millimeter */
    SR_PRODUCT_PAGE_UNIT_SQMS: () => t('SR_PRODUCT_PAGE_UNIT_SQMS'), /* Square meters */
    SR_PRODUCT_PAGE_UNIT_SQM: () => t('SR_PRODUCT_PAGE_UNIT_SQM'), /* Square meter */
    SR_PRODUCT_PAGE_UNIT_PINTS: () => t('SR_PRODUCT_PAGE_UNIT_PINTS'), /* Pints */
    SR_PRODUCT_PAGE_UNIT_INCH: () => t('SR_PRODUCT_PAGE_UNIT_INCH'), /* Inch */
    SR_PRODUCT_PAGE_UNIT_YARD: () => t('SR_PRODUCT_PAGE_UNIT_YARD'), /* Yard */
    SR_PRODUCT_PAGE_UNIT_POUND: () => t('SR_PRODUCT_PAGE_UNIT_POUND'), /* Pound */
    SR_PRODUCT_PAGE_UNIT_PINT: () => t('SR_PRODUCT_PAGE_UNIT_PINT'), /* Pint */
    SR_PRODUCT_PAGE_UNIT_FEET: () => t('SR_PRODUCT_PAGE_UNIT_FEET'), /* Feet */
    SR_PRODUCT_PAGE_UNIT_QUART: () => t('SR_PRODUCT_PAGE_UNIT_QUART'), /* Quart */
    SR_PRODUCT_PAGE_UNIT_OUNCE: () => t('SR_PRODUCT_PAGE_UNIT_OUNCE'), /* Ounce */
    SR_PRODUCT_PAGE_UNIT_INCHES: () => t('SR_PRODUCT_PAGE_UNIT_INCHES'), /* Inches */
    SR_PRODUCT_PAGE_UNIT_GALLONS: () => t('SR_PRODUCT_PAGE_UNIT_GALLONS'), /* Gallons */
    SR_PRODUCT_PAGE_UNIT_SQFOOT: () => t('SR_PRODUCT_PAGE_UNIT_SQFOOT'), /* Square foot */
    SR_PRODUCT_PAGE_UNIT_QUARTS: () => t('SR_PRODUCT_PAGE_UNIT_QUARTS'), /* Quarts */
    SR_PRODUCT_PAGE_UNIT_FOOT: () => t('SR_PRODUCT_PAGE_UNIT_FOOT'), /* Foot */
    SR_PRODUCT_PAGE_UNIT_GALLON: () => t('SR_PRODUCT_PAGE_UNIT_GALLON'), /* Gallon */
    SR_PRODUCT_PAGE_UNIT_POUNDS: () => t('SR_PRODUCT_PAGE_UNIT_POUNDS'), /* Pounds */
    SR_PRODUCT_PAGE_UNIT_SQFEET: () => t('SR_PRODUCT_PAGE_UNIT_SQFEET'), /* Square feet */
    SR_PRODUCT_PAGE_UNIT_YARDS: () => t('SR_PRODUCT_PAGE_UNIT_YARDS'), /* Yards */
    SR_PRODUCT_PAGE_UNIT_OUNCES: () => t('SR_PRODUCT_PAGE_UNIT_OUNCES'), /* Ounces */
    PRODUCT_PAGE_BASE_UNIT_LB: () => t('PRODUCT_PAGE_BASE_UNIT_LB'), /* lb */
    PRODUCT_PAGE_BASE_UNIT_CBM: () => t('PRODUCT_PAGE_BASE_UNIT_CBM'), /* m³ */
    PRODUCT_PAGE_BASE_UNIT_MM: () => t('PRODUCT_PAGE_BASE_UNIT_MM'), /* mm */
    PRODUCT_PAGE_BASE_UNIT_L: () => t('PRODUCT_PAGE_BASE_UNIT_L'), /* l */
    PRODUCT_PAGE_BASE_UNIT_ML: () => t('PRODUCT_PAGE_BASE_UNIT_ML'), /* ml */
    PRODUCT_PAGE_BASE_UNIT_SQF: () => t('PRODUCT_PAGE_BASE_UNIT_SQF'), /* ft² */
    PRODUCT_PAGE_BASE_UNIT_SQM: () => t('PRODUCT_PAGE_BASE_UNIT_SQM'), /* m² */
    PRODUCT_PAGE_BASE_UNIT_PT: () => t('PRODUCT_PAGE_BASE_UNIT_PT'), /* pt */
    PRODUCT_PAGE_BASE_UNIT_QT: () => t('PRODUCT_PAGE_BASE_UNIT_QT'), /* qt */
    PRODUCT_PAGE_BASE_UNIT_YD: () => t('PRODUCT_PAGE_BASE_UNIT_YD'), /* yd */
    PRODUCT_PAGE_BASE_UNIT_M: () => t('PRODUCT_PAGE_BASE_UNIT_M'), /* m */
    PRODUCT_PAGE_BASE_UNIT_OZ: () => t('PRODUCT_PAGE_BASE_UNIT_OZ'), /* oz */
    PRODUCT_PAGE_BASE_UNIT_CM: () => t('PRODUCT_PAGE_BASE_UNIT_CM'), /* cm */
    PRODUCT_PAGE_BASE_UNIT_FT: () => t('PRODUCT_PAGE_BASE_UNIT_FT'), /* ft */
    PRODUCT_PAGE_BASE_UNIT_GAL: () => t('PRODUCT_PAGE_BASE_UNIT_GAL'), /* gal */
    PRODUCT_PAGE_BASE_UNIT_FLOZ: () => t('PRODUCT_PAGE_BASE_UNIT_FLOZ'), /* fl oz */
    PRODUCT_PAGE_BASE_UNIT_G: () => t('PRODUCT_PAGE_BASE_UNIT_G'), /* g */
    PRODUCT_PAGE_BASE_UNIT_IN: () => t('PRODUCT_PAGE_BASE_UNIT_IN'), /* in */
    PRODUCT_PAGE_BASE_UNIT_MG: () => t('PRODUCT_PAGE_BASE_UNIT_MG'), /* mg */
    PRODUCT_PAGE_BASE_UNIT_CL: () => t('PRODUCT_PAGE_BASE_UNIT_CL'), /* cl */
    PRODUCT_PAGE_BASE_UNIT_KG: () => t('PRODUCT_PAGE_BASE_UNIT_KG'), /* kg */
    SR_PRODUCT_PAGE_UNIT_FLOZ: () => t('SR_PRODUCT_PAGE_UNIT_FLOZ'), /* Fluid ounce */
    SR_PRODUCT_PAGE_UNIT_FLOZS: () => t('SR_PRODUCT_PAGE_UNIT_FLOZS'), /* Fluid ounces */
    productPage: {
      price: {
        from: {
          label: () => t('productPage.price.from.label'), /* From */
        },
        tax: {
          included: {
            label: () => t('productPage.price.tax.included.label'), /* Included */
          },
          excluded: {
            label: () => t('productPage.price.tax.excluded.label'), /* Excluding */
          },
          includedParam: {
            label: (data: Record<'taxOrVAT' | 'included', unknown>) => t('productPage.price.tax.includedParam.label', data), /* {taxOrVAT} {included} */
          },
          excludedParam: {
            label: (data: Record<'excluding' | 'taxOrVAT', unknown>) => t('productPage.price.tax.excludedParam.label', data), /* {excluding} {taxOrVAT} */
          },
        },
      },
      productPageWidget: {
        displayName: () => t('productPage.productPageWidget.displayName'), /* Product Page */
        gfpp: {
          manage: () => t('productPage.productPageWidget.gfpp.manage'), /* Manage Products */
          layout: () => t('productPage.productPageWidget.gfpp.layout'), /* Choose Layout */
        },
        elements: {
          customText: {
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.customText.tooltip.text'), /* Allow customers to personalize their product. You can manage these custom text boxes from your product info in the Dashboard. */
              link: () => t('productPage.productPageWidget.elements.customText.tooltip.link'), /* Manage Products */
            },
            notification: {
              link: () => t('productPage.productPageWidget.elements.customText.notification.link'), /* Restore Custom Text */
              text: () => t('productPage.productPageWidget.elements.customText.notification.text'), /* Customers won’t be able to purchase products that require custom text. */
            },
            label: () => t('productPage.productPageWidget.elements.customText.label'), /* Custom text */
          },
          panelHeader: () => t('productPage.productPageWidget.elements.panelHeader'), /* Product Page Elements */
          sku: {
            tooltip: {
              link: () => t('productPage.productPageWidget.elements.sku.tooltip.link'), /* Manage Products */
              text: () => t('productPage.productPageWidget.elements.sku.tooltip.text'), /* Use unique identifiers to track your products’ inventory and sales. You can manage these SKU codes from your product info in the Dashboard. */
            },
            label: () => t('productPage.productPageWidget.elements.sku.label'), /* SKU */
          },
          infoSections: {
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.infoSections.tooltip.text'), /* Share information like a return policy or care instructions. You can manage these info sections from your product info in the Dashboard. */
              link: () => t('productPage.productPageWidget.elements.infoSections.tooltip.link'), /* Manage Products */
            },
            label: () => t('productPage.productPageWidget.elements.infoSections.label'), /* Info sections */
          },
          actionButtons: {
            title: () => t('productPage.productPageWidget.elements.actionButtons.title'), /* Action buttons */
          },
          productOptions: {
            tooltip: {
              link: () => t('productPage.productPageWidget.elements.productOptions.tooltip.link'), /* Manage Products */
              text: () => t('productPage.productPageWidget.elements.productOptions.tooltip.text'), /* Offer a choice between product variants, like different colors or weights. You can manage these product options from your product info in the Dashboard. */
            },
            notification: {
              text: () => t('productPage.productPageWidget.elements.productOptions.notification.text'), /* Customers won’t be able to purchase products that have more than one variant. */
              link: () => t('productPage.productPageWidget.elements.productOptions.notification.link'), /* Restore Product Options */
            },
            label: () => t('productPage.productPageWidget.elements.productOptions.label'), /* Product options */
          },
          twitter: {
            label: () => t('productPage.productPageWidget.elements.twitter.label'), /* Twitter */
          },
          smartPayment: {
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.smartPayment.tooltip.text'), /* Automatically show payment buttons (like PayPal and Apple Pay) based on customers’ location, payment history and more. You can manage these payment options from your payment settings. */
              link: () => t('productPage.productPageWidget.elements.smartPayment.tooltip.link'), /* Learn More */
              linkURL: () => t('productPage.productPageWidget.elements.smartPayment.tooltip.linkURL'), /* https://support.wix.com/en/article/smart-payments */
            },
            label: () => t('productPage.productPageWidget.elements.smartPayment.label'), /* Smart Payment */
          },
          pricing: {
            notification: {
              link: () => t('productPage.productPageWidget.elements.pricing.notification.link'), /* Go to SEO Settings */
              text: () => t('productPage.productPageWidget.elements.pricing.notification.text'), /* The price might still appear in search engines. You can hide it from the product’s structured data markup. */
            },
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.pricing.tooltip.text'), /* Show the product’s price, tax and shipping fees, installment options and more. */
              link: () => t('productPage.productPageWidget.elements.pricing.tooltip.link'), /* Learn more */
            },
            label: () => t('productPage.productPageWidget.elements.pricing.label'), /* Pricing */
          },
          shareButtons: {
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.shareButtons.tooltip.text'), /* Let customers share your products on social media. */
            },
            title: () => t('productPage.productPageWidget.elements.shareButtons.title'), /* Share Buttons */
          },
          previousNext: {
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.previousNext.tooltip.text'), /* Let customers navigate between the different products in the gallery they came from. */
            },
            label: () => t('productPage.productPageWidget.elements.previousNext.label'), /* Previous/Next */
          },
          gallery: {
            tooltip: {
              link: () => t('productPage.productPageWidget.elements.gallery.tooltip.link'), /* Manage Products */
              text: () => t('productPage.productPageWidget.elements.gallery.tooltip.text'), /* Show a media gallery with your product’s images and videos. You can manage this media from your product info in the Dashboard. */
            },
            label: () => t('productPage.productPageWidget.elements.gallery.label'), /* Gallery */
          },
          pinterest: {
            label: () => t('productPage.productPageWidget.elements.pinterest.label'), /* Pinterest */
          },
          quantity: {
            label: () => t('productPage.productPageWidget.elements.quantity.label'), /* Quantity */
          },
          productName: {
            notification: {
              link: () => t('productPage.productPageWidget.elements.productName.notification.link'), /* Manage SEO Settings */
              text: () => t('productPage.productPageWidget.elements.productName.notification.text'), /* The name might still appear in search engines. You can hide it from the product’s structured data markup. */
            },
            label: () => t('productPage.productPageWidget.elements.productName.label'), /* Product name */
          },
          description: {
            label: () => t('productPage.productPageWidget.elements.description.label'), /* Description */
          },
          addToCart: {
            label: () => t('productPage.productPageWidget.elements.addToCart.label'), /* Add to Cart */
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.addToCart.tooltip.text'), /* Let customers add products to their cart and continue shopping. */
            },
          },
          navigation: {
            title: () => t('productPage.productPageWidget.elements.navigation.title'), /* Navigation */
          },
          breadcrumbs: {
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.breadcrumbs.tooltip.text'), /* Tell customers exactly where they are on your site, so they can easily navigate back to a previous page. */
            },
            label: () => t('productPage.productPageWidget.elements.breadcrumbs.label'), /* Breadcrumbs */
          },
          buyNow: {
            label: () => t('productPage.productPageWidget.elements.buyNow.label'), /* Buy Now */
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.buyNow.tooltip.text'), /* Let customers skip the cart and go straight to checkout from the product page. */
            },
          },
          productInfo: {
            title: () => t('productPage.productPageWidget.elements.productInfo.title'), /* Product info */
          },
          facebook: {
            label: () => t('productPage.productPageWidget.elements.facebook.label'), /* Facebook */
          },
          whatsapp: {
            label: () => t('productPage.productPageWidget.elements.whatsapp.label'), /* WhatsApp */
          },
          stockStatus: {
            label: () => t('productPage.productPageWidget.elements.stockStatus.label'), /* Stock status */
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.stockStatus.tooltip.text'), /* Show a message when the product is low in stock. If you enabled back in stock requests, this will also show when the product is out of stock. */
            },
          },
          strikethroughPrice: {
            label: () => t('productPage.productPageWidget.elements.strikethroughPrice.label'), /* Strikethrough price */
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.strikethroughPrice.tooltip.text'), /* If this product has a sale price, the original price will show with a strikethrough next to the discounted price. */
            },
          },
          subscriptionFrequency: {
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.subscriptionFrequency.tooltip.text'), /* When customers select a subscription option, the subscription frequency will show up next to the product price. */
            },
            label: () => t('productPage.productPageWidget.elements.subscriptionFrequency.label'), /* Subscription frequency */
          },
          finalPrice: {
            label: () => t('productPage.productPageWidget.elements.finalPrice.label'), /* Price */
          },
          discountName: {
            label: () => t('productPage.productPageWidget.elements.discountName.label'), /* Discount name */
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.discountName.tooltip.text'), /* This appears under the price when the product is eligible for an automatic discount. You can set this up for each automatic discount in the Dashboard. */
            },
          },
          pricePerUnit: {
            label: () => t('productPage.productPageWidget.elements.pricePerUnit.label'), /* Price per unit */
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.pricePerUnit.tooltip.text'), /* Show customers how much they’ll be spending per unit of the product (e.g., 5$ per 100g). You can set up the price per unit for each product from the Dashboard. */
            },
          },
          taxShippingInfo: {
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.taxShippingInfo.tooltip.text'), /* Tell customers if the product price includes tax and shipping. If you want to add this info, you’ll first need to set it up from the Dashboard settings. */
            },
            label: () => t('productPage.productPageWidget.elements.taxShippingInfo.label'), /* Tax & shipping info */
          },
          fromLabel: {
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.fromLabel.tooltip.text'), /* If your product has variants with different prices, you can show the lowest price with the word “From” next to it. If you want to use this, you’ll first need to turn it on from the Dashboard settings. */
            },
            label: () => t('productPage.productPageWidget.elements.fromLabel.label'), /* "From" label */
          },
          subscriptions: {
            label: () => t('productPage.productPageWidget.elements.subscriptions.label'), /* Subscriptions */
            tooltip: () => t('productPage.productPageWidget.elements.subscriptions.tooltip'), /* Let customers make a recurring purchase of this product. You can set up subscriptions for each product from the Dashboard. */
            notification: {
              text: () => t('productPage.productPageWidget.elements.subscriptions.notification.text'), /* Customers won’t be able to purchase products that have a subscription option. */
              link: () => t('productPage.productPageWidget.elements.subscriptions.notification.link'), /* Restore Subscriptions */
            },
          },
          subtitle: {
            text: () => t('productPage.productPageWidget.elements.subtitle.text'), /* Choose which elements will show on all of your products pages. */
          },
          compareAtPrice: {
            label: () => t('productPage.productPageWidget.elements.compareAtPrice.label'), /* Strikethrough price */
            tooltip: {
              text: () => t('productPage.productPageWidget.elements.compareAtPrice.tooltip.text'), /* Strikethrough prices appear crossed out next to the actual price. */
            },
          },
        },
        design: {
          productName: {
            sectionName: () => t('productPage.productPageWidget.design.productName.sectionName'), /* Product Name */
            panelHeader: () => t('productPage.productPageWidget.design.productName.panelHeader'), /* Product Name */
          },
          secondaryText: {
            sectionName: () => t('productPage.productPageWidget.design.secondaryText.sectionName'), /* Secondary Text */
            panelHeader: () => t('productPage.productPageWidget.design.secondaryText.panelHeader'), /* Secondary Text */
            tooltip: {
              text: () => t('productPage.productPageWidget.design.secondaryText.tooltip.text'), /* Design the SKU, price per unit, character counter, subscription tagline and subscription frequency. */
            },
          },
          titlesBodyText: {
            sectionName: () => t('productPage.productPageWidget.design.titlesBodyText.sectionName'), /* Titles & Body Text */
            panelHeader: () => t('productPage.productPageWidget.design.titlesBodyText.panelHeader'), /* Titles & Body Text */
          },
          background: {
            sectionName: () => t('productPage.productPageWidget.design.background.sectionName'), /* Background */
            panelHeader: () => t('productPage.productPageWidget.design.background.panelHeader'), /* Background */
          },
          panelHeader: () => t('productPage.productPageWidget.design.panelHeader'), /* Product Page Design */
          inputFields: {
            sectionName: () => t('productPage.productPageWidget.design.inputFields.sectionName'), /* Input Fields */
            panelHeader: () => t('productPage.productPageWidget.design.inputFields.panelHeader'), /* Input Fields */
            tooltip: {
              text: () => t('productPage.productPageWidget.design.inputFields.tooltip.text'), /* Design the product options dropdown, custom text box and quantity fields. */
            },
          },
          actionButtons: {
            sectionName: () => t('productPage.productPageWidget.design.actionButtons.sectionName'), /* Action Buttons */
            tooltip: {
              text: () => t('productPage.productPageWidget.design.actionButtons.tooltip.text'), /* Design the Add to Cart, Buy Now, Pre-Order, Subscribe Now and Notify Me buttons. */
            },
          },
          titles: {
            sectionName: () => t('productPage.productPageWidget.design.titles.sectionName'), /* Input Titles */
            tooltip: {
              text: () => t('productPage.productPageWidget.design.titles.tooltip.text'), /* Design the titles of the product options, subscription selection and quantity fields. */
            },
          },
          bodyText: {
            sectionName: () => t('productPage.productPageWidget.design.bodyText.sectionName'), /* Body Text */
            tooltip: {
              text: () => t('productPage.productPageWidget.design.bodyText.tooltip.text'), /* Design the product price, subscription name and info sections. */
            },
          },
        },
        layout: {
          panelHeader: () => t('productPage.productPageWidget.layout.panelHeader'), /* Product Page Layout */
          columnNumber: {
            one: {
              option: () => t('productPage.productPageWidget.layout.columnNumber.one.option'), /* 1 column */
            },
            two: {
              option: () => t('productPage.productPageWidget.layout.columnNumber.two.option'), /* 2 columns */
            },
            label: () => t('productPage.productPageWidget.layout.columnNumber.label'), /* Number of columns */
          },
          columnRatio: {
            label: () => t('productPage.productPageWidget.layout.columnRatio.label'), /* Column ratio (%) */
            sixtyThirty: {
              option: () => t('productPage.productPageWidget.layout.columnRatio.sixtyThirty.option'), /* 66-33 */
            },
            equal: {
              option: () => t('productPage.productPageWidget.layout.columnRatio.equal.option'), /* 50-50 (equal) */
            },
          },
          infoAlignment: {
            center: {
              option: () => t('productPage.productPageWidget.layout.infoAlignment.center.option'), /* Center */
            },
            label: () => t('productPage.productPageWidget.layout.infoAlignment.label'), /* Page alignment */
            left: {
              option: () => t('productPage.productPageWidget.layout.infoAlignment.left.option'), /* Left */
            },
            right: {
              option: () => t('productPage.productPageWidget.layout.infoAlignment.right.option'), /* Right */
            },
          },
          resetToDefault: {
            button: () => t('productPage.productPageWidget.layout.resetToDefault.button'), /* Reset to Default */
          },
          pageDirection: {
            tooltip: {
              text: () => t('productPage.productPageWidget.layout.pageDirection.tooltip.text'), /* Choose the right to left direction if your site is in a language like Arabic or Hebrew. If you have a multilingual site, this will change the direction for all of your languages. */
            },
            label: () => t('productPage.productPageWidget.layout.pageDirection.label'), /* Page direction */
            LTR: {
              option: () => t('productPage.productPageWidget.layout.pageDirection.LTR.option'), /* Left to right */
            },
            RTL: {
              option: () => t('productPage.productPageWidget.layout.pageDirection.RTL.option'), /* Right to left */
            },
          },
          advancedSettings: {
            show: {
              button: () => t('productPage.productPageWidget.layout.advancedSettings.show.button'), /* Show Advanced Settings */
            },
            hide: {
              button: () => t('productPage.productPageWidget.layout.advancedSettings.hide.button'), /* Hide Advanced Settings */
            },
          },
          galleryMinWidth: {
            note: {
              text: () => t('productPage.productPageWidget.layout.galleryMinWidth.note.text'), /* Reduce layout shifts and improve your site’s performance score by matching this minimum height to the actual height of your gallery. */
            },
            label: () => t('productPage.productPageWidget.layout.galleryMinWidth.label'), /* Gallery minimum height (vw) */
          },
          spacingBetweenColumns: {
            label: () => t('productPage.productPageWidget.layout.spacingBetweenColumns.label'), /* Spacing between columns */
          },
        },
        main: {
          title: () => t('productPage.productPageWidget.main.title'), /* Product Page */
        },
      },
      sr: {
        navigationWidget: {
          container: {
            name: () => t('productPage.sr.navigationWidget.container.name'), /* Product */
          },
        },
        customTextWidget: {
          maxCharacters: (data: Record<'numCharacters', unknown>) => t('productPage.sr.customTextWidget.maxCharacters', data), /* Up to {numCharacters} characters. */
        },
        quantityWidget: {
          minusButton: {
            label: () => t('productPage.sr.quantityWidget.minusButton.label'), /* Remove one */
          },
          plusButton: {
            label: () => t('productPage.sr.quantityWidget.plusButton.label'), /* Add one */
          },
        },
        skuWidget: {
          value: {
            label: (data: Record<'skuCode', unknown>) => t('productPage.sr.skuWidget.value.label', data), /* SKU {skuCode} */
          },
        },
        pricingWidget: {
          regularPrice: {
            label: () => t('productPage.sr.pricingWidget.regularPrice.label'), /* Price */
          },
          originalPrice: {
            label: () => t('productPage.sr.pricingWidget.originalPrice.label'), /* Original price */
          },
          salePrice: {
            label: () => t('productPage.sr.pricingWidget.salePrice.label'), /* Sale price */
          },
        },
        shareButtonsWidget: {
          container: {
            name: () => t('productPage.sr.shareButtonsWidget.container.name'), /* Share on social */
          },
        },
      },
      actionButtonsWidget: {
        gfpp: {
          changeView: () => t('productPage.actionButtonsWidget.gfpp.changeView'), /* Choose Buttons to Preview */
          design: () => t('productPage.actionButtonsWidget.gfpp.design'), /* Design Action Buttons */
        },
        design: {
          panelHeader: () => t('productPage.actionButtonsWidget.design.panelHeader'), /* Action Buttons Design */
          buyNowButton: {
            sectionName: () => t('productPage.actionButtonsWidget.design.buyNowButton.sectionName'), /* Buy Now */
          },
          addToCartButton: {
            sectionName: () => t('productPage.actionButtonsWidget.design.addToCartButton.sectionName'), /* Add to Cart */
          },
          subscribeNow: {
            sectionName: () => t('productPage.actionButtonsWidget.design.subscribeNow.sectionName'), /* Subscribe Now */
            tooltip: {
              text: () => t('productPage.actionButtonsWidget.design.subscribeNow.tooltip.text'), /* This appears when a product has subscription options. To preview it, click the Action Buttons element and select the Subscribe Now state. */
            },
          },
          notifyMe: {
            sectionName: () => t('productPage.actionButtonsWidget.design.notifyMe.sectionName'), /* Notify Me */
            tooltip: {
              learnMoreLink: () => t('productPage.actionButtonsWidget.design.notifyMe.tooltip.learnMoreLink'), /* Learn more */
              text: () => t('productPage.actionButtonsWidget.design.notifyMe.tooltip.text'), /* If you’re collecting back in stock requests, this button will show when the product is out of stock. To preview it, click the Action Buttons element and select the Notify Me state. */
            },
          },
          outOfStock: {
            sectionName: () => t('productPage.actionButtonsWidget.design.outOfStock.sectionName'), /* Out of Stock */
          },
          preOrder: {
            sectionName: () => t('productPage.actionButtonsWidget.design.preOrder.sectionName'), /* Pre-Order */
            tooltip: {
              text: () => t('productPage.actionButtonsWidget.design.preOrder.tooltip.text'), /* This appears when a product with pre-order is out of stock. To preview it, click the Action Buttons element and select the Pre-order state. */
            },
          },
        },
        layout: {
          panelHeader: () => t('productPage.actionButtonsWidget.layout.panelHeader'), /* Action Buttons Layout */
          stacked: {
            option: () => t('productPage.actionButtonsWidget.layout.stacked.option'), /* Stacked */
          },
          sideBySide: {
            option: () => t('productPage.actionButtonsWidget.layout.sideBySide.option'), /* Side by side */
          },
          chooseLayout: {
            label: () => t('productPage.actionButtonsWidget.layout.chooseLayout.label'), /* Layout style */
          },
          direction: {
            LTR: {
              option: () => t('productPage.actionButtonsWidget.layout.direction.LTR.option'), /* Left to right */
            },
            label: () => t('productPage.actionButtonsWidget.layout.direction.label'), /* Direction */
            RTL: {
              option: () => t('productPage.actionButtonsWidget.layout.direction.RTL.option'), /* Right to left */
            },
          },
        },
        changeView: {
          panelHeader: () => t('productPage.actionButtonsWidget.changeView.panelHeader'), /* Preview More Buttons */
          subscribe: {
            option: () => t('productPage.actionButtonsWidget.changeView.subscribe.option'), /* Subscribe Now */
          },
          whichButtons: {
            label: () => t('productPage.actionButtonsWidget.changeView.whichButtons.label'), /* Which buttons to preview? */
            note: {
              text: () => t('productPage.actionButtonsWidget.changeView.whichButtons.note.text'), /* See how these buttons look when a product is out of stock, sold as a subscription, available for pre-order and more. */
            },
          },
          preOrder: {
            option: () => t('productPage.actionButtonsWidget.changeView.preOrder.option'), /* Pre-Order */
          },
          addToCartBuyNow: {
            option: () => t('productPage.actionButtonsWidget.changeView.addToCartBuyNow.option'), /* Add to Cart & Buy Now */
          },
          outOfStock: {
            option: () => t('productPage.actionButtonsWidget.changeView.outOfStock.option'), /* Out of Stock */
          },
          notifyMe: {
            option: () => t('productPage.actionButtonsWidget.changeView.notifyMe.option'), /* Notify Me */
          },
        },
        displayName: () => t('productPage.actionButtonsWidget.displayName'), /* Action Buttons */
      },
      customTextWidget: {
        gfpp: {
          design: () => t('productPage.customTextWidget.gfpp.design'), /* Design Custom Text */
        },
        displayName: () => t('productPage.customTextWidget.displayName'), /* Custom Text */
        design: {
          inputField: {
            panelHeader: () => t('productPage.customTextWidget.design.inputField.panelHeader'), /* Input Field */
            sectionName: () => t('productPage.customTextWidget.design.inputField.sectionName'), /* Input Field */
          },
          title: {
            sectionName: () => t('productPage.customTextWidget.design.title.sectionName'), /* Title */
            panelHeader: () => t('productPage.customTextWidget.design.title.panelHeader'), /* Title */
          },
          characterCount: {
            sectionName: () => t('productPage.customTextWidget.design.characterCount.sectionName'), /* Character Counter */
            panelHeader: () => t('productPage.customTextWidget.design.characterCount.panelHeader'), /* Character Counter */
          },
          panelHeader: () => t('productPage.customTextWidget.design.panelHeader'), /* Custom Text Design */
        },
        errorMessage: {
          requiredField: () => t('productPage.customTextWidget.errorMessage.requiredField'), /* This field is required */
        },
        settings: {
          panelHeader: () => t('productPage.customTextWidget.settings.panelHeader'), /* Custom Text Settings */
          showCharacterCounter: {
            label: () => t('productPage.customTextWidget.settings.showCharacterCounter.label'), /* Show character counter */
          },
        },
        characterCounter: {
          displayName: () => t('productPage.customTextWidget.characterCounter.displayName'), /* Character Counter */
        },
        inputField: {
          displayName: () => t('productPage.customTextWidget.inputField.displayName'), /* Input Field */
        },
        title: {
          displayName: () => t('productPage.customTextWidget.title.displayName'), /* Title */
        },
        optional: {
          label: (data: Record<'customTextTitle', unknown>) => t('productPage.customTextWidget.optional.label', data), /* {customTextTitle} (optional) */
        },
      },
      descriptionWidget: {
        gfpp: {
          design: () => t('productPage.descriptionWidget.gfpp.design'), /* Design Description */
          buttonText: () => t('productPage.descriptionWidget.gfpp.buttonText'), /* Edit Button Text */
        },
        displayName: () => t('productPage.descriptionWidget.displayName'), /* Description */
        design: {
          panelHeader: () => t('productPage.descriptionWidget.design.panelHeader'), /* Description Design */
        },
      },
      pricingWidget: {
        gfpp: {
          design: () => t('productPage.pricingWidget.gfpp.design'), /* Design Pricing */
        },
        design: {
          finalPrice: {
            sectionName: () => t('productPage.pricingWidget.design.finalPrice.sectionName'), /* Price */
            panelHeader: () => t('productPage.pricingWidget.design.finalPrice.panelHeader'), /* Price */
          },
          taxShippingInfo: {
            panelHeader: () => t('productPage.pricingWidget.design.taxShippingInfo.panelHeader'), /* Tax and Shipping Info */
            sectionName: () => t('productPage.pricingWidget.design.taxShippingInfo.sectionName'), /* Tax and Shipping Info */
            tooltip: {
              text: () => t('productPage.pricingWidget.design.taxShippingInfo.tooltip.text'), /* This shows customers if the product price includes tax and shipping. You can set it up from your settings in the Dashboard. */
              learnMoreLink: () => t('productPage.pricingWidget.design.taxShippingInfo.tooltip.learnMoreLink'), /* Learn more */
            },
          },
          strikethroughPrice: {
            sectionName: () => t('productPage.pricingWidget.design.strikethroughPrice.sectionName'), /* Strikethrough Price */
            panelHeader: () => t('productPage.pricingWidget.design.strikethroughPrice.panelHeader'), /* Strikethrough Price */
            tooltip: {
              text: () => t('productPage.pricingWidget.design.strikethroughPrice.tooltip.text'), /* This is what the original price looks like when the product has a sale price. */
            },
          },
          pricePerUnit: {
            panelHeader: () => t('productPage.pricingWidget.design.pricePerUnit.panelHeader'), /* Price per Unit */
            sectionName: () => t('productPage.pricingWidget.design.pricePerUnit.sectionName'), /* Price per Unit */
            tooltip: {
              text: () => t('productPage.pricingWidget.design.pricePerUnit.tooltip.text'), /* This shows customers how much they’ll be spending per unit of the product (e.g., $5 per 100g). You can set it up from each product’s page in the Dashboard. */
            },
          },
          subscription: {
            panelHeader: () => t('productPage.pricingWidget.design.subscription.panelHeader'), /* Subscription Frequency */
            sectionName: () => t('productPage.pricingWidget.design.subscription.sectionName'), /* Subscription Frequency */
            tooltip: {
              text: () => t('productPage.pricingWidget.design.subscription.tooltip.text'), /* This appears next to the price when customers select a specific subscription option. You can see it in preview mode or on your live site. */
            },
          },
          panelHeader: () => t('productPage.pricingWidget.design.panelHeader'), /* Pricing Design */
          fromLabel: {
            sectionName: () => t('productPage.pricingWidget.design.fromLabel.sectionName'), /* "From" Label */
            panelHeader: () => t('productPage.pricingWidget.design.fromLabel.panelHeader'), /* "From" Label */
            tooltip: {
              text: () => t('productPage.pricingWidget.design.fromLabel.tooltip.text'), /* This appears next to the price when there are multiple price options. It can be turned on from your settings in the Dashboard. */
              learnMoreLink: () => t('productPage.pricingWidget.design.fromLabel.tooltip.learnMoreLink'), /* Learn more */
            },
          },
          discountName: {
            sectionName: () => t('productPage.pricingWidget.design.discountName.sectionName'), /* Discount Name */
            tooltip: {
              learnMoreLink: () => t('productPage.pricingWidget.design.discountName.tooltip.learnMoreLink'), /* Learn more */
              text: () => t('productPage.pricingWidget.design.discountName.tooltip.text'), /* This appears under the price when the product is eligible for an automatic discount. You can set this up for each automatic discount in the Dashboard. */
            },
          },
        },
        subscription: {
          displayName: () => t('productPage.pricingWidget.subscription.displayName'), /* Subscription Frequency */
        },
        displayName: () => t('productPage.pricingWidget.displayName'), /* Pricing */
        strikethroughPrice: {
          displayName: () => t('productPage.pricingWidget.strikethroughPrice.displayName'), /* Strikethrough Price */
        },
        taxShippingInfo: {
          displayName: () => t('productPage.pricingWidget.taxShippingInfo.displayName'), /* Tax and Shipping Info */
        },
        finalPrice: {
          displayName: () => t('productPage.pricingWidget.finalPrice.displayName'), /* Price */
        },
        pricePerUnit: {
          displayName: () => t('productPage.pricingWidget.pricePerUnit.displayName'), /* Price per Unit */
        },
        fromLabel: {
          displayName: () => t('productPage.pricingWidget.fromLabel.displayName'), /* "From" Label */
        },
        discountName: {
          displayName: () => t('productPage.pricingWidget.discountName.displayName'), /* Discount Name */
        },
      },
      quantityWidget: {
        gfpp: {
          design: () => t('productPage.quantityWidget.gfpp.design'), /* Design Quantity */
        },
        design: {
          title: {
            sectionName: () => t('productPage.quantityWidget.design.title.sectionName'), /* Title */
            panelHeader: () => t('productPage.quantityWidget.design.title.panelHeader'), /* Title */
          },
          plusButton: {
            panelHeader: () => t('productPage.quantityWidget.design.plusButton.panelHeader'), /* Plus Button */
            sectionName: () => t('productPage.quantityWidget.design.plusButton.sectionName'), /* Plus Button */
          },
          panelHeader: () => t('productPage.quantityWidget.design.panelHeader'), /* Quantity Design */
          inputField: {
            panelHeader: () => t('productPage.quantityWidget.design.inputField.panelHeader'), /* Input Field */
            sectionName: () => t('productPage.quantityWidget.design.inputField.sectionName'), /* Input Field */
          },
          minusButton: {
            sectionName: () => t('productPage.quantityWidget.design.minusButton.sectionName'), /* Minus Button */
            panelHeader: () => t('productPage.quantityWidget.design.minusButton.panelHeader'), /* Minus Button */
          },
        },
        errorMessage: {
          minimumQuantity: (data: Record<'minQuantity', unknown>) => t('productPage.quantityWidget.errorMessage.minimumQuantity', data), /* The minimum quantity is {minQuantity} */
          exceedsInventory: (data: Record<'inventory', unknown>) => t('productPage.quantityWidget.errorMessage.exceedsInventory', data), /* Only {inventory} left in stock */
        },
        inputField: {
          displayName: () => t('productPage.quantityWidget.inputField.displayName'), /* Input Field */
        },
        minusButton: {
          displayName: () => t('productPage.quantityWidget.minusButton.displayName'), /* Minus Button */
        },
        settings: {
          panelHeader: () => t('productPage.quantityWidget.settings.panelHeader'), /* Quantity Settings */
          showTitle: {
            label: () => t('productPage.quantityWidget.settings.showTitle.label'), /* Show title */
          },
          titleText: {
            label: () => t('productPage.quantityWidget.settings.titleText.label'), /* Title text */
          },
        },
        title: {
          displayName: () => t('productPage.quantityWidget.title.displayName'), /* Title */
        },
        plusButton: {
          displayName: () => t('productPage.quantityWidget.plusButton.displayName'), /* Plus Button */
        },
        displayName: () => t('productPage.quantityWidget.displayName'), /* Quantity */
      },
      infoSectionWidget: {
        gfpp: {
          design: () => t('productPage.infoSectionWidget.gfpp.design'), /* Design Info Section */
        },
        design: {
          title: {
            panelHeader: () => t('productPage.infoSectionWidget.design.title.panelHeader'), /* Title */
            sectionName: () => t('productPage.infoSectionWidget.design.title.sectionName'), /* Title */
          },
          body: {
            sectionName: () => t('productPage.infoSectionWidget.design.body.sectionName'), /* Body Text */
            panelHeader: () => t('productPage.infoSectionWidget.design.body.panelHeader'), /* Body Text */
          },
          panelHeader: () => t('productPage.infoSectionWidget.design.panelHeader'), /* Info Section Design */
          divider: {
            panelHeader: () => t('productPage.infoSectionWidget.design.divider.panelHeader'), /* Divider */
            sectionName: () => t('productPage.infoSectionWidget.design.divider.sectionName'), /* Divider */
          },
        },
        layout: {
          panelHeader: () => t('productPage.infoSectionWidget.layout.panelHeader'), /* Info Section Layout */
          accordion: {
            option: () => t('productPage.infoSectionWidget.layout.accordion.option'), /* Accordion */
          },
          cards: {
            option: () => t('productPage.infoSectionWidget.layout.cards.option'), /* Cards */
          },
          alignment: {
            left: {
              option: () => t('productPage.infoSectionWidget.layout.alignment.left.option'), /* Left */
            },
            center: {
              option: () => t('productPage.infoSectionWidget.layout.alignment.center.option'), /* Center */
            },
            label: () => t('productPage.infoSectionWidget.layout.alignment.label'), /* Alignment */
            right: {
              option: () => t('productPage.infoSectionWidget.layout.alignment.right.option'), /* Right */
            },
          },
          columns: {
            option: () => t('productPage.infoSectionWidget.layout.columns.option'), /* Columns */
          },
          stacked: {
            option: () => t('productPage.infoSectionWidget.layout.stacked.option'), /* Stacked */
          },
          chooseLayout: {
            label: () => t('productPage.infoSectionWidget.layout.chooseLayout.label'), /* Layout style */
          },
          expandFirst: {
            checkbox: {
              text: () => t('productPage.infoSectionWidget.layout.expandFirst.checkbox.text'), /* Expand the first info section by default */
            },
          },
          direction: {
            LTR: {
              option: () => t('productPage.infoSectionWidget.layout.direction.LTR.option'), /* Left to right */
            },
            RTL: {
              option: () => t('productPage.infoSectionWidget.layout.direction.RTL.option'), /* Right to left */
            },
            label: () => t('productPage.infoSectionWidget.layout.direction.label'), /* Direction */
          },
          accordionDefault: {
            label: () => t('productPage.infoSectionWidget.layout.accordionDefault.label'), /* Default accordion state */
            tooltip: () => t('productPage.infoSectionWidget.layout.accordionDefault.tooltip'), /* Choose if you want the first info section to be open or closed when customers reach this page. */
            allCollapsed: () => t('productPage.infoSectionWidget.layout.accordionDefault.allCollapsed'), /* All sections closed */
            firstExpanded: () => t('productPage.infoSectionWidget.layout.accordionDefault.firstExpanded'), /* First section open */
          },
        },
        displayName: () => t('productPage.infoSectionWidget.displayName'), /* Info Section */
        title: {
          displayName: () => t('productPage.infoSectionWidget.title.displayName'), /* Title */
        },
        divider: {
          displayName: () => t('productPage.infoSectionWidget.divider.displayName'), /* Divider */
        },
        body: {
          displayName: () => t('productPage.infoSectionWidget.body.displayName'), /* Body Text */
        },
        expandButton: {
          displayName: () => t('productPage.infoSectionWidget.expandButton.displayName'), /* Expand Button */
        },
        collapseButton: {
          displayName: () => t('productPage.infoSectionWidget.collapseButton.displayName'), /* Collapse Button */
        },
      },
      productOptionsWidget: {
        gfpp: {
          design: () => t('productPage.productOptionsWidget.gfpp.design'), /* Design Product Options */
        },
        design: {
          panelHeader: () => t('productPage.productOptionsWidget.design.panelHeader'), /* Product Options Design */
          title: {
            sectionName: () => t('productPage.productOptionsWidget.design.title.sectionName'), /* Titles */
            panelHeader: () => t('productPage.productOptionsWidget.design.title.panelHeader'), /* Titles */
          },
          dropdownList: {
            panelHeader: () => t('productPage.productOptionsWidget.design.dropdownList.panelHeader'), /* Dropdown */
            sectionName: () => t('productPage.productOptionsWidget.design.dropdownList.sectionName'), /* Dropdown */
          },
          colorSwatch: {
            panelHeader: () => t('productPage.productOptionsWidget.design.colorSwatch.panelHeader'), /* Color Swatch */
            sectionName: () => t('productPage.productOptionsWidget.design.colorSwatch.sectionName'), /* Color Swatch */
          },
        },
        settings: {
          panelHeader: () => t('productPage.productOptionsWidget.settings.panelHeader'), /* Product Options Settings */
          showTitle: {
            label: () => t('productPage.productOptionsWidget.settings.showTitle.label'), /* Show title */
          },
        },
        errorMessage: {
          selectOption: (data: Record<'optionTitle', unknown>) => t('productPage.productOptionsWidget.errorMessage.selectOption', data), /* Select {optionTitle} */
        },
        colorSwatch: {
          displayName: () => t('productPage.productOptionsWidget.colorSwatch.displayName'), /* Color Swatch */
        },
        title: {
          displayName: () => t('productPage.productOptionsWidget.title.displayName'), /* Title */
        },
        dropdownList: {
          displayName: () => t('productPage.productOptionsWidget.dropdownList.displayName'), /* Dropdown */
        },
        displayName: () => t('productPage.productOptionsWidget.displayName'), /* Product Options */
        outOfStock: {
          label: (data: Record<'choiceName', unknown>) => t('productPage.productOptionsWidget.outOfStock.label', data), /* {choiceName} (out of stock) */
        },
      },
      productNameWidget: {
        gfpp: {
          design: () => t('productPage.productNameWidget.gfpp.design'), /* Design Product Name */
        },
        displayName: () => t('productPage.productNameWidget.displayName'), /* Product Name */
        design: {
          panelHeader: () => t('productPage.productNameWidget.design.panelHeader'), /* Product Name Design */
        },
      },
      skuWidget: {
        gfpp: {
          design: () => t('productPage.skuWidget.gfpp.design'), /* Design SKU */
        },
        displayName: () => t('productPage.skuWidget.displayName'), /* SKU */
        design: {
          panelHeader: () => t('productPage.skuWidget.design.panelHeader'), /* SKU Design */
          label: {
            sectionName: () => t('productPage.skuWidget.design.label.sectionName'), /* SKU Label */
            panelHeader: () => t('productPage.skuWidget.design.label.panelHeader'), /* SKU Label */
          },
          code: {
            sectionName: () => t('productPage.skuWidget.design.code.sectionName'), /* SKU Code */
            panelHeader: () => t('productPage.skuWidget.design.code.panelHeader'), /* SKU Code */
          },
        },
        settings: {
          panelHeader: () => t('productPage.skuWidget.settings.panelHeader'), /* SKU Settings */
          showLabel: {
            label: () => t('productPage.skuWidget.settings.showLabel.label'), /* Show SKU label */
          },
          labelText: {
            label: () => t('productPage.skuWidget.settings.labelText.label'), /* SKU label text */
          },
        },
        skuLabel: {
          displayName: () => t('productPage.skuWidget.skuLabel.displayName'), /* SKU Label */
        },
        skuCode: {
          displayName: () => t('productPage.skuWidget.skuCode.displayName'), /* SKU Code */
        },
      },
      prevNextWidget: {
        previousButton: {
          design: {
            panelHeader: () => t('productPage.prevNextWidget.previousButton.design.panelHeader'), /* Previous Button Design */
          },
          layout: {
            panelHeader: () => t('productPage.prevNextWidget.previousButton.layout.panelHeader'), /* Previous Button Layout */
          },
          displayName: () => t('productPage.prevNextWidget.previousButton.displayName'), /* Previous Button */
          settings: {
            panelHeader: () => t('productPage.prevNextWidget.previousButton.settings.panelHeader'), /* Previous Button Settings */
          },
        },
        nextButton: {
          layout: {
            panelHeader: () => t('productPage.prevNextWidget.nextButton.layout.panelHeader'), /* Next Button Layout */
          },
          displayName: () => t('productPage.prevNextWidget.nextButton.displayName'), /* Next Button */
          design: {
            panelHeader: () => t('productPage.prevNextWidget.nextButton.design.panelHeader'), /* Next Button Design */
          },
          settings: {
            panelHeader: () => t('productPage.prevNextWidget.nextButton.settings.panelHeader'), /* Next Button Settings */
          },
        },
        design: {
          buttons: {
            panelHeader: () => t('productPage.prevNextWidget.design.buttons.panelHeader'), /* Pre/Next Buttons */
            sectionName: () => t('productPage.prevNextWidget.design.buttons.sectionName'), /* Previous/Next Buttons */
          },
          panelHeader: () => t('productPage.prevNextWidget.design.panelHeader'), /* Previous/Next Design */
          divider: {
            panelHeader: () => t('productPage.prevNextWidget.design.divider.panelHeader'), /* Divider */
            sectionName: () => t('productPage.prevNextWidget.design.divider.sectionName'), /* Divider */
          },
        },
        layout: {
          panelHeader: () => t('productPage.prevNextWidget.layout.panelHeader'), /* Previous/Next Layout */
        },
        gfpp: {
          design: () => t('productPage.prevNextWidget.gfpp.design'), /* Design Previous/Next */
        },
        displayName: () => t('productPage.prevNextWidget.displayName'), /* Previous/Next */
        divider: {
          displayName: () => t('productPage.prevNextWidget.divider.displayName'), /* Divider */
        },
      },
      stockWidget: {
        design: {
          lowStock: {
            sectionName: () => t('productPage.stockWidget.design.lowStock.sectionName'), /* Low Stock */
            panelHeader: () => t('productPage.stockWidget.design.lowStock.panelHeader'), /* Low Stock */
            tooltip: {
              text: () => t('productPage.stockWidget.design.lowStock.tooltip.text'), /* This is a notification that appears when your product is low in stock. To set up when it appears, click the Stock Status element and select Settings. */
            },
          },
          outOfStock: {
            sectionName: () => t('productPage.stockWidget.design.outOfStock.sectionName'), /* Out of Stock */
            panelHeader: () => t('productPage.stockWidget.design.outOfStock.panelHeader'), /* Out of Stock */
            tooltip: {
              text: () => t('productPage.stockWidget.design.outOfStock.tooltip.text'), /* If you're collecting back in stock requests with the Notify Me button, this out of stock message will show instead of your Out of Stock button. */
            },
          },
          panelHeader: () => t('productPage.stockWidget.design.panelHeader'), /* Stock Status Design */
        },
        displayName: () => t('productPage.stockWidget.displayName'), /* Stock Status */
        gfpp: {
          design: () => t('productPage.stockWidget.gfpp.design'), /* Design Stock Status */
        },
        settings: {
          panelHeader: () => t('productPage.stockWidget.settings.panelHeader'), /* Stock Status Settings */
          lowStockLevel: {
            label: () => t('productPage.stockWidget.settings.lowStockLevel.label'), /* Show when stock level reaches: */
          },
          outOfStock: {
            sectionHeader: {
              text: () => t('productPage.stockWidget.settings.outOfStock.sectionHeader.text'), /* Out of Stock */
              tooltip: () => t('productPage.stockWidget.settings.outOfStock.sectionHeader.tooltip'), /* If you're collecting back in stock requests with the Notify Me button, this out of stock message will show instead of your Out of Stock button. */
            },
            messageText: {
              label: () => t('productPage.stockWidget.settings.outOfStock.messageText.label'), /* Out of stock message */
            },
          },
          lowStock: {
            sectionHeader: {
              text: () => t('productPage.stockWidget.settings.lowStock.sectionHeader.text'), /* Low Stock */
            },
          },
          showLowStock: {
            label: () => t('productPage.stockWidget.settings.showLowStock.label'), /* Show low stock message */
          },
        },
        lowStock: {
          displayName: () => t('productPage.stockWidget.lowStock.displayName'), /* Low Stock */
        },
        outOfStock: {
          displayName: () => t('productPage.stockWidget.outOfStock.displayName'), /* Out of Stock */
        },
      },
      outOfStockButton: {
        design: {
          panelHeader: () => t('productPage.outOfStockButton.design.panelHeader'), /* Out of Stock Design */
        },
        layout: {
          panelHeader: () => t('productPage.outOfStockButton.layout.panelHeader'), /* Out of Stock Layout */
        },
        displayName: () => t('productPage.outOfStockButton.displayName'), /* Out of Stock Button */
      },
      preOrderButton: {
        displayName: () => t('productPage.preOrderButton.displayName'), /* Pre-Order Button */
        design: {
          panelHeader: () => t('productPage.preOrderButton.design.panelHeader'), /* Pre-Order Design */
        },
        layout: {
          panelHeader: () => t('productPage.preOrderButton.layout.panelHeader'), /* Pre-Order Layout */
        },
        text: () => t('productPage.preOrderButton.text'), /* Pre-Order */
        settings: {
          panelHeader: () => t('productPage.preOrderButton.settings.panelHeader'), /* Pre-Order Settings */
          onClick: {
            succesPopUp: {
              option: () => t('productPage.preOrderButton.settings.onClick.succesPopUp.option'), /* Open success pop-up */
            },
            cartPage: {
              option: () => t('productPage.preOrderButton.settings.onClick.cartPage.option'), /* Go to cart page */
            },
            miniCart: {
              option: () => t('productPage.preOrderButton.settings.onClick.miniCart.option'), /* Open mini cart */
            },
            checkmark: {
              option: () => t('productPage.preOrderButton.settings.onClick.checkmark.option'), /* Show check mark on button */
            },
            checkout: {
              option: () => t('productPage.preOrderButton.settings.onClick.checkout.option'), /* Go straight to checkout */
            },
            label: () => t('productPage.preOrderButton.settings.onClick.label'), /* When button is clicked: */
            tooltip: {
              text: () => t('productPage.preOrderButton.settings.onClick.tooltip.text'), /* Choose what happens when customers click the button. */
            },
            sideCart: {
              option: () => t('productPage.preOrderButton.settings.onClick.sideCart.option'), /* Open side cart */
            },
          },
        },
      },
      buyNowButton: {
        layout: {
          panelHeader: () => t('productPage.buyNowButton.layout.panelHeader'), /* Buy Now Layout */
        },
        design: {
          panelHeader: () => t('productPage.buyNowButton.design.panelHeader'), /* Buy Now Design */
        },
        displayName: () => t('productPage.buyNowButton.displayName'), /* Buy Now Button */
      },
      subscribeNowButton: {
        displayName: () => t('productPage.subscribeNowButton.displayName'), /* Subscribe Now Button */
        layout: {
          panelHeader: () => t('productPage.subscribeNowButton.layout.panelHeader'), /* Subscribe Now Layout */
        },
        design: {
          panelHeader: () => t('productPage.subscribeNowButton.design.panelHeader'), /* Subscribe Now Design */
        },
      },
      notifyMeButton: {
        displayName: () => t('productPage.notifyMeButton.displayName'), /* Notify Me Button */
        layout: {
          panelHeader: () => t('productPage.notifyMeButton.layout.panelHeader'), /* Notify Me Layout */
        },
        design: {
          panelHeader: () => t('productPage.notifyMeButton.design.panelHeader'), /* Notify Me Design */
        },
        text: () => t('productPage.notifyMeButton.text'), /* Notify When Available */
      },
      addToCartButton: {
        design: {
          panelHeader: () => t('productPage.addToCartButton.design.panelHeader'), /* Add to Cart Design */
        },
        settings: {
          panelHeader: () => t('productPage.addToCartButton.settings.panelHeader'), /* Add to Cart Settings */
          onClick: {
            label: () => t('productPage.addToCartButton.settings.onClick.label'), /* When button is clicked: */
            tooltip: {
              text: () => t('productPage.addToCartButton.settings.onClick.tooltip.text'), /* Choose what happens when customers click the button. */
            },
            succesPopUp: {
              option: () => t('productPage.addToCartButton.settings.onClick.succesPopUp.option'), /* Open success pop-up */
            },
            checkmark: {
              option: () => t('productPage.addToCartButton.settings.onClick.checkmark.option'), /* Show check mark on button */
            },
            cartPage: {
              option: () => t('productPage.addToCartButton.settings.onClick.cartPage.option'), /* Go to cart page */
            },
            miniCart: {
              option: () => t('productPage.addToCartButton.settings.onClick.miniCart.option'), /* Open mini cart */
            },
            sideCart: {
              option: () => t('productPage.addToCartButton.settings.onClick.sideCart.option'), /* Open side cart */
            },
          },
        },
        layout: {
          panelHeader: () => t('productPage.addToCartButton.layout.panelHeader'), /* Add to Cart Layout */
        },
        displayName: () => t('productPage.addToCartButton.displayName'), /* Add to Cart Button */
      },
      nextButton: {
        text: () => t('productPage.nextButton.text'), /* Next */
      },
      previousButton: {
        text: () => t('productPage.previousButton.text'), /* Previous */
      },
      outOfStockMessage: {
        label: () => t('productPage.outOfStockMessage.label'), /* Out of stock */
      },
      lowStockMessage: {
        label: (data: Record<'Inventory', unknown>) => t('productPage.lowStockMessage.label', data), /* Only {Inventory} left in stock */
      },
      twitterButton: {
        design: {
          panelHeader: () => t('productPage.twitterButton.design.panelHeader'), /* Twitter Button Design */
        },
        settings: {
          panelHeader: () => t('productPage.twitterButton.settings.panelHeader'), /* Twitter Button Settings */
        },
        displayName: () => t('productPage.twitterButton.displayName'), /* Twitter Button */
        layout: {
          panelHeader: () => t('productPage.twitterButton.layout.panelHeader'), /* Twitter Button Layout */
        },
        text: () => t('productPage.twitterButton.text'), /* Twitter */
      },
      shareButtonsWidget: {
        gfpp: {
          design: () => t('productPage.shareButtonsWidget.gfpp.design'), /* Design Share Buttons */
        },
        displayName: () => t('productPage.shareButtonsWidget.displayName'), /* Share Buttons */
        design: {
          panelHeader: () => t('productPage.shareButtonsWidget.design.panelHeader'), /* Share Buttons Design */
        },
        layout: {
          panelHeader: () => t('productPage.shareButtonsWidget.layout.panelHeader'), /* Share Buttons Layout */
        },
      },
      facebookButton: {
        layout: {
          panelHeader: () => t('productPage.facebookButton.layout.panelHeader'), /* Facebook Button Layout */
        },
        settings: {
          panelHeader: () => t('productPage.facebookButton.settings.panelHeader'), /* Facebook Button Settings */
        },
        design: {
          panelHeader: () => t('productPage.facebookButton.design.panelHeader'), /* Facebook Button Design */
        },
        displayName: () => t('productPage.facebookButton.displayName'), /* Facebook Button */
        text: () => t('productPage.facebookButton.text'), /* Facebook */
      },
      pinterestButton: {
        design: {
          panelHeader: () => t('productPage.pinterestButton.design.panelHeader'), /* Pinterest Button Design */
        },
        layout: {
          panelHeader: () => t('productPage.pinterestButton.layout.panelHeader'), /* Pinterest Button Layout */
        },
        settings: {
          panelHeader: () => t('productPage.pinterestButton.settings.panelHeader'), /* Pinterest Button Settings */
        },
        displayName: () => t('productPage.pinterestButton.displayName'), /* Pinterest Button */
        text: () => t('productPage.pinterestButton.text'), /* Pinterest */
      },
      whatsappButton: {
        layout: {
          panelHeader: () => t('productPage.whatsappButton.layout.panelHeader'), /* WhatsApp Button Layout */
        },
        displayName: () => t('productPage.whatsappButton.displayName'), /* WhatsApp Button */
        design: {
          panelHeader: () => t('productPage.whatsappButton.design.panelHeader'), /* WhatsApp Button Design */
        },
        settings: {
          panelHeader: () => t('productPage.whatsappButton.settings.panelHeader'), /* WhatsApp Button Settings */
        },
        text: () => t('productPage.whatsappButton.text'), /* WhatsApp */
      },
      shippingOption: {
        pickup: {
          addressFormatSubdivision: (data: Record<'addressLine' | 'city' | 'subdivision' | 'zipCode' | 'country', unknown>) => t('productPage.shippingOption.pickup.addressFormatSubdivision', data), /* {addressLine}, {city}, {subdivision}, {zipCode}, {country} */
        },
      },
      galleryWidget: {
        gfpp: {
          layout: () => t('productPage.galleryWidget.gfpp.layout'), /* Change Layout */
        },
        displayName: () => t('productPage.galleryWidget.displayName'), /* Gallery */
      },
      wishlistButton: {
        design: {
          removeFromWishlist: {
            panelHeader: () => t('productPage.wishlistButton.design.removeFromWishlist.panelHeader'), /* Remove from Wishlist */
            sectionName: () => t('productPage.wishlistButton.design.removeFromWishlist.sectionName'), /* Remove from Wishlist */
          },
          panelHeader: () => t('productPage.wishlistButton.design.panelHeader'), /* Wishlist Button Design */
          addToWishist: {
            panelHeader: () => t('productPage.wishlistButton.design.addToWishist.panelHeader'), /* Add to Wishlist */
            sectionName: () => t('productPage.wishlistButton.design.addToWishist.sectionName'), /* Add to Wishlist */
          },
        },
        displayName: () => t('productPage.wishlistButton.displayName'), /* Wishlist Button */
        gfpp: {
          design: () => t('productPage.wishlistButton.gfpp.design'), /* Design Wishlist Button */
        },
      },
      preOrderMessageWidget: {
        displayName: () => t('productPage.preOrderMessageWidget.displayName'), /* Pre-order message */
        gfppdesign: () => t('productPage.preOrderMessageWidget.gfppdesign'), /* Design Pre-Order Message */
        designpanelHeader: () => t('productPage.preOrderMessageWidget.designpanelHeader'), /* Pre-Order Message Design */
        placeholderText: () => t('productPage.preOrderMessageWidget.placeholderText'), /* Your pre-order message will show up here. */
      },
      subscriptionsWidget: {
        optionName: {
          displayName: () => t('productPage.subscriptionsWidget.optionName.displayName'), /* Option Name */
        },
        interval: {
          years: (data: Record<'interval' | 'year' | '# years', unknown>) => t('productPage.subscriptionsWidget.interval.years', data), /* every {interval, plural, =1 {year} other {# years}} */
          weeks: (data: Record<'interval' | 'week' | '# weeks', unknown>) => t('productPage.subscriptionsWidget.interval.weeks', data), /* every {interval, plural, =1 {week} other {# weeks}} */
          months: (data: Record<'interval' | 'month' | '# months', unknown>) => t('productPage.subscriptionsWidget.interval.months', data), /* every {interval, plural, =1 {month} other {# months}} */
        },
        design: {
          container: {
            sectionName: () => t('productPage.subscriptionsWidget.design.container.sectionName'), /* Container */
          },
          optionName: {
            sectionName: () => t('productPage.subscriptionsWidget.design.optionName.sectionName'), /* Option Names */
          },
          radioButton: {
            sectionName: () => t('productPage.subscriptionsWidget.design.radioButton.sectionName'), /* Radio Buttons */
          },
          frequencyDuration: {
            sectionName: () => t('productPage.subscriptionsWidget.design.frequencyDuration.sectionName'), /* Frequency & Duration */
          },
          tagline: {
            sectionName: () => t('productPage.subscriptionsWidget.design.tagline.sectionName'), /* Taglines */
          },
          panelHeader: () => t('productPage.subscriptionsWidget.design.panelHeader'), /* Subscription Options */
          price: {
            sectionName: () => t('productPage.subscriptionsWidget.design.price.sectionName'), /* Prices */
          },
          title: {
            sectionName: () => t('productPage.subscriptionsWidget.design.title.sectionName'), /* Title */
          },
        },
        frequencyDuration: {
          displayName: () => t('productPage.subscriptionsWidget.frequencyDuration.displayName'), /* Frequency & Duration */
          container: (data: Record<'interval' | 'duration', unknown>) => t('productPage.subscriptionsWidget.frequencyDuration.container', data), /* {interval} {duration} */
        },
        settings: {
          panelHeader: () => t('productPage.subscriptionsWidget.settings.panelHeader'), /* Subscriptions Settings */
          titleText: {
            label: () => t('productPage.subscriptionsWidget.settings.titleText.label'), /* Title text */
          },
          oneTimePurchaseLocation: {
            first: {
              option: () => t('productPage.subscriptionsWidget.settings.oneTimePurchaseLocation.first.option'), /* First */
            },
            label: () => t('productPage.subscriptionsWidget.settings.oneTimePurchaseLocation.label'), /* Display the one-time purchase option: */
            tooltip: {
              text: () => t('productPage.subscriptionsWidget.settings.oneTimePurchaseLocation.tooltip.text'), /* This option will only appear if customers can choose between subscribing and making a one-time purchase. */
            },
            last: {
              option: () => t('productPage.subscriptionsWidget.settings.oneTimePurchaseLocation.last.option'), /* Last */
            },
          },
          showTitle: {
            label: () => t('productPage.subscriptionsWidget.settings.showTitle.label'), /* Show title */
          },
        },
        tagline: {
          displayName: () => t('productPage.subscriptionsWidget.tagline.displayName'), /* Tagline */
        },
        duration: {
          months: (data: Record<'duration' | '1 month' | '# months', unknown>) => t('productPage.subscriptionsWidget.duration.months', data), /* for {duration, plural, =1 {1 month} other {# months}} */
          weeks: (data: Record<'duration' | '1 week' | '# weeks', unknown>) => t('productPage.subscriptionsWidget.duration.weeks', data), /* for {duration, plural, =1 {1 week} other {# weeks}} */
          years: (data: Record<'duration' | '1 year' | '# years', unknown>) => t('productPage.subscriptionsWidget.duration.years', data), /* for {duration, plural, =1 {1 year} other {# years}} */
          noExpiration: () => t('productPage.subscriptionsWidget.duration.noExpiration'), /* until canceled */
        },
        price: {
          displayName: () => t('productPage.subscriptionsWidget.price.displayName'), /* Price */
        },
        layout: {
          panelHeader: () => t('productPage.subscriptionsWidget.layout.panelHeader'), /* Subscriptions Layout */
          list: {
            option: () => t('productPage.subscriptionsWidget.layout.list.option'), /* List */
          },
          alignment: {
            center: {
              option: () => t('productPage.subscriptionsWidget.layout.alignment.center.option'), /* Center */
            },
            left: {
              option: () => t('productPage.subscriptionsWidget.layout.alignment.left.option'), /* Left */
            },
            label: () => t('productPage.subscriptionsWidget.layout.alignment.label'), /* Alignment */
            right: {
              option: () => t('productPage.subscriptionsWidget.layout.alignment.right.option'), /* Right */
            },
          },
          cards: {
            option: () => t('productPage.subscriptionsWidget.layout.cards.option'), /* Cards */
          },
          chooseLayout: {
            label: () => t('productPage.subscriptionsWidget.layout.chooseLayout.label'), /* Layout style */
          },
          direction: {
            LTR: {
              option: () => t('productPage.subscriptionsWidget.layout.direction.LTR.option'), /* Left to right */
            },
            label: () => t('productPage.subscriptionsWidget.layout.direction.label'), /* Direction */
            RTL: {
              option: () => t('productPage.subscriptionsWidget.layout.direction.RTL.option'), /* Right to left */
            },
          },
        },
        radioButton: {
          displayName: () => t('productPage.subscriptionsWidget.radioButton.displayName'), /* Radio Button */
        },
        gfpp: {
          design: () => t('productPage.subscriptionsWidget.gfpp.design'), /* Design Subscription Options */
        },
        title: {
          displayName: () => t('productPage.subscriptionsWidget.title.displayName'), /* Title */
        },
        displayName: () => t('productPage.subscriptionsWidget.displayName'), /* Subscription Options */
      },
      planPrice: {
        weeks: {
          label: (data: Record<'interval' | 'per week' | 'every # weeks', unknown>) => t('productPage.planPrice.weeks.label', data), /* {interval, plural, =1 {per week} other {every # weeks}} */
        },
        months: {
          label: (data: Record<'interval' | 'per month' | 'every # months', unknown>) => t('productPage.planPrice.months.label', data), /* {interval, plural, =1 {per month} other {every # months}} */
        },
        years: {
          label: (data: Record<'interval' | 'per year' | 'every # years', unknown>) => t('productPage.planPrice.years.label', data), /* {interval, plural, =1 {per year} other {every # years}} */
        },
      },
      category: {
        allProducts: () => t('productPage.category.allProducts'), /* All Products */
      },
    },
  };
}

export type ILocaleKeys = ReturnType<typeof LocaleKeys>;
