import {DropdownComponentIds} from '../../../components/dropdownWidget/config/constants';
import {ColorItemComponentIds, ColorWidgetComponentIds} from '../../../components/colorPickerWidget/config/constants';
import {getRole} from './getRole';
import {helpIds} from '../../../constants/app';
import {WidgetDesignBuilderFn} from '../../editor.app.types';

export const optionsDesignBuilderFn: WidgetDesignBuilderFn = (widgetDesignBuilder, t): void => {
  widgetDesignBuilder.set({
    title: t.productPage.productOptionsWidget.design.panelHeader(),
    customHelpId: helpIds.PRODUCT_OPTIONS,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.productOptionsWidget.design.title.sectionName(),
        })
        .groups()
        .set({
          roles: [ColorWidgetComponentIds.Label, DropdownComponentIds.Label].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.productOptionsWidget.design.dropdownList.sectionName(),
        })
        .groups()
        .set({
          roles: [DropdownComponentIds.Dropdown].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({label: t.productPage.productOptionsWidget.design.colorSwatch.sectionName()})
        .groups()
        .set({
          roles: [
            ColorItemComponentIds.DefaultCircle,
            ColorItemComponentIds.ErrorCircle,
            ColorItemComponentIds.SelectedCircle,
            ColorItemComponentIds.ColorFill,
          ].map(getRole),
        });
    });
};
