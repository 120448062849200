import {Methods} from '../../../types/type';

export const methods: Methods = {
  getProduct: {
    public: true,
    params: [],
    documentation: {
      link: 'https://www.wix.com/velo/reference/$w/productpage/getproduct',
      label: 'Read more',
    },
    description: 'Gets all the information associated with the current product',
    returnsDescription: 'Promise<$w.ProductPage.Product>',
  },
  getCustomTextFieldsValues: {
    public: false,
    params: [],
    description: 'get custom text fields values',
    returnsDescription: 'Promise<string[]>',
  },
  getCustomText: {
    public: true,
    params: [],
    description: "Gets the values of a product's custom text fields.",
    returnsDescription: 'Promise<string[]>',
    documentation: {
      link: 'https://www.wix.com/velo/reference/$w/productpage/getcustomtext',
      label: 'Read more',
    },
  },
  setAddToCartLabel: {
    public: true,
    params: [
      {
        name: 'label',
        type: 'string',
        description: "A string representing the Add To Cart button's new label.",
      },
    ],
    description: "Sets the Add To Cart button's label.",
    returnsDescription: 'Promise<void>',
    documentation: {
      link: 'https://www.wix.com/velo/reference/$w/productpage/setaddtocartlabel',
      label: 'Read more',
    },
  },
  setBuyNowLabel: {
    public: true,
    params: [
      {
        name: 'label',
        type: 'string',
        description: "A string representing the Buy Now button's new label.",
      },
    ],
    description: "Sets the Buy Now button's label.",
    returnsDescription: 'Promise<void>',
    documentation: {
      link: 'https://www.wix.com/velo/reference/$w/productpage/setbuynowlabel',
      label: 'Read more',
    },
  },
  onAddToCart: {
    public: true,
    params: [
      {
        name: 'addToCartEventHandler',
        type: 'Function',
        description: 'The function to run when the Add To Cart button in the Product Page is clicked.',
      },
    ],
    description: 'Adds an event handler that runs when the Add To Cart button in the Product Page is clicked.',
    returnsDescription: 'Promise<void>',
    documentation: {
      link: 'https://www.wix.com/velo/reference/$w/productpage/onaddtocart',
      label: 'Read more',
    },
  },
  onBuyNow: {
    public: true,
    params: [
      {
        name: 'buyNowEventHandler',
        type: 'Function',
        description: 'The function to run when the Buy Now button in the Product Page is clicked.',
      },
    ],
    description: 'Adds an event handler that runs when the Buy Now button in the Product Page is clicked.',
    returnsDescription: 'Promise<void>',
    documentation: {
      link: 'https://www.wix.com/velo/reference/$w/productpage/onbuynow',
      label: 'Read more',
    },
  },
  onChoiceSelected: {
    public: true,
    params: [
      {
        name: 'choiceSelectedEventHandler',
        type: 'Function',
        description: 'The function to run when a product option choice is selected.',
      },
    ],
    description: 'Adds an event handler that runs when a product option choice is selected.',
    returnsDescription: 'Promise<void>',
    documentation: {
      link: 'https://www.wix.com/velo/reference/$w/productpage/onchoiceselected',
      label: 'Read more',
    },
  },
  getSelectedVariantId: {
    public: true,
    params: [],
    description: 'Gets the variant ID of the selected product variant.',
    returnsDescription: 'Promise<string>',
    documentation: {
      link: 'https://www.wix.com/velo/reference/$w/productpage/getselectedvariantid',
      label: 'Read more',
    },
  },
  getSelectedChoices: {
    public: true,
    params: [],
    description: 'Gets the selected product option choices of a product in the Product Page.',
    returnsDescription: 'Promise<object>',
    documentation: {
      link: 'https://www.wix.com/velo/reference/$w/productpage/getselectedchoices',
      label: 'Read more',
    },
  },
  getQuantity: {
    public: true,
    params: [],
    description: 'Gets the quantity of a product in the Product Page.',
    returnsDescription: 'Promise<number>',
    documentation: {
      link: 'https://www.wix.com/velo/reference/$w/productpage/getquantity',
      label: 'Read more',
    },
  },
  setQuantity: {
    public: true,
    params: [
      {
        name: 'value',
        type: 'number',
        description: "A number representing a product's new quantity",
      },
    ],
    description: 'Sets the quantity of a product in the Product Page.',
    returnsDescription: 'Promise<number>',
    documentation: {
      link: 'https://www.wix.com/velo/reference/$w/productpage/setquantity',
      label: 'Read more',
    },
  },
  onQuantityChanged: {
    public: true,
    params: [
      {
        name: 'quantityChangedEventHandler',
        type: 'Function',
        description: "The function to run when a product's quantity is changed",
      },
    ],
    description: "Adds an event handler that runs when a product's quantity is changed.",
    returnsDescription: 'Promise<number>',
    documentation: {
      link: 'https://www.wix.com/velo/reference/$w/productpage/onquantitychanged',
      label: 'Read more',
    },
  },
};
