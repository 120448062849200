import {ConnectedComponentsBuilder, WidgetBuilder} from '../../editor.app.types';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {getRole} from '../global-design/getRole';
import {QuantityWidgetComponentIds as ComponentIds} from '../../../components/quantityWidget/config/constants';
import {setComboBoxInputDesignPanelsWithHiddenErrorState} from '../../panels/comboBoxInputPanel';
import {setComponentsAsUnselectable} from './helpers/setComponentsAsUnselectable';

export const setQuantityInnerComponentsStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder
    .configureConnectedComponents(getRole(ComponentIds.Increase), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.quantityWidget.plusButton.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.Decrease), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.quantityWidget.minusButton.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.Input), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.quantityWidget.inputField.displayName()});
      setComboBoxInputDesignPanelsWithHiddenErrorState(innerWidgetBuilder);
    })
    .configureConnectedComponents(getRole(ComponentIds.Label), (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.quantityWidget.title.displayName()});
    });
  setComponentsAsUnselectable(
    [ComponentIds.Increase, ComponentIds.Decrease, ComponentIds.Input, ComponentIds.ErrorMessage, ComponentIds.Label],
    widgetBuilder,
  );
};
