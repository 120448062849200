import {getRole} from './getRole';
import {CtaButtonsWidgetComponentIds} from '../../../components/ctaButtonsWidget/config/constants';
import {helpIds} from '../../../constants/app';
import {WidgetDesignBuilderFn} from '../../editor.app.types';

export const ctaButtonsDesignBuilderFn: WidgetDesignBuilderFn = (widgetDesignBuilder, t): void => {
  widgetDesignBuilder.set({
    title: t.productPage.actionButtonsWidget.design.panelHeader(),
    customHelpId: helpIds.CTA_BUTTONS,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.actionButtonsWidget.design.addToCartButton.sectionName(),
        })
        .groups()
        .set({
          roles: [CtaButtonsWidgetComponentIds.AddToCartBtn].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.actionButtonsWidget.design.buyNowButton.sectionName(),
        })
        .groups()
        .set({
          roles: [CtaButtonsWidgetComponentIds.BuyNowBtn].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.actionButtonsWidget.design.preOrder.sectionName(),
          tooltip: t.productPage.actionButtonsWidget.design.preOrder.tooltip.text(),
        })
        .groups()
        .set({
          roles: [CtaButtonsWidgetComponentIds.PreOrderButton].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.actionButtonsWidget.design.outOfStock.sectionName(),
        })
        .groups()
        .set({
          roles: [CtaButtonsWidgetComponentIds.OutOfStockBtn].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.actionButtonsWidget.design.subscribeNow.sectionName(),
          tooltip: t.productPage.actionButtonsWidget.design.subscribeNow.tooltip.text(),
        })
        .groups()
        .set({
          roles: [CtaButtonsWidgetComponentIds.SubscribeNowBtn].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.actionButtonsWidget.design.notifyMe.sectionName(),
          tooltip: t.productPage.actionButtonsWidget.design.notifyMe.tooltip.text(),
        })
        .groups()
        .set({
          roles: [CtaButtonsWidgetComponentIds.NotifyMeBtn].map(getRole),
        });
    });
};
