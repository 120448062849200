export const InfoSectionItemComponentIds = {
  Title: '#sectionTitle',
  TitleBox: '#clickBox',
  Paragraph: '#sectionDescription',
  SeparatorLineVertical: '#verticalLine',
  SeparatorLineHorizontal: '#horizontalLine',
  PlusIcon: '#plus',
  MinusIcon: '#minus',
  RicoParagraph: '#ricoInfoSection',
} as const;

export const InfoSectionWidgetComponentIds = {
  Repeater: '#infoRepeater',
  TopContainer: '#box1',
} as const;
