import {WIX_ECOM, WIX_NEW_STORES} from '@wix/app-definition-ids';
import rootComp from '../components/rootWidget/.component.json';

export const ECOM_APP_DEF_ID = WIX_ECOM;
export const STORES_APP_DEF_ID = WIX_NEW_STORES;
export const BLOCKS_PRODUCT_PAGE_WIDGET_ID = rootComp.id;
export const origin = 'product-page-on-blocks';
export const ROOT_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-v8gqn';
export const NAVIGATION_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-mt2f0';
export const QUANTITY_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-x4vxv';
export const SKU_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-uu804';
export const CUSTOM_TEXT_FIELDS_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-h9lrc';
export const INFO_SECTION_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-x7lat';
export const NEW_PRODUCT_OPTIONS_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-yl1zs';
export const STOCK_INDICATOR_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-fgl5b';
export const DROPDOWN_OPTIONS_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-bkcdi';
export const COLOR_PICKER_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-r7gvz';
export const CTA_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-bqb3v';
export const PRICING_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-hxdg5';
export const SOCIAL_SHARE_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-b4kha';
export const SUBSCRIPTION_WIDGET_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087-mp016';
export const STORES_PREMIUM = 'stores_basic_premium';

export const rootWidgetPresetsIds = {
  'X - 2 columns - right gallery 50:50': 'variants-kottona7',
  'X - 2 columns - center gallery 50:50': 'variants-l5i33h8l',
  'X - 2 columns - right gallery 70:30': 'variants-l054vu46',
  '1 column / mobile': 'variants-kquwhsvv',
  'mobile - center align': 'variants-l9wihvlj',
  '1 column - left align': 'variants-l74uae5w',
  '1 column - center align': 'variants-l9whrvl8',
  '2 columns - 50:50 - left align': 'variants-kyldlyx0',
  '2 columns - 66:33 - center align': 'variants-l74hlryw',
  'RTL column / mobile': 'variants-lkfpanij',
  'RTL mobile - center align': 'variants-kquwhsvv',
  'RTL 1 column - left align': 'variants-lkfpbd2e',
  'RTL 1 column - center align': 'variants-lkfpbpnq',
  'RTL 2 columns - 50:50 - left align': 'variants-lkfpcng3',
  'RTL 2 columns - 50:50 - center align': 'variants-lkfpd2kq',
  'RTL 2 columns - 66:33 - left align': 'variants-lkfpddr2',
  'RTL 2 columns - 66:33 - center align': 'variants-lkfpdux9',
};

export const defaultVariants = {
  'breakpoints-k3sr3k4j': {
    type: 'BreakpointsData',
    values: [
      {
        type: 'BreakpointRange',
        id: 'breakpoints-kc1s7zda1',
        min: 320,
        max: 1000,
      },
      {
        type: 'BreakpointRange',
        id: 'breakpoints-kc1s7zda',
        min: 320,
        max: 750,
      },
    ],
  },
};

export const defaultBreakpoints = {
  type: 'BreakpointsData',
  id: 'breakpoints-k3sr3k4j',
  values: [
    {
      type: 'BreakpointRange',
      id: 'breakpoints-kc1s7zda1',
      min: 320,
      max: 1000,
    },
    {
      type: 'BreakpointRange',
      id: 'breakpoints-kc1s7zda',
      min: 320,
      max: 750,
    },
  ],
};

export enum helpIds {
  MAIN_WIDGET = '1d6b3236-0079-417f-8ce1-7c6b53ad9709',
  BREADCRUMBS = 'b5a71885-2079-41c2-8a79-7a998bc22da5',
  NAVIGATION = '6f15fab9-d097-4244-a253-20f73bf0463e',
  PRODUCT_NAME = '161349c6-36a9-4779-9fd0-4efd5805754f',
  SKU = '83308a96-9396-44c2-aa70-32a480add19d',
  PRICING = 'a6e0b7e6-a597-4550-8358-bcaaa2fa4146',
  DESCRIPTION = '71d1de26-c319-4393-b468-0e148a471ed6',
  PRODUCT_OPTIONS = '06183783-06ed-45e9-9746-d4d8094527e8',
  QUANTITY = '08a0b23c-34a3-4553-b01b-759936b9d2bf',
  STOCK_INDICATOR = 'e04d9d20-76ec-46cb-9974-6683fa164591',
  CTA_BUTTONS = '874f45ff-44d4-43e9-8d0c-576207f44797',
  PRO_GALLERY = '3d36bcc7-3929-4495-a371-0f780e0aff77',
  SUBSCRIPTIONS = '2dafb191-98ae-4797-83df-03f6ac8eb1e9',
  CUSTOM_TEXT_FIELDS = '2132359a-5fc5-4879-9e06-c7209c46018e',
  INFO_SECTIONS = '1346b007-8f56-4691-9bf9-901dd866f75f',
  SHARE_BUTTONS = 'c70295ba-0721-47e0-9cd1-ea3afc84f701',
  ELEMENTS_PANEL = 'f8bca6c6-9db6-4d54-940b-e1092cda8e02',
}

export const PPOB_ARTICLE_HELP_ID = '1d6b3236-0079-417f-8ce1-7c6b53ad9709';

export enum FedopsInteractions {
  ProductPageBlocksInstallationEditorReady = 'product-page-blocks-installation-editor-ready',
  ProductPageBlocksPageAddition = 'product-page-blocks-page-addition',
  ProductPageBlocksNavigateToCreatedPage = 'product-page-blocks-navigate-to-created-page',
  ProductPageBlocksGetSectionRef = 'product-page-blocks-get-section-ref',
  ProductPageBlocksWidgetAddition = 'product-page-blocks-widget-addition',
  ProductPageBlocksSetStateForStoresPages = 'product-page-blocks-set-state-for-stores-pages',
}
