export enum QuantityWidgetComponentIds {
  Label = '#quantityHeader',
  Input = '#quantityInput',
  Increase = '#quantityIncrease',
  Decrease = '#quantityDecrease',
  ErrorMessage = '#errorMessage1',
}

export enum QuantityWidgetEvents {
  Change = 'change',
}
