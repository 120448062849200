import {AppManifest} from '@wix/platform-editor-sdk';
import {ROOT_WIDGET_ID} from '../../../constants/app';
import {buildTypeDef} from '../utils';
import {methods} from '../../../components/rootWidget/config/exportedMethods';

export const exportMethodsToVelo = (appManifest): AppManifest => {
  appManifest.exports = {
    [ROOT_WIDGET_ID]: {
      typeDef: buildTypeDef(methods),
    },
  };
  return appManifest;
};
