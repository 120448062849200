import {WidgetBuilder} from '../../editor.app.types';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {socialShareDesignBuilderFn} from '../global-design/socialShare.widget.design';
import {helpIds} from '../../../constants/app';

export const setSocialShareWidgetStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder.set({displayName: t.productPage.shareButtonsWidget.displayName()});

  widgetBuilder.behavior().set({removable: false});

  widgetBuilder
    .gfpp()
    .set('mainAction1', {label: t.productPage.shareButtonsWidget.gfpp.design(), actionId: '', actionType: 'design'})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('add', {behavior: 'HIDE'})
    .set('connect', {behavior: 'HIDE'})
    .set('settings', {behavior: 'HIDE'})
    .set('help', {id: helpIds.SHARE_BUTTONS});

  widgetBuilder
    .gfpp('mobile')
    .set('settings', {behavior: 'HIDE'})
    .set('mainAction1', {behavior: 'HIDE'})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('layout', {behavior: 'HIDE'})
    .set('hide', {behavior: 'HIDE'});

  widgetBuilder.configureWidgetDesign((wb) => socialShareDesignBuilderFn(wb, t));
};
