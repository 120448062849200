import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {ConnectedComponentsBuilder, FlowEditorSDK} from '../../editor.app.types';
import {
  setCollapsibleTextSettingsControls,
  setCollapsibleTextSettingsSections,
} from '../../panels/collapsibleTextPanel';
import {helpIds} from '../../../constants/app';

export const setCollapsibleTextComponentStageBehavior = (
  componentBuilder: ConnectedComponentsBuilder,
  t: ILocaleKeys,
  isEllipsisEnabled: boolean,
  editorSDK: FlowEditorSDK,
): void => {
  componentBuilder.set({displayName: t.productPage.descriptionWidget.displayName()});
  setCollapsibleTextSettingsSections(componentBuilder);
  setCollapsibleTextSettingsControls(componentBuilder);

  if (isEllipsisEnabled) {
    componentBuilder
      .gfpp()
      .set('mainAction1', {behavior: 'DEFAULT'})
      .set('mainAction2', {
        label: t.productPage.descriptionWidget.gfpp.design(),
        onClick: ({detail: {componentRef}}) => {
          void editorSDK.editor.openNativeComponentPanel('', 'design', {
            componentRef,
          });
        },
      });
  } else {
    componentBuilder.gfpp().set('mainAction1', {
      label: t.productPage.descriptionWidget.gfpp.design(),
      onClick: ({detail: {componentRef}}) => {
        void editorSDK.editor.openNativeComponentPanel('', 'design', {
          componentRef,
        });
      },
    });
    componentBuilder.gfpp().set('mainAction2', {behavior: 'HIDE'});
  }
  componentBuilder.gfpp().set('connect', {behavior: 'HIDE'}).set('help', {id: helpIds.DESCRIPTION});
};
