import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {ICustomTextField} from './config/types';

export default createBlocksModel({
  widgetName: 'customTextFieldsWidget',
  props: {
    fields: {
      type: undefined,
      defaultValue: [] as ICustomTextField[],
    },
  },
  methods: {
    focus: {},
  },
  events: {
    change: {},
  },
});
