export enum SubscriptionWidgetEvents {
  Change = 'change',
}

export enum SubscriptionWidgetComponentIds {
  TopContainer = '#box1',
  Repeater = '#repeater',
  SelectableRepeater = '#selectableRepeater',
  ErrorSection = '#errorSection',
  ErrorMessage = '#errorMessage1',
  Label = '#priceOptionsTitle',
}

export const SubscriptionSelectableRepeaterItemComponentIds = {
  Tagline: '#planTagline',
  Title: '#planTitle',
  Price: '#planPrice',
  Frequency: '#planDuration',
  SelectableContainer1: '#selectableContainer1',
  SelectableContainerInput1: '#selectableContainerInput1',
} as const;

export const SubscriptionRepeaterContainers = {
  PriceContainer: '#box8',
  ItemContainer: '#box7',
};
