import {WidgetBuilder} from '../../editor.app.types';
import {hideInnerWidgetMobileGfpp} from './helpers/hideInnerWidgetMobileGfpp';
import {priceDesignBuilderFn} from '../global-design/price.widget.design';
import {helpIds} from '../../../constants/app';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';

export const setPricingWidgetStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder.set({displayName: t.productPage.pricingWidget.displayName()});

  widgetBuilder.behavior().set({removable: false});

  widgetBuilder
    .gfpp()
    .set('mainAction1', {label: t.productPage.pricingWidget.gfpp.design(), actionType: 'design', actionId: ''})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('connect', {behavior: 'HIDE'})
    .set('settings', {behavior: 'HIDE'})
    .set('layout', {behavior: 'HIDE'})
    .set('add', {behavior: 'HIDE'})
    .set('help', {id: helpIds.PRICING});

  hideInnerWidgetMobileGfpp(widgetBuilder);

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => priceDesignBuilderFn(widgetDesignBuilder, t));
};
