import {
  CTA_WIDGET_ID,
  CUSTOM_TEXT_FIELDS_WIDGET_ID,
  INFO_SECTION_WIDGET_ID,
  NAVIGATION_WIDGET_ID,
  NEW_PRODUCT_OPTIONS_WIDGET_ID,
  PRICING_WIDGET_ID,
  QUANTITY_WIDGET_ID,
  ROOT_WIDGET_ID,
  SKU_WIDGET_ID,
  STOCK_INDICATOR_WIDGET_ID,
} from '../../../constants/app';
import {AppManifest} from '../../editor.app.types';
import {exportMethodsToVelo} from '../export-to-velo/velo.methods.root.widget';

export const addMobileDesign = (appManifest): AppManifest => {
  appManifest.controllersStageData[ROOT_WIDGET_ID].default.gfpp.mobile.widgetDesign =
    appManifest.controllersStageData[ROOT_WIDGET_ID].default.gfpp.desktop.widgetDesign;
  appManifest.controllersStageData[NAVIGATION_WIDGET_ID].default.gfpp.mobile.widgetDesign =
    appManifest.controllersStageData[NAVIGATION_WIDGET_ID].default.gfpp.desktop.widgetDesign;
  appManifest.controllersStageData[ROOT_WIDGET_ID].default.gfpp.mobile.iconButtons.add = {behavior: 'HIDE'};
  appManifest.controllersStageData[SKU_WIDGET_ID].default.gfpp.mobile.widgetDesign =
    appManifest.controllersStageData[SKU_WIDGET_ID].default.gfpp.desktop.widgetDesign;
  appManifest.controllersStageData[CTA_WIDGET_ID].default.gfpp.mobile.widgetDesign =
    appManifest.controllersStageData[CTA_WIDGET_ID].default.gfpp.desktop.widgetDesign;
  appManifest.controllersStageData[CUSTOM_TEXT_FIELDS_WIDGET_ID].default.gfpp.mobile.widgetDesign =
    appManifest.controllersStageData[CUSTOM_TEXT_FIELDS_WIDGET_ID].default.gfpp.desktop.widgetDesign;
  appManifest.controllersStageData[INFO_SECTION_WIDGET_ID].default.gfpp.mobile.widgetDesign =
    appManifest.controllersStageData[INFO_SECTION_WIDGET_ID].default.gfpp.desktop.widgetDesign;
  appManifest.controllersStageData[NEW_PRODUCT_OPTIONS_WIDGET_ID].default.gfpp.mobile.widgetDesign =
    appManifest.controllersStageData[NEW_PRODUCT_OPTIONS_WIDGET_ID].default.gfpp.desktop.widgetDesign;
  appManifest.controllersStageData[QUANTITY_WIDGET_ID].default.gfpp.mobile.widgetDesign =
    appManifest.controllersStageData[QUANTITY_WIDGET_ID].default.gfpp.desktop.widgetDesign;
  appManifest.controllersStageData[STOCK_INDICATOR_WIDGET_ID].default.gfpp.mobile.widgetDesign =
    appManifest.controllersStageData[STOCK_INDICATOR_WIDGET_ID].default.gfpp.desktop.widgetDesign;
  appManifest.controllersStageData[PRICING_WIDGET_ID].default.gfpp.mobile.widgetDesign =
    appManifest.controllersStageData[PRICING_WIDGET_ID].default.gfpp.desktop.widgetDesign;

  return exportMethodsToVelo(appManifest);
};
