import {createBlocksModel} from '@wix/yoshi-flow-editor';

export default createBlocksModel({
  widgetName: 'socialShareWidget',
  props: {
    facebookLink: {
      type: undefined,
      defaultValue: undefined as string | undefined,
    },
    pinterestLink: {
      type: undefined,
      defaultValue: undefined as string | undefined,
    },
    twitterLink: {
      type: undefined,
      defaultValue: undefined as string | undefined,
    },
    whatsappLink: {
      type: undefined,
      defaultValue: undefined as string | undefined,
    },
  },
  methods: {},
  events: {},
});
