/* eslint-disable @typescript-eslint/no-unused-vars */
import {fetchWidgetsStageData} from '@wix/bob-widget-services';
import {ConnectedComponentsBuilder, FlowEditorSDK, GetAppManifestFn} from './editor.app.types';
import {
  CUSTOM_TEXT_FIELDS_WIDGET_ID,
  ECOM_APP_DEF_ID,
  helpIds,
  NAVIGATION_WIDGET_ID,
  DROPDOWN_OPTIONS_WIDGET_ID,
  QUANTITY_WIDGET_ID,
  ROOT_WIDGET_ID,
  SKU_WIDGET_ID,
  COLOR_PICKER_WIDGET_ID,
  CTA_WIDGET_ID,
  PRICING_WIDGET_ID,
  SOCIAL_SHARE_WIDGET_ID,
  INFO_SECTION_WIDGET_ID,
  STOCK_INDICATOR_WIDGET_ID,
  NEW_PRODUCT_OPTIONS_WIDGET_ID,
  SUBSCRIPTION_WIDGET_ID,
} from '../constants/app';
import {getIsStoresPremium} from '../services/store-settings-service/get-is-stores-premium/getIsStoresPremium';
import {setRootWidgetStageBehavior} from './manifest/gfpp/root.widget.manifest';
import {setTextComponentStageBehavior} from './manifest/gfpp/helpers/setTextComponentStageBehavior';
import {setCtaButtonsWidgetStageBehavior} from './manifest/gfpp/cta.components.manifest';
import {setPricingWidgetStageBehavior} from './manifest/gfpp/price.widget.manifest';
import {setProductOptionsWidgetStageBehavior} from './manifest/gfpp/options.widget.manifest';
import {setInfoSectionWidgetStageBehavior} from './manifest/gfpp/infoSections.widget.manifest';
import {setCustomTextFieldsWidgetStageBehavior} from './manifest/gfpp/customTextFields.widget.manifest';
import {addMobileDesign} from './manifest/global-design/addMobileDesign';
import {initLocaleKeys} from '../services/platform-service/initLocaleKeys';
import {setStockIndicatorWidgetStageBehavior} from './manifest/gfpp/stockIndicator.widget.manifest';
import {RootWidgetComponentIds as ComponentIds} from '../components/rootWidget/config/constants';
import {getRole} from './manifest/global-design/getRole';
import {setQuantityWidgetStageBehavior} from './manifest/gfpp/quantity.widget.manifest';
import {setQuantityInnerComponentsStageBehavior} from './manifest/gfpp/quantity.components.manifest';
import {setCustomTextFieldsInnerWidgetsStageBehavior} from './manifest/gfpp/customTextFields.components.manifest';
import {setDropdownWidgetsStageBehavior} from './manifest/gfpp/dropdown.widget.manifest';
import {setColorPickerWidgetStageBehavior} from './manifest/gfpp/colorPicker.widget.manifest';
import {setCtaButtonsWidgetInnerStageBehavior} from './manifest/gfpp/cta.widget.manifest';
import {setSocialShareWidgetStageBehavior} from './manifest/gfpp/socialShare.widget.manifest';
import {setPricingInnerWidgetsStageBehavior} from './manifest/gfpp/price.components.manifest';
import {setSocialShareButtonsStageBehavior} from './manifest/gfpp/socialShare.components.manifest';
import {setNavigationWidgetStageBehavior} from './manifest/gfpp/navigation.widget.manifest';
import {setNavigationButtonsStageBehavior} from './manifest/gfpp/navigation.components.manifest';
import {setSkuWidgetStageBehavior} from './manifest/gfpp/sku.widget.manifest';
import {setSkuInnerComponentsWidgetStageBehavior} from './manifest/gfpp/sku.components.manifest';
import {setGalleryComponentStageBehavior} from './manifest/gfpp/gallery.manifest';
import {setInfoSectionInnerWidgetsStageBehavior} from './manifest/gfpp/infoSections.components.manifest';
import {setCollapsibleTextComponentStageBehavior} from './manifest/gfpp/collapsibleDescription.manifest';
import {getCompPropsByRole} from './utils';
import {setSubscriptionsWidgetStageBehavior} from './manifest/gfpp/subscription.widget.manifest';
import {setSubscriptionInnerComponentsStageBehavior} from './manifest/gfpp/subscription.components.manifest';
import {setStockIndicatorStageBehavior} from './manifest/gfpp/stockIndicator.components.manifest';
import {removeRootConnections} from './manifest/utils';
import {setBreadcrumbsComponentStageBehavior} from './manifest/gfpp/breadcrumbs.manifest';

const getEcomInstance = (editorSDK: FlowEditorSDK) => {
  return editorSDK.tpa.app.getDataByAppDefId('', ECOM_APP_DEF_ID).then((ecomAppData) => ecomAppData.instance);
};

export const getAppManifest: GetAppManifestFn = async (options, editorSDK, contextParams, flowAPI) => {
  const {appManifestBuilder} = options;
  const t = initLocaleKeys(flowAPI.translations.i18n);

  const baseManifest = await fetchWidgetsStageData(options as any);
  const baseManifestWithoutRootConnections = removeRootConnections(baseManifest);
  appManifestBuilder.withJsonManifest(baseManifestWithoutRootConnections);
  const ecomInstance = await getEcomInstance(editorSDK);
  const isStoresPremium = await getIsStoresPremium(ecomInstance, contextParams.essentials.httpClient);

  appManifestBuilder.configureWidget(NAVIGATION_WIDGET_ID, (widgetBuilder) => {
    setNavigationWidgetStageBehavior(widgetBuilder, t);
    widgetBuilder.configureConnectedComponents(getRole(ComponentIds.Navigation), () => {
      setNavigationButtonsStageBehavior(widgetBuilder, t);
    });
  });
  appManifestBuilder.configureWidget(SKU_WIDGET_ID, (widgetBuilder) => {
    setSkuWidgetStageBehavior(widgetBuilder, t);
    widgetBuilder.configureConnectedComponents(getRole(ComponentIds.Sku), () => {
      setSkuInnerComponentsWidgetStageBehavior(widgetBuilder, t);
    });
  });
  appManifestBuilder.configureWidget(SUBSCRIPTION_WIDGET_ID, (widgetBuilder) => {
    setSubscriptionsWidgetStageBehavior(widgetBuilder, t);
    widgetBuilder.configureConnectedComponents(getRole(ComponentIds.Subscription), () => {
      setSubscriptionInnerComponentsStageBehavior(widgetBuilder, t);
    });
  });
  appManifestBuilder.configureWidget(CTA_WIDGET_ID, (widgetBuilder) => {
    setCtaButtonsWidgetStageBehavior(widgetBuilder, t);
    widgetBuilder.configureConnectedComponents(getRole(ComponentIds.CtaButtons), () => {
      setCtaButtonsWidgetInnerStageBehavior(widgetBuilder, t, editorSDK, flowAPI);
    });
  });
  appManifestBuilder.configureWidget(CUSTOM_TEXT_FIELDS_WIDGET_ID, (widgetBuilder) => {
    setCustomTextFieldsWidgetStageBehavior(widgetBuilder, t);
    widgetBuilder.configureConnectedComponents(getRole(ComponentIds.CustomTextFields), () => {
      setCustomTextFieldsInnerWidgetsStageBehavior(widgetBuilder, t);
    });
  });
  appManifestBuilder.configureWidget(INFO_SECTION_WIDGET_ID, (widgetBuilder) => {
    setInfoSectionWidgetStageBehavior(widgetBuilder, t);
    widgetBuilder.configureConnectedComponents(getRole(ComponentIds.Info), () => {
      setInfoSectionInnerWidgetsStageBehavior(widgetBuilder, t);
    });
  });

  appManifestBuilder.configureWidget(NEW_PRODUCT_OPTIONS_WIDGET_ID, (widgetBuilder) => {
    setProductOptionsWidgetStageBehavior(widgetBuilder, t);
  });

  appManifestBuilder.configureWidget(COLOR_PICKER_WIDGET_ID, (widgetBuilder) => {
    setColorPickerWidgetStageBehavior(widgetBuilder, t);
  });
  appManifestBuilder.configureWidget(DROPDOWN_OPTIONS_WIDGET_ID, (widgetBuilder) => {
    setDropdownWidgetsStageBehavior(widgetBuilder, t);
  });

  appManifestBuilder.configureWidget(QUANTITY_WIDGET_ID, (widgetBuilder) => {
    setQuantityWidgetStageBehavior(widgetBuilder, t);
    widgetBuilder.configureConnectedComponents(getRole(ComponentIds.Quantity), () => {
      setQuantityInnerComponentsStageBehavior(widgetBuilder, t);
    });
  });
  appManifestBuilder.configureWidget(STOCK_INDICATOR_WIDGET_ID, (widgetBuilder) => {
    setStockIndicatorWidgetStageBehavior(widgetBuilder, t);
    widgetBuilder.configureConnectedComponents(getRole(ComponentIds.StockIndicator), () => {
      setStockIndicatorStageBehavior(widgetBuilder, t);
    });
  });
  appManifestBuilder.configureWidget(SOCIAL_SHARE_WIDGET_ID, (widgetBuilder) => {
    setSocialShareWidgetStageBehavior(widgetBuilder, t);
    widgetBuilder.configureConnectedComponents(getRole(ComponentIds.SocialShare), () => {
      setSocialShareButtonsStageBehavior(widgetBuilder, t);
    });
  });
  appManifestBuilder.configureWidget(PRICING_WIDGET_ID, (widgetBuilder) => {
    setPricingWidgetStageBehavior(widgetBuilder, t);
    widgetBuilder.configureConnectedComponents(getRole(ComponentIds.Price), () => {
      setPricingInnerWidgetsStageBehavior(widgetBuilder, t);
    });
  });

  const isDescriptionEllipsisEnabled = (
    await getCompPropsByRole(editorSDK, getRole(ComponentIds.CollapsibleDescription))
  )?.ellipsis;

  appManifestBuilder.configureWidget(ROOT_WIDGET_ID, (widgetBuilder) => {
    void setRootWidgetStageBehavior(widgetBuilder, isStoresPremium, t);

    widgetBuilder
      .configureConnectedComponents(
        getRole(ComponentIds.ProductTitle),
        (componentBuilder: ConnectedComponentsBuilder) => {
          setTextComponentStageBehavior(
            componentBuilder,
            editorSDK,
            t.productPage.productNameWidget.displayName(),
            t.productPage.productNameWidget.gfpp.design(),
            helpIds.PRODUCT_NAME,
          );
        },
      )
      .configureConnectedComponents(getRole(ComponentIds.CollapsibleDescription), (componentBuilder) => {
        setCollapsibleTextComponentStageBehavior(componentBuilder, t, isDescriptionEllipsisEnabled, editorSDK);
      })
      .configureConnectedComponents(getRole(ComponentIds.Gallery), (componentBuilder) => {
        setGalleryComponentStageBehavior(componentBuilder, t, editorSDK);
      })
      .configureConnectedComponents(getRole(ComponentIds.Breadcrumbs), (componentBuilder) => {
        setBreadcrumbsComponentStageBehavior(componentBuilder, t);
      });
  });

  return addMobileDesign(appManifestBuilder.build());
};
