import {EventType, FlowAPI, FlowEditorSDK} from '../../editor.app.types';
import {handleWidgetReset} from './widgetResetOverride/handleWidgetReset';

export const registerResetWidgetOverrideEvent = (editorSDK: FlowEditorSDK, flowAPI: FlowAPI): Promise<void> =>
  editorSDK.addEventListener(EventType.resetWidgetOverrides, async (event) => {
    const componentRef = await event.detail.componentRef;
    const isRootComponent = !componentRef?.scope;
    if (isRootComponent) {
      await handleWidgetReset(editorSDK, flowAPI, componentRef);
    }
  });
