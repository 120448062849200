import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {SubscriptionWidgetEvents} from './config/constants';
import {SubscriptionPlansView} from '@wix/wixstores-client-storefront-sdk/dist/src/services/ProductSubscriptionService/type';

export default createBlocksModel({
  widgetName: 'subscriptionWidget',
  props: {
    options: {
      type: undefined,
      defaultValue: [] as SubscriptionPlansView[],
    },
    isError: {
      type: 'boolean',
      defaultValue: false,
    },
    errorMessage: {
      type: 'text',
      defaultValue: '',
    },
    shouldShowTitle: {
      type: 'boolean',
      defaultValue: true,
    },
    titleText: {
      type: 'text',
      defaultValue: 'Price Options',
    },
    shouldShowOneTimePurchaseOptionFirst: {
      type: 'text',
      defaultValue: 'first',
    },
  },
  methods: {
    focus: {},
  },
  events: {
    [SubscriptionWidgetEvents.Change]: {
      description: '',
    },
  },
});
