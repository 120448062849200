import {hideInnerWidgetMobileGfpp} from './helpers/hideInnerWidgetMobileGfpp';
import {helpIds} from '../../../constants/app';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {navigationDesignBuilderFn} from '../global-design/navigation.widget.design';
import {WidgetBuilder} from '../../editor.app.types';

export const setNavigationWidgetStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder.set({displayName: t.productPage.prevNextWidget.displayName()});

  widgetBuilder
    .gfpp()
    .set('mainAction1', {label: t.productPage.prevNextWidget.gfpp.design(), actionType: 'design', actionId: ''})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('connect', {behavior: 'HIDE'})
    .set('layout', {behavior: 'HIDE'})
    .set('add', {behavior: 'HIDE'})
    .set('help', {id: helpIds.NAVIGATION});

  hideInnerWidgetMobileGfpp(widgetBuilder);

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => navigationDesignBuilderFn(widgetDesignBuilder, t));
};
