import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {OptionsWidgetEvents as Events} from './config/constants';
import {IProductExtendedOption} from '../../types/type';

const DEFAULT_SETTINGS = {
  shouldShowTitle: true,
};

export default createBlocksModel({
  widgetName: 'optionsNewWidget',
  props: {
    settings: {
      type: undefined,
      defaultValue: DEFAULT_SETTINGS,
    },
    productOptions: {
      type: undefined,
      defaultValue: [] as IProductExtendedOption[],
    },
    selectionIds: {
      type: undefined,
      defaultValue: [] as number[],
    },
  },
  methods: {
    focus: {},
  },
  events: {
    [Events.Change]: {},
  },
});
