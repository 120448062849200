import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {WidgetBuilder} from '../../editor.app.types';
import {ActionIds} from '../../../constants/gfpp';
import {subscriptionWidgetDesignBuilder} from '../global-design/subscription.widget.design';
import {helpIds} from '../../../constants/app';
import {hideInnerWidgetMobileGfpp} from './helpers/hideInnerWidgetMobileGfpp';

export const setSubscriptionsWidgetStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder.set({displayName: t.productPage.subscriptionsWidget.displayName()});

  widgetBuilder
    .gfpp()
    .set('settings', {actionId: ActionIds.SUBSCRIPTION_SETTINGS})
    .set('mainAction1', {label: t.productPage.subscriptionsWidget.gfpp.design(), actionType: 'design', actionId: ''})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('layout', {actionId: ActionIds.SUBSCRIPTION_LAYOUT})
    .set('add', {behavior: 'HIDE'})
    .set('connect', {behavior: 'HIDE'})
    .set('help', {id: helpIds.SUBSCRIPTIONS});

  hideInnerWidgetMobileGfpp(widgetBuilder);

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => subscriptionWidgetDesignBuilder(widgetDesignBuilder, t));
};
