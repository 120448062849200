import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {IProductData} from '../../types/wixDataTypes';
import {methods} from './config/exportedMethods';

const DEFAULT_SETTINGS = {
  desktop: {
    alignment: 'left',
  },
  mobile: {
    alignment: 'left',
  },
};

export default createBlocksModel({
  widgetName: 'rootWidget',
  props: {
    productId: {
      type: 'text',
      defaultValue: undefined,
    },
    slug: {
      type: 'text',
      defaultValue: undefined,
    },
    productObject: {
      type: undefined,
      defaultValue: undefined as IProductData | undefined,
    },
    layoutSettings: {
      type: undefined,
      defaultValue: DEFAULT_SETTINGS,
    },
  },
  methods,
  events: {},
});
