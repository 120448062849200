export enum StockIndicatorWidgetComponentIds {
  TopContainer = '#box1',
  MultiStateBox = '#multiStateBox1',
  StockIndicatorContainer = '#left',
  StockIndicatorText = '#text1',
  OutOfStockContainer = '#oos',
  OutOfStockText = '#text2',
}

export enum StockIndicatorStates {
  OutOfStock = 'oos',
  StockIndicator = 'left',
}
