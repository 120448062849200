/* eslint-disable @typescript-eslint/no-misused-promises*/
import {openShowHidePanel} from '../panels/elements-panel/openShowHidePanel';
import {PanelsApiFactory} from '@wix/blocks-widget-services/panels';
import {ComponentRef, FlowAPI, FlowEditorSDK, EditorType, EventType} from '../editor.app.types';
import blockWidgetDescriptor from '../../../blocks-widget-descriptor.json';
import rootWidget from '../../components/rootWidget/.component.json';
import {ActionIds, PanelModularizationCustomActionIds} from '../../constants/gfpp';
import {
  CtaPanelNames,
  InfoSectionsPanelNames,
  QuantityPanelNames,
  SkuPanelNames,
  OptionsPanelNames,
  RootPanelNames,
  StockIndicatorPanelNames,
  CustomTextFieldsPanelNames,
  SubscriptionPanelNames,
} from '../../constants/panels';
import {openProductsDashboardPanel} from '../panels/dashboardPanels';
import {openProGalleryLayoutPanel} from '../panels/proGalleryPanels';
import {getCompRefByRole} from '../utils';
import {RootWidgetComponentIds as ComponentIds} from '../../components/rootWidget/config/constants';
import {getRole} from '../manifest/global-design/getRole';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {initLocaleKeys} from '../../services/platform-service/initLocaleKeys';
import type {AppEssentials} from '@wix/fe-essentials-editor';

export const registerGfppEvents = async (
  editorSDK: FlowEditorSDK,
  editorType: EditorType,
  ecomPublicApi: any,
  flowAPI: FlowAPI,
  essentials: AppEssentials,
): Promise<void> => {
  const panelsApi = await new PanelsApiFactory().createPanelsApi({
    editorSDK,
    editorType,
    essentials,
  });
  const t = initLocaleKeys(flowAPI.translations.i18n);

  // TODO - should be removed once editor can take appRevision from url
  const openBlocksPanelWrapper = (panelName: string, componentRef: ComponentRef, title?: string) =>
    panelsApi.openBlocksPanel(panelName, componentRef, {
      overrides: {
        revision: blockWidgetDescriptor[rootWidget.id].revision.toString(),
        title,
      },
    });

  const registerWidgetGfppEvents = editorSDK.addEventListener(EventType.widgetGfppClicked, (event) => {
    const {id, componentRef} = event.detail;
    switch (id) {
      case ActionIds.ROOT_MANAGE_PRODUCTS:
        void openProductsDashboardPanel(editorSDK);
        break;
      case ActionIds.ROOT_LAYOUT_DESKTOP:
        void openBlocksPanelWrapper(
          RootPanelNames.DESKTOP_LAYOUT,
          componentRef,
          t.productPage.productPageWidget.layout.panelHeader(),
        );
        break;
      case ActionIds.ROOT_LAYOUT_MOBILE:
        void openBlocksPanelWrapper(
          RootPanelNames.MOBILE_LAYOUT,
          componentRef,
          t.productPage.productPageWidget.layout.panelHeader(),
        );
        break;
      case ActionIds.ROOT_ELEMENTS:
        void openShowHidePanel(editorSDK, componentRef, flowAPI);
        break;
      case ActionIds.ROOT_UPGRADE:
        ecomPublicApi.openEcomBillingPage();
        break;
      case ActionIds.CTA_LAYOUT:
        void openBlocksPanelWrapper(
          CtaPanelNames.LAYOUT,
          componentRef,
          t.productPage.actionButtonsWidget.layout.panelHeader(),
        );
        break;
      case ActionIds.CTA_CHANGE_STATE:
        void openBlocksPanelWrapper(
          CtaPanelNames.CHANGE_STATE,
          componentRef,
          t.productPage.actionButtonsWidget.changeView.panelHeader(),
        );
        break;
      case ActionIds.INFO_SECTIONS_LAYOUT_DESKTOP:
        void openBlocksPanelWrapper(
          InfoSectionsPanelNames.LAYOUT_DESKTOP,
          componentRef,
          t.productPage.infoSectionWidget.layout.panelHeader(),
        );
        break;
      case ActionIds.INFO_SECTIONS_LAYOUT_MOBILE:
        void openBlocksPanelWrapper(
          InfoSectionsPanelNames.LAYOUT_MOBILE,
          componentRef,
          t.productPage.infoSectionWidget.layout.panelHeader(),
        );
        break;
      case ActionIds.QUANTITY_SETTINGS:
        void openBlocksPanelWrapper(
          QuantityPanelNames.SETTINGS,
          componentRef,
          t.productPage.quantityWidget.settings.panelHeader(),
        );
        break;
      case ActionIds.SKU_SETTINGS:
        void openBlocksPanelWrapper(
          SkuPanelNames.SETTINGS,
          componentRef,
          t.productPage.skuWidget.settings.panelHeader(),
        );
        break;
      case ActionIds.OPTIONS_SETTINGS:
        void openBlocksPanelWrapper(
          OptionsPanelNames.SETTINGS_BASE_UI,
          componentRef,
          t.productPage.productOptionsWidget.settings.panelHeader(),
        );
        break;
      case ActionIds.STOCK_INDICATOR_SETTINGS:
        void openBlocksPanelWrapper(
          StockIndicatorPanelNames.SETTINGS,
          componentRef,
          t.productPage.stockWidget.settings.panelHeader(),
        );
        break;
      case ActionIds.CUSTOM_TEXT_FIELD_SETTINGS:
        void openBlocksPanelWrapper(
          CustomTextFieldsPanelNames.SETTINGS,
          componentRef,
          t.productPage.customTextWidget.settings.panelHeader(),
        );
        break;
      case ActionIds.SUBSCRIPTION_SETTINGS:
        void openBlocksPanelWrapper(
          SubscriptionPanelNames.SETTINGS,
          componentRef,
          t.productPage.subscriptionsWidget.settings.panelHeader(),
        );
        break;
      case ActionIds.SUBSCRIPTION_LAYOUT:
        void openBlocksPanelWrapper(
          SubscriptionPanelNames.LAYOUT,
          componentRef,
          t.productPage.subscriptionsWidget.layout.panelHeader(),
        );
        break;
      case 'textComponentDesign':
        void editorSDK.editor.openNativeComponentPanel('textComponentDesign', 'design', {
          componentRef,
          panelSectionsDefinition: {
            placeholder: 'hidden',
            required: 'hidden',
          },
        });
        break;
    }
  });

  const registerInnerWidgetGfppEvents = editorSDK.addEventListener(EventType.componentGfppClicked, (event) => {
    const {id, componentRef} = event.detail;
    if (id === ActionIds.ADD_TO_CART_SETTINGS) {
      void openBlocksPanelWrapper(
        CtaPanelNames.SETTINGS,
        componentRef,
        t.productPage.addToCartButton.settings.panelHeader(),
      );
    } else if (id === ActionIds.CTA_PRE_ORDER_SETTINGS) {
      void openBlocksPanelWrapper(CtaPanelNames.PRE_ORDER_SETTINGS, componentRef);
    } else if (id === PanelModularizationCustomActionIds.GALLERY_LAYOUT) {
      openProGalleryLayoutPanel(editorSDK, componentRef);
    }
  });

  const registerComponentPropsChanged = editorSDK.addEventListener(EventType.componentPropsChanged, async (event) => {
    const descriptionCompRefId = (await getCompRefByRole(editorSDK, getRole(ComponentIds.CollapsibleDescription)))?.id;
    const {compRef} = event.detail;
    if (compRef.id === descriptionCompRefId) {
      void editorSDK.document.application.reloadManifest();
    }
  });

  const registerFocusedPageChangedEvent = editorSDK.addEventListener(EventType.focusedPageChanged, async (event) => {
    const pageData = await editorSDK.document.pages.data.get('', {pageRef: event.detail.pageRef});
    if (pageData?.tpaPageId === PageMap.PRODUCT) {
      void editorSDK.document.application.reloadManifest();
    }
  });

  await Promise.all([
    registerWidgetGfppEvents,
    registerInnerWidgetGfppEvents,
    registerComponentPropsChanged,
    registerFocusedPageChangedEvent,
  ]);
};
