import {ConnectedComponentsBuilder} from '../editor.app.types';

export const setContainerBoxDesignPanelWithHiddenBackgroundColor = (innerWidgetBuilder: ConnectedComponentsBuilder) => {
  innerWidgetBuilder.panel<'ContainerBox'>('design').configureControls({
    bg: {
      hidden: true,
    },
  } as any); // https://jira.wixpress.com/browse/ECL-5987
};

export const setContainerBoxDesignPanelWithHiddenBorderColor = (innerWidgetBuilder: ConnectedComponentsBuilder) => {
  innerWidgetBuilder.panel<'ContainerBox'>('design').configureControls({
    brd: {
      hidden: true,
    },
  } as any); // https://jira.wixpress.com/browse/ECL-5987
};
