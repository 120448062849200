import {getRole} from './getRole';
import {StockIndicatorWidgetComponentIds as ComponentIds} from '../../../components/stockIndicatorWidget/config/constants';
import {helpIds} from '../../../constants/app';
import {WidgetDesignBuilderFn} from '../../editor.app.types';

export const stockIndicatorDesignBuilderFn: WidgetDesignBuilderFn = (widgetDesignBuilder, t): void => {
  widgetDesignBuilder.set({
    title: t.productPage.stockWidget.design.panelHeader(),
    customHelpId: helpIds.STOCK_INDICATOR,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.stockWidget.design.outOfStock.sectionName(),
          tooltip: t.productPage.stockWidget.design.outOfStock.tooltip.text(),
        })
        .groups()
        .set({
          roles: [ComponentIds.OutOfStockText].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.stockWidget.design.lowStock.sectionName(),
          tooltip: t.productPage.stockWidget.design.lowStock.tooltip.text(),
        })
        .groups()
        .set({
          roles: [ComponentIds.StockIndicatorText].map(getRole),
        });
    });
};
