import {getCategoriesData} from './getCategoriesData';
import {getElementsData} from './getElementsData';
import {FlowAPI, FlowEditorSDK, ComponentRef} from '../../editor.app.types';
import {initLocaleKeys} from '../../../services/platform-service/initLocaleKeys';
import {RootWidgetComponentIds} from '../../../components/rootWidget/config/constants';
import {getRole} from '../../manifest/global-design/getRole';
import {SPECS} from '../../../specs';
import {PriceWidgetComponentIds} from '../../../components/priceWidget/config/constants';
import {isSpecialLanguage} from '../../../services/platform-service/getIsSpecialLanguage';
import {helpIds} from '../../../constants/app';

export const openShowHidePanel = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  flowAPI: FlowAPI,
): Promise<void> => {
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const isMS3 = flowAPI.experiments.enabled(SPECS.PRODUCT_PAGE_BLOCKS_MS3);
  const shouldUseStoresCatalogV3PPOB = flowAPI.experiments.enabled(SPECS.UseStoresCatalogV3PPOB);

  // TODO - TEST Special language case
  const priceLangType = isSpecialLanguage(flowAPI.environment.siteLanguage) ? 'SpecialLang' : 'DefaultLang';

  const getCollapsedRefComponentByRole = async (role) => {
    const [widgetRefHost] = await editorSDK.components.getAncestors('token', {componentRef: widgetRef});
    const collapsedRefComponents = await editorSDK.components.refComponents.getCollapsedRefComponents('token', {
      componentRef: widgetRefHost,
      // @ts-expect-error temp until types are GAed
      includeInnerCollapsed: true,
    });
    const collapsedRefComponent = collapsedRefComponents.filter((comp) => comp.role === role);
    return collapsedRefComponent[0].componentRef;
  };

  const getElementsWithWarningCompRefs = async () => {
    const roles = [
      getRole(RootWidgetComponentIds.OptionsNew),
      getRole(RootWidgetComponentIds.CustomTextFields),
      getRole(RootWidgetComponentIds.ProductTitle),
      getRole(RootWidgetComponentIds.Subscription),
      `${getRole(RootWidgetComponentIds.Price)}.${getRole(PriceWidgetComponentIds[`ActualPrice${priceLangType}`])}`,
    ];

    const findAllByRole = async (controllerRef, role) => {
      return editorSDK.document.components.findAllByRole('', {
        controllerRef,
        role,
      });
    };

    const compRefs = await Promise.all(
      roles.map((role) => {
        const [parentRole, childRole] = role.split('.');
        if (childRole) {
          return findAllByRole(widgetRef, parentRole).then((parentRefs) => findAllByRole(parentRefs[0], childRole));
        }
        return findAllByRole(widgetRef, role);
      }),
    );
    const fullCompRefs = await Promise.all(compRefs.map((compRef) => getWidgetCompRef(compRef[0])));
    return fullCompRefs.map((compRef, index) => ({role: roles[index], compRefId: compRef.id}));
  };

  const getWidgetCompRef = async (componentRef) => {
    const type = await editorSDK.components.getType('token', {componentRef});
    return type.includes('AppWidget')
      ? (await editorSDK.components.getAncestors('t', {componentRef}))[0]
      : /* istanbul ignore next reason: we don't hide whole widget */ componentRef;
  };

  const showComp = async (componentRef) => {
    await editorSDK.components.refComponents.expandReferredComponent('token', {
      componentRef,
    });
  };

  const hideComp = async (componentRef) => {
    await editorSDK.components.refComponents.collapseReferredComponent('token', {
      componentRef,
    });
  };

  const addCompHandler = async ({role}) => {
    const componentRef = await getCollapsedRefComponentByRole(role);
    return showComp(componentRef);
  };

  const removeCompHandler = async (compRef) => {
    const compToHide = await getWidgetCompRef(compRef);
    const elementsWithWarnings = await getElementsWithWarningCompRefs();
    if (elementsWithWarnings.map((element) => element.compRefId).includes(compToHide.id)) {
      const removedElementRole = elementsWithWarnings.find((element) => element.compRefId === compToHide.id)!.role;
      void showWarningNotification(removedElementRole, compRef);
    }
    return hideComp(compToHide);
  };

  const showWarningNotification = async (role: string, compRef) => {
    const restoreElementCb = async () => {
      const compToHide = await getWidgetCompRef(compRef);
      await editorSDK.editor.closePanel('', '');
      return showComp(compToHide);
    };

    const navigateToSeoBMPageCb = async () => {
      return editorSDK.editor.openDashboardPanel('token', {
        url: `seo-home/seo-settings/stores-product`,
        closeOtherPanels: false,
      });
    };

    const optionsData = {
      warningText: t.productPage.productPageWidget.elements.productOptions.notification.text(),
      linkText: t.productPage.productPageWidget.elements.productOptions.notification.link(),
      clickedCallback: restoreElementCb,
    };

    const notificationData = {
      [getRole(RootWidgetComponentIds.OptionsNew)]: optionsData,
      [getRole(RootWidgetComponentIds.CustomTextFields)]: {
        warningText: t.productPage.productPageWidget.elements.customText.notification.text(),
        linkText: t.productPage.productPageWidget.elements.customText.notification.link(),
        clickedCallback: restoreElementCb,
      },
      [getRole(RootWidgetComponentIds.ProductTitle)]: {
        warningText: t.productPage.productPageWidget.elements.productName.notification.text(),
        linkText: t.productPage.productPageWidget.elements.productName.notification.link(),
        clickedCallback: navigateToSeoBMPageCb,
      },
      [getRole(RootWidgetComponentIds.Subscription)]: {
        warningText: t.productPage.productPageWidget.elements.subscriptions.notification.text(),
        linkText: t.productPage.productPageWidget.elements.subscriptions.notification.link(),
        clickedCallback: restoreElementCb,
      },
      [`${getRole(RootWidgetComponentIds.Price)}.${getRole(PriceWidgetComponentIds[`ActualPrice${priceLangType}`])}`]: {
        warningText: t.productPage.productPageWidget.elements.pricing.notification.text(),
        linkText: t.productPage.productPageWidget.elements.pricing.notification.link(),
        clickedCallback: navigateToSeoBMPageCb,
      },
    };

    const {warningText, linkText, clickedCallback} = notificationData[role];

    const linkWasClicked = await editorSDK.editor.showUserActionNotification('', {
      link: {caption: linkText, onClick: clickedCallback},
      message: warningText,
      type: editorSDK.editor.NotificationType.Warning,
    });

    if (linkWasClicked) {
      await clickedCallback();
    }
  };

  return editorSDK.editor.openElementsPanel('t', {
    widgetRef,
    categoriesData: getCategoriesData(t),
    elementsData: getElementsData(t, isMS3, shouldUseStoresCatalogV3PPOB, priceLangType),
    addComponentHandler: addCompHandler,
    removeComponentHandler: removeCompHandler,
    helpId: helpIds.ELEMENTS_PANEL,
    subtitle: t.productPage.productPageWidget.elements.subtitle.text(),
  });
};
