import {WidgetDesignBuilderFn} from '../../editor.app.types';
import {
  SubscriptionSelectableRepeaterItemComponentIds,
  SubscriptionWidgetComponentIds,
} from '../../../components/subscriptionWidget/config/constants';
import {getRole} from './getRole';
import {helpIds} from '../../../constants/app';

export const subscriptionWidgetDesignBuilder: WidgetDesignBuilderFn = (widgetDesignBuilder, t): void => {
  widgetDesignBuilder.set({
    title: t.productPage.subscriptionsWidget.design.panelHeader(),
    customHelpId: helpIds.SUBSCRIPTIONS,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.subscriptionsWidget.design.title.sectionName(),
        })
        .groups()
        .set({
          roles: [SubscriptionWidgetComponentIds.Label].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.subscriptionsWidget.design.radioButton.sectionName(),
        })
        .groups()
        .set({
          roles: [SubscriptionSelectableRepeaterItemComponentIds.SelectableContainerInput1].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.subscriptionsWidget.design.optionName.sectionName(),
        })
        .groups()
        .set({
          roles: [SubscriptionSelectableRepeaterItemComponentIds.Title].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.subscriptionsWidget.design.tagline.sectionName(),
        })
        .groups()
        .set({
          roles: [SubscriptionSelectableRepeaterItemComponentIds.Tagline].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.subscriptionsWidget.design.price.sectionName(),
        })
        .groups()
        .set({
          roles: [SubscriptionSelectableRepeaterItemComponentIds.Price].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.subscriptionsWidget.design.frequencyDuration.sectionName(),
        })
        .groups()
        .set({
          roles: [SubscriptionSelectableRepeaterItemComponentIds.Frequency].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.subscriptionsWidget.design.container.sectionName(),
        })
        .groups()
        .set({
          roles: [SubscriptionSelectableRepeaterItemComponentIds.SelectableContainer1].map(getRole),
        });
    });
};
