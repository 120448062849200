import {NavigationWidgetComponentIds as ComponentIds} from '../../../components/navigationWidget/config/constants';
import {getRole} from './getRole';
import {helpIds} from '../../../constants/app';
import {WidgetDesignBuilderFn} from '../../editor.app.types';

export const navigationDesignBuilderFn: WidgetDesignBuilderFn = (widgetDesignBuilder, t): void => {
  widgetDesignBuilder.set({
    title: t.productPage.prevNextWidget.design.panelHeader(),
    customHelpId: helpIds.NAVIGATION,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.prevNextWidget.design.buttons.sectionName(),
        })
        .groups()
        .set({
          roles: [ComponentIds.prevIcon, ComponentIds.nextIcon].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.prevNextWidget.design.divider.sectionName(),
        })
        .groups()
        .set({
          roles: [ComponentIds.divider].map(getRole),
        });
    });
};
