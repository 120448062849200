import {WidgetBuilder, ConnectedComponentsBuilder} from '../../editor.app.types';
import {getRole} from '../global-design/getRole';
import {InfoSectionItemComponentIds as ComponentIds} from '../../../components/infoSectionsWidget/config/constants';
import {setRichTextSettingsSections, setRichTextSettingsControls} from '../../panels/richTextPanels';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';

export const setInfoSectionInnerWidgetsStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys) => {
  const innerBuilder = (innerWidgetBuilder: ConnectedComponentsBuilder) => {
    setRichTextSettingsControls(innerWidgetBuilder);
    setRichTextSettingsSections(innerWidgetBuilder);
    innerWidgetBuilder.behavior().set({
      closed: {
        selectable: false,
        hideFromHierarchy: true,
      },
    });
  };

  widgetBuilder
    .configureConnectedComponents(getRole(ComponentIds.Title), (innerWidgetBuilder) => {
      innerBuilder(innerWidgetBuilder);
    })
    .configureConnectedComponents(getRole(ComponentIds.Paragraph), (innerWidgetBuilder) => {
      innerBuilder(innerWidgetBuilder);
    })
    .configureConnectedComponents(getRole(ComponentIds.PlusIcon), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({
        displayName: t.productPage.infoSectionWidget.expandButton.displayName(),
      });
      innerWidgetBuilder.gfpp().set('link', {behavior: 'HIDE'});
    })
    .configureConnectedComponents(getRole(ComponentIds.MinusIcon), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({
        displayName: t.productPage.infoSectionWidget.collapseButton.displayName(),
      });
      innerWidgetBuilder.gfpp().set('link', {behavior: 'HIDE'});
    });
};
