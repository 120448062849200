import {WidgetBuilder} from '../../editor.app.types';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {getRole} from '../global-design/getRole';
import {ColorWidgetComponentIds, ColorItemComponentIds} from '../../../components/colorPickerWidget/config/constants';
import {
  setContainerBoxDesignPanelWithHiddenBackgroundColor,
  setContainerBoxDesignPanelWithHiddenBorderColor,
} from '../../panels/contianerBoxPanels';
import {setComponentsAsUnselectable} from './helpers/setComponentsAsUnselectable';

export const setColorPickerWidgetStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder
    .configureConnectedComponents(getRole(ColorWidgetComponentIds.Label), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.productOptionsWidget.title.displayName()});
    })
    .configureConnectedComponents(getRole(ColorWidgetComponentIds.Repeater), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.productOptionsWidget.colorSwatch.displayName()});
    })
    .configureConnectedComponents(getRole(ColorWidgetComponentIds.ErrorMessage), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.productOptionsWidget.colorSwatch.displayName()});
    })
    .configureConnectedComponents(getRole(ColorItemComponentIds.ColorFill), (innerWidgetBuilder) => {
      setContainerBoxDesignPanelWithHiddenBackgroundColor(innerWidgetBuilder);
      setContainerBoxDesignPanelWithHiddenBorderColor(innerWidgetBuilder);
    });

  setComponentsAsUnselectable(ColorWidgetComponentIds, widgetBuilder);
  setComponentsAsUnselectable(ColorItemComponentIds, widgetBuilder);
};
