import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {InfoSectionLayouts} from './config/types';
import {InfoSectionAccordionDefaultState as AccordionDefaultState} from './config/presets';
import {IAdditionalInfo} from '../../types/type';

const DEFAULT_SETTINGS = {
  layout: InfoSectionLayouts.CollapseLeft,
  layouts: {
    desktop: InfoSectionLayouts.CollapseLeft,
    mobile: InfoSectionLayouts.CollapseLeft,
  },
  accordionDefaultState: {
    desktop: AccordionDefaultState.FirstExpanded,
    mobile: AccordionDefaultState.FirstExpanded,
  },
};

export default createBlocksModel({
  widgetName: 'infoSectionsWidget',
  props: {
    sections: {
      type: undefined,
      defaultValue: [] as IAdditionalInfo[],
    },
    settings: {
      type: undefined,
      defaultValue: DEFAULT_SETTINGS,
    },
  },
  methods: {},
  events: {},
});
