import {WidgetBuilder} from '../../editor.app.types';
import {optionsDesignBuilderFn} from '../global-design/options.widget.design';
import {helpIds} from '../../../constants/app';
import {ActionIds} from '../../../constants/gfpp';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';

export const setProductOptionsWidgetStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder.set({displayName: t.productPage.productOptionsWidget.displayName()});

  widgetBuilder
    .gfpp()
    .set('settings', {actionId: ActionIds.OPTIONS_SETTINGS})
    .set('mainAction1', {label: t.productPage.productOptionsWidget.gfpp.design(), actionType: 'design', actionId: ''})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('connect', {behavior: 'HIDE'})
    .set('layout', {behavior: 'HIDE'})
    .set('add', {behavior: 'HIDE'})
    .set('help', {id: helpIds.PRODUCT_OPTIONS});

  widgetBuilder
    .gfpp('mobile')
    .set('settings', {actionId: ActionIds.OPTIONS_SETTINGS})
    .set('mainAction1', {behavior: 'HIDE'})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('layout', {behavior: 'HIDE'})
    .set('hide', {behavior: 'HIDE'});

  widgetBuilder.configureWidgetDesign((wb) => optionsDesignBuilderFn(wb, t));
};
