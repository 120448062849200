import {handleFirstInstall} from './first-install/handleFirstInstall';
import {ECOM_APP_DEF_ID, FedopsInteractions} from '../constants/app';
import {registerGfppEvents} from './events/gfppEvents';
import {registerDashboardClosedEvent} from './events/dashboardEvents';
import {EditorReadyFn} from './editor.app.types';
import {registerResetWidgetOverrideEvent} from './events/widgetEvents/widgetEvents';
import {registerDeviceTypeChangedEvents} from './events/deviceTypeEvents';

export const editorReady: EditorReadyFn = async (editorSDK, appDefId, options, flowAPI): Promise<void> => {
  const ecomPublicAPI = await editorSDK.application.getPublicAPI('', {appDefinitionId: ECOM_APP_DEF_ID});
  if (options.firstInstall) {
    flowAPI.fedops.interactionStarted(FedopsInteractions.ProductPageBlocksInstallationEditorReady);
    await handleFirstInstall(editorSDK, appDefId, options, ecomPublicAPI, flowAPI);
    flowAPI.fedops.interactionEnded(FedopsInteractions.ProductPageBlocksInstallationEditorReady);
  }

  await Promise.all([
    registerDashboardClosedEvent(editorSDK),
    registerResetWidgetOverrideEvent(editorSDK, flowAPI),
    registerDeviceTypeChangedEvents(editorSDK, flowAPI),
    registerGfppEvents(editorSDK, options.origin.type, ecomPublicAPI, flowAPI, options.essentials),
  ]);
};
