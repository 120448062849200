import {createBlocksModel} from '@wix/yoshi-flow-editor';

const DEFAULT_DATA = {
  prevLink: undefined as string | undefined,
  nextLink: undefined as string | undefined,
};

export default createBlocksModel({
  widgetName: 'navigationWidget',
  props: {
    data: {
      type: undefined,
      defaultValue: DEFAULT_DATA,
    },
  },
  methods: {},
  events: {},
});
