import {WidgetBuilder} from '../../editor.app.types';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {getRole} from '../global-design/getRole';
import {DropdownComponentIds} from '../../../components/dropdownWidget/config/constants';
import {setDropdownDesignPanelsWithHiddenErrorState} from '../../panels/dropdownPanels';
import {setComponentsAsUnselectable} from './helpers/setComponentsAsUnselectable';

export const setDropdownWidgetsStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder
    .configureConnectedComponents(getRole(DropdownComponentIds.Label), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.productOptionsWidget.title.displayName()});
    })
    .configureConnectedComponents(getRole(DropdownComponentIds.Dropdown), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.productOptionsWidget.dropdownList.displayName()});
      setDropdownDesignPanelsWithHiddenErrorState(innerWidgetBuilder);
    });
  setComponentsAsUnselectable(DropdownComponentIds, widgetBuilder);
};
