export enum DropdownComponentIds {
  Dropdown = '#dropdown',
  Label = '#label',
  TopContainer = '#topContainer',
  ErrorMessage = '#errorMessage1',
  ColorState = '#colorState',
  DropdownState = '#dropdownState',
}

export enum DropdownWidgetEvents {
  Change = 'change',
}
