import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {StockIndicatorStates} from './config/constants';

const DEFAULT_DATA = {
  amountInStock: 1,
};

const DEFAULT_SETTINGS = {
  threshold: 10,
  activeState: null as StockIndicatorStates | null,
  outOfStockText: null as string | null,
  showStockIndicator: true,
};

export default createBlocksModel({
  widgetName: 'stockIndicatorWidget',
  props: {
    data: {
      type: undefined,
      defaultValue: DEFAULT_DATA,
    },
    settings: {
      type: undefined,
      defaultValue: DEFAULT_SETTINGS,
    },
  },
  methods: {},
  events: {},
});
