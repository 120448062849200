export const enum CtaPanelNames {
  SETTINGS = 'CTA - Settings',
  PRE_ORDER_SETTINGS = 'Pre order settings',
  LAYOUT = 'CTA - Layout Panel',
  CHANGE_STATE = 'Change view',
}

export const enum InfoSectionsPanelNames {
  LAYOUT_DESKTOP = 'Info Section Layout',
  LAYOUT_MOBILE = 'Info Section Layout - Mobile',
}

export const enum QuantityPanelNames {
  SETTINGS = 'Quantity Settings',
}

export const enum SkuPanelNames {
  SETTINGS = 'SKU Settings',
}

export const enum OptionsPanelNames {
  SETTINGS = 'Product Options Settings',
  SETTINGS_BASE_UI = 'Product Options Settings',
}

export const enum StockIndicatorPanelNames {
  SETTINGS = 'Stock Indicator Settings',
}

export const enum RootPanelNames {
  DESKTOP_LAYOUT = 'Product Page Layout',
  MOBILE_LAYOUT = 'New Mobile Product Page Layout',
}

export const enum CustomTextFieldsPanelNames {
  SETTINGS = 'Text Field Settings',
}

export const enum SubscriptionPanelNames {
  SETTINGS = 'Subscriptions Settings',
  LAYOUT = 'Subscription Layout',
}
