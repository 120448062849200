import {ConnectedComponentsBuilder} from '../../editor.app.types';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {helpIds} from '../../../constants/app';

export const setBreadcrumbsComponentStageBehavior = (
  componentBuilder: ConnectedComponentsBuilder,
  t: ILocaleKeys,
): void => {
  componentBuilder.gfpp().set('help', {id: helpIds.BREADCRUMBS});
};
