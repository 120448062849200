import {PresetAlign, PresetDirection} from '../../../editor-app/utils';

export type rootWidgetPreset =
  | '1 column - left align'
  | 'RTL 1 column - right align'
  | '1 column - center align'
  | 'RTL 1 column - center align'
  | '2 columns - 66:33 - left align'
  | 'RTL 2 columns - 66:33 - right align'
  | '2 columns - 66:33 - center align'
  | 'RTL 2 columns - 66:33 - center align'
  | '1 column / mobile'
  | 'RTL column / mobile'
  | 'mobile - center align'
  | 'RTL mobile - center align'
  | '2 columns - 50:50 - left align'
  | 'RTL 2 columns - 50:50 - right align'
  | '2 columns - 50:50 - center align'
  | 'RTL 2 columns - 50:50 - center align';
export enum rootWidgetPresetNumOfColumns {
  one = '1 column',
  two = '2 column',
}

export type PresetType = 'desktop' | 'mobile';

export const rootWidgetPresetsIds: {[k in rootWidgetPreset]: string} = {
  '1 column / mobile': 'variants-kquwhsvv',
  'RTL column / mobile': 'variants-lkfpanij',
  'mobile - center align': 'variants-l9wihvlj',
  'RTL mobile - center align': 'variants-lkfpb026',
  '1 column - left align': 'variants-l74uae5w',
  'RTL 1 column - right align': 'variants-lkfpbd2e',
  '1 column - center align': 'variants-l9whrvl8',
  'RTL 1 column - center align': 'variants-lkfpbpnq',
  '2 columns - 50:50 - left align': 'variants-kquvm5qe',
  'RTL 2 columns - 50:50 - right align': 'variants-lkfpcng3',
  '2 columns - 66:33 - left align': 'variants-kyldlyx0',
  'RTL 2 columns - 66:33 - right align': 'variants-lkfpddr2',
  '2 columns - 66:33 - center align': 'variants-l74hlryw',
  'RTL 2 columns - 66:33 - center align': 'variants-lkfpdux9',
  '2 columns - 50:50 - center align': 'variants-l74gaa4x',
  'RTL 2 columns - 50:50 - center align': 'variants-lkfpd2kq',
};

export type rootWidgetColRatio = [{id: '1'; value: number}, {id: '2'; value: number}];

export const rootWidgetConfigToPreset: {
  [p in PresetType]: {
    [m in rootWidgetPresetNumOfColumns]?: {[k in PresetDirection]: {[n in PresetAlign]?: rootWidgetPreset}};
  };
} = {
  mobile: {
    '1 column': {
      rtl: {
        center: 'RTL mobile - center align',
        right: 'RTL column / mobile',
      },
      ltr: {
        center: 'mobile - center align',
        left: '1 column / mobile',
      },
    },
  },
  desktop: {
    '1 column': {
      rtl: {
        center: 'RTL 1 column - center align',
        right: 'RTL 1 column - right align',
      },
      ltr: {
        center: '1 column - center align',
        left: '1 column - left align',
      },
    },
    '2 column': {
      rtl: {
        center: 'RTL 2 columns - 66:33 - center align',
        right: 'RTL 2 columns - 66:33 - right align',
      },
      ltr: {
        center: '2 columns - 66:33 - center align',
        left: '2 columns - 66:33 - left align',
      },
    },
  },
};

export const rootWidgetPresetNameToConfig: {
  [k in rootWidgetPreset]: {
    direction: PresetDirection;
    align: PresetAlign;
    type: PresetType;
    numOfColumns: rootWidgetPresetNumOfColumns;
  };
} = {
  '1 column / mobile': {
    direction: PresetDirection.Ltr,
    align: PresetAlign.Left,
    type: 'mobile',
    numOfColumns: rootWidgetPresetNumOfColumns.one,
  },
  'mobile - center align': {
    direction: PresetDirection.Ltr,
    align: PresetAlign.Center,
    type: 'mobile',
    numOfColumns: rootWidgetPresetNumOfColumns.one,
  },
  'RTL column / mobile': {
    direction: PresetDirection.Rtl,
    align: PresetAlign.Right,
    type: 'mobile',
    numOfColumns: rootWidgetPresetNumOfColumns.one,
  },
  'RTL mobile - center align': {
    direction: PresetDirection.Rtl,
    align: PresetAlign.Center,
    type: 'mobile',
    numOfColumns: rootWidgetPresetNumOfColumns.one,
  },
  '1 column - center align': {
    direction: PresetDirection.Ltr,
    align: PresetAlign.Center,
    type: 'desktop',
    numOfColumns: rootWidgetPresetNumOfColumns.one,
  },
  '1 column - left align': {
    direction: PresetDirection.Ltr,
    align: PresetAlign.Left,
    type: 'desktop',
    numOfColumns: rootWidgetPresetNumOfColumns.one,
  },
  '2 columns - 66:33 - center align': {
    direction: PresetDirection.Ltr,
    align: PresetAlign.Center,
    type: 'desktop',
    numOfColumns: rootWidgetPresetNumOfColumns.two,
  },
  '2 columns - 66:33 - left align': {
    direction: PresetDirection.Ltr,
    align: PresetAlign.Left,
    type: 'desktop',
    numOfColumns: rootWidgetPresetNumOfColumns.two,
  },
  '2 columns - 50:50 - left align': {
    direction: PresetDirection.Ltr,
    align: PresetAlign.Left,
    type: 'desktop',
    numOfColumns: rootWidgetPresetNumOfColumns.two,
  },
  'RTL 2 columns - 50:50 - right align': {
    direction: PresetDirection.Rtl,
    align: PresetAlign.Right,
    type: 'desktop',
    numOfColumns: rootWidgetPresetNumOfColumns.two,
  },
  'RTL 1 column - center align': {
    direction: PresetDirection.Rtl,
    align: PresetAlign.Center,
    type: 'desktop',
    numOfColumns: rootWidgetPresetNumOfColumns.one,
  },
  'RTL 1 column - right align': {
    direction: PresetDirection.Rtl,
    align: PresetAlign.Right,
    type: 'desktop',
    numOfColumns: rootWidgetPresetNumOfColumns.one,
  },
  'RTL 2 columns - 66:33 - center align': {
    direction: PresetDirection.Rtl,
    align: PresetAlign.Center,
    type: 'desktop',
    numOfColumns: rootWidgetPresetNumOfColumns.two,
  },
  '2 columns - 50:50 - center align': {
    direction: PresetDirection.Ltr,
    align: PresetAlign.Center,
    type: 'desktop',
    numOfColumns: rootWidgetPresetNumOfColumns.two,
  },
  'RTL 2 columns - 66:33 - right align': {
    direction: PresetDirection.Rtl,
    align: PresetAlign.Right,
    type: 'desktop',
    numOfColumns: rootWidgetPresetNumOfColumns.two,
  },
  'RTL 2 columns - 50:50 - center align': {
    direction: PresetDirection.Rtl,
    align: PresetAlign.Center,
    type: 'desktop',
    numOfColumns: rootWidgetPresetNumOfColumns.two,
  },
};
