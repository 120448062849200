import {ElementCategoryData} from '../../editor.app.types';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {ELEMENTS_PANEL_CATEGORIES_IDS as CATEGORIES_IDS} from './constants';

export const getCategoriesData = (t: ILocaleKeys): ElementCategoryData[] => {
  return [
    {
      id: CATEGORIES_IDS.info,
      title: t.productPage.productPageWidget.elements.productInfo.title(),
    },
    {
      id: CATEGORIES_IDS.pricing,
      title: t.productPage.productPageWidget.elements.pricing.label(),
      tooltipData: {
        content: t.productPage.productPageWidget.elements.pricing.tooltip.text(),
        link: {
          text: t.productPage.productPageWidget.elements.pricing.tooltip.link(),
          helpId: 'a6e0b7e6-a597-4550-8358-bcaaa2fa4146',
        },
      },
    },
    {
      id: CATEGORIES_IDS.ctaButtons,
      title: t.productPage.productPageWidget.elements.actionButtons.title(),
    },
    {
      id: CATEGORIES_IDS.navigation,
      title: t.productPage.productPageWidget.elements.navigation.title(),
    },
    {
      id: CATEGORIES_IDS.shareButtons,
      title: t.productPage.productPageWidget.elements.shareButtons.title(),
      tooltipData: {
        content: t.productPage.productPageWidget.elements.shareButtons.tooltip.text(),
      },
    },
  ];
};
