import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {MAX_QUANTITY_INPUT, MIN_QUANTITY_INPUT} from '../rootWidget/config/constants';
import {QuantityWidgetEvents as Events} from './config/constants';

const DEFAULT_SETTINGS = {
  label: undefined as string | undefined,
};

export default createBlocksModel({
  widgetName: 'quantityWidget',
  props: {
    min: {
      type: 'number',
      defaultValue: MIN_QUANTITY_INPUT,
    },
    max: {
      type: 'number',
      defaultValue: MAX_QUANTITY_INPUT,
    },
    value: {
      type: 'number',
      defaultValue: 1,
    },
    isError: {
      type: 'boolean',
      defaultValue: false,
    },
    errorMessage: {
      type: 'text',
      defaultValue: '',
    },
    settings: {
      type: undefined,
      defaultValue: DEFAULT_SETTINGS,
    },
  },
  methods: {
    focus: {},
  },
  events: {
    [Events.Change]: {},
  },
});
