import {WidgetBuilder} from '../../editor.app.types';
import {productPageDesignBuilderFn} from '../global-design/root.widget.design';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {ActionIds} from '../../../constants/gfpp';
import {helpIds} from '../../../constants/app';

export const setRootWidgetStageBehavior = (
  widgetBuilder: WidgetBuilder,
  isStoresPremium: boolean,
  t: ILocaleKeys,
): void => {
  widgetBuilder.set({displayName: t.productPage.productPageWidget.displayName(), nickname: 'productPage'});

  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t.productPage.productPageWidget.gfpp.manage(),
      actionId: ActionIds.ROOT_MANAGE_PRODUCTS,
    })
    .set('mainAction2', {label: t.productPage.productPageWidget.gfpp.layout(), actionId: ActionIds.ROOT_LAYOUT_DESKTOP})
    .set('layout', {actionId: ActionIds.ROOT_LAYOUT_DESKTOP})
    .set('add', {actionId: ActionIds.ROOT_ELEMENTS})
    .set('connect', {behavior: 'HIDE'})
    .set('help', {id: helpIds.MAIN_WIDGET});

  widgetBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    essential: {
      enabled: false,
    },
  });

  if (isStoresPremium) {
    widgetBuilder.gfpp().set('upgrade', {behavior: 'HIDE'});
  } else {
    widgetBuilder.gfpp().set('upgrade', {actionId: ActionIds.ROOT_UPGRADE});
  }

  widgetBuilder
    .gfpp('mobile')
    .set('mainAction2', {label: t.productPage.productPageWidget.gfpp.layout(), actionId: ActionIds.ROOT_LAYOUT_MOBILE})
    .set('animation', {behavior: 'HIDE'})
    .set('layout', {actionId: ActionIds.ROOT_LAYOUT_MOBILE})
    .set('hide', {behavior: 'HIDE'});

  widgetBuilder.configureWidgetDesign((wb) => productPageDesignBuilderFn(wb, t));
};
