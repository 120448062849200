import {createBlocksModel} from '@wix/yoshi-flow-editor';
import {DropdownWidgetEvents} from './config/constants';
import {ChoiceItem} from '../optionsNewWidget/config/types';

export default createBlocksModel({
  widgetName: 'dropdownWidget',
  props: {
    shouldShowTitle: {
      type: 'boolean',
      defaultValue: true,
    },
    label: {
      type: 'text',
      defaultValue: '',
    },
    selections: {
      type: undefined,
      defaultValue: [] as ChoiceItem[],
    },
    selectionIds: {
      type: undefined,
      defaultValue: [] as number[],
    },
    isError: {
      type: 'boolean',
      defaultValue: false,
    },
    errorMessage: {
      type: 'text',
      defaultValue: '',
    },
  },
  methods: {
    focus: {},
  },
  events: {
    [DropdownWidgetEvents.Change]: {},
  },
});
