import {WidgetBuilder} from '../../editor.app.types';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {getRole} from '../global-design/getRole';
import {
  CustomTextFieldsWidgetComponentIds as WidgetComponentIds,
  CustomTextFieldsItemComponentIds as ItemComponentIds,
} from '../../../components/customTextFieldsWidget/config/constants';
import {setComboBoxInputDesignPanelsWithHiddenErrorState} from '../../panels/comboBoxInputPanel';
import {setComponentsAsUnselectable} from './helpers/setComponentsAsUnselectable';

export const setCustomTextFieldsInnerWidgetsStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder
    .configureConnectedComponents(getRole(ItemComponentIds.Label), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.customTextWidget.title.displayName()});
    })
    .configureConnectedComponents(getRole(ItemComponentIds.TextBox), (innerWidgetBuilder) => {
      setComboBoxInputDesignPanelsWithHiddenErrorState(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.customTextWidget.inputField.displayName()});
    })
    .configureConnectedComponents(getRole(ItemComponentIds.CharCounter), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.customTextWidget.characterCounter.displayName()});
    });
  setComponentsAsUnselectable(ItemComponentIds, widgetBuilder);
  setComponentsAsUnselectable(WidgetComponentIds, widgetBuilder);
};
