import {PresetAlign, PresetDirection} from '../../../editor-app/utils';

export const enum InfoSectionLayouts {
  Column = 'column',
  Stacked = 'stacked',
  Collapse = 'collapse',
  Cards = 'cards',
}

export const enum InfoSectionAccordionDefaultState {
  AllCollapsed = 'all-collapsed',
  FirstExpanded = 'first-expanded',
}

export type InfoSectionPresets =
  | 'column left'
  | 'column RTL'
  | 'column center'
  | 'column center RTL'
  | 'cards left'
  | 'cards RTL'
  | 'cards center'
  | 'cards center RTL'
  | 'stacked left'
  | 'stacked RTL'
  | 'stacked center'
  | 'stacked center RTL'
  | 'collapse left'
  | 'collapse RTL'
  | 'collapse center'
  | 'collapse center RTL';

export const InfoSectionConfigToPreset: {
  [k in InfoSectionLayouts]: {[m in PresetAlign]: {[n in PresetDirection]?: InfoSectionPresets}};
} = {
  column: {
    left: {
      ltr: 'column left',
    },
    right: {
      rtl: 'column RTL',
    },
    center: {
      ltr: 'column center',
      rtl: 'column center RTL',
    },
  },
  cards: {
    left: {
      ltr: 'cards left',
    },
    right: {
      rtl: 'cards RTL',
    },
    center: {
      ltr: 'cards center',
      rtl: 'cards center RTL',
    },
  },
  stacked: {
    left: {
      ltr: 'stacked left',
    },
    right: {
      rtl: 'stacked RTL',
    },
    center: {
      ltr: 'stacked center',
      rtl: 'stacked center RTL',
    },
  },
  collapse: {
    left: {
      ltr: 'collapse left',
    },
    right: {
      rtl: 'collapse RTL',
    },
    center: {
      ltr: 'collapse center',
      rtl: 'collapse center RTL',
    },
  },
};
export const InfoSectionPresetToConfig: {
  [k in InfoSectionPresets]: {
    layout: InfoSectionLayouts;
    direction: PresetDirection;
    align: PresetAlign;
  };
} = {
  'stacked left': {
    layout: InfoSectionLayouts.Stacked,
    direction: PresetDirection.Ltr,
    align: PresetAlign.Left,
  },
  'stacked RTL': {
    layout: InfoSectionLayouts.Stacked,
    direction: PresetDirection.Rtl,
    align: PresetAlign.Right,
  },
  'stacked center': {
    layout: InfoSectionLayouts.Stacked,
    direction: PresetDirection.Ltr,
    align: PresetAlign.Center,
  },
  'stacked center RTL': {
    layout: InfoSectionLayouts.Stacked,
    direction: PresetDirection.Rtl,
    align: PresetAlign.Center,
  },
  'collapse left': {
    layout: InfoSectionLayouts.Collapse,
    direction: PresetDirection.Ltr,
    align: PresetAlign.Left,
  },
  'collapse RTL': {
    layout: InfoSectionLayouts.Collapse,
    direction: PresetDirection.Rtl,
    align: PresetAlign.Right,
  },
  'collapse center': {
    layout: InfoSectionLayouts.Collapse,
    direction: PresetDirection.Ltr,
    align: PresetAlign.Center,
  },
  'collapse center RTL': {
    layout: InfoSectionLayouts.Collapse,
    direction: PresetDirection.Rtl,
    align: PresetAlign.Center,
  },
  'column left': {
    layout: InfoSectionLayouts.Column,
    direction: PresetDirection.Ltr,
    align: PresetAlign.Left,
  },
  'column RTL': {
    layout: InfoSectionLayouts.Column,
    direction: PresetDirection.Rtl,
    align: PresetAlign.Right,
  },
  'column center': {
    layout: InfoSectionLayouts.Column,
    direction: PresetDirection.Ltr,
    align: PresetAlign.Center,
  },
  'column center RTL': {
    layout: InfoSectionLayouts.Column,
    direction: PresetDirection.Rtl,
    align: PresetAlign.Center,
  },
  'cards left': {
    layout: InfoSectionLayouts.Cards,
    direction: PresetDirection.Ltr,
    align: PresetAlign.Left,
  },
  'cards RTL': {
    layout: InfoSectionLayouts.Cards,
    direction: PresetDirection.Rtl,
    align: PresetAlign.Right,
  },
  'cards center': {
    layout: InfoSectionLayouts.Cards,
    direction: PresetDirection.Ltr,
    align: PresetAlign.Center,
  },
  'cards center RTL': {
    layout: InfoSectionLayouts.Cards,
    direction: PresetDirection.Rtl,
    align: PresetAlign.Center,
  },
};
