import {ConnectedComponentsBuilder} from '../editor.app.types';

export const setComboBoxInputDesignPanelsWithHiddenErrorState = (innerWidgetBuilder: ConnectedComponentsBuilder) => {
  innerWidgetBuilder.panel<'ComboBoxInput'>('design').configureStates({error: {hidden: true}});
  innerWidgetBuilder.panel<'ComboBoxInput'>('design').configureControls({
    fntprefix: {
      hidden: true,
    },
    txtlbl: {
      hidden: true,
    },
    fntlbl: {
      hidden: true,
    },
    txtlblrq: {
      hidden: true,
    },
  } as any);
};
