import {WidgetBuilder} from '../../editor.app.types';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {
  PriceWidgetComponentIds,
  PriceWidgetComponentIds as ComponentIds,
} from '../../../components/priceWidget/config/constants';
import {getRole} from '../global-design/getRole';
import {setRichTextSettingsControls, setRichTextSettingsSections} from '../../panels/richTextPanels';
import {setComponentsAsUnselectable} from './helpers/setComponentsAsUnselectable';

export const setPricingInnerWidgetsStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder
    .configureConnectedComponents(getRole(ComponentIds.FromDefaultLang), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.pricingWidget.fromLabel.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.FromSpecialLang), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.pricingWidget.fromLabel.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.OriginalPriceDefaultLang), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.pricingWidget.strikethroughPrice.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.OriginalPriceSpecialLang), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.pricingWidget.strikethroughPrice.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.ActualPriceDefaultLang), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.pricingWidget.finalPrice.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.ActualPriceSpecialLang), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.pricingWidget.finalPrice.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.FrequencyDefaultLang), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.pricingWidget.subscription.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.FrequencySpecialLang), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.pricingWidget.subscription.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.DiscountTitle), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.pricingWidget.discountName.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.PricePerUnit), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.pricingWidget.pricePerUnit.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.TaxDisclaimer), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.pricingWidget.taxShippingInfo.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.ShippingDisclaimer), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: ''});
    })
    .configureConnectedComponents(getRole(ComponentIds.DisclaimerSeparator), (innerWidgetBuilder) => {
      setRichTextSettingsControls(innerWidgetBuilder);
      setRichTextSettingsSections(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: ''});
    })
    .configureConnectedComponents(getRole(ComponentIds.BreakdownBox), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.pricingWidget.taxShippingInfo.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.CashierBanner), (innerWidgetBuilder) => {
      innerWidgetBuilder.behavior().set({
        closed: {
          selectable: true,
        },
      });
    });

  setComponentsAsUnselectable(
    [
      ComponentIds.FromDefaultLang,
      ComponentIds.FromSpecialLang,
      ComponentIds.OriginalPriceDefaultLang,
      PriceWidgetComponentIds.OriginalPriceDefaultLang,
      ComponentIds.OriginalPriceSpecialLang,
      ComponentIds.ActualPriceDefaultLang,
      ComponentIds.ActualPriceSpecialLang,
      ComponentIds.FrequencyDefaultLang,
      ComponentIds.FrequencySpecialLang,
      ComponentIds.DiscountTitle,
      ComponentIds.PricePerUnit,
      ComponentIds.TaxDisclaimer,
      ComponentIds.ShippingDisclaimer,
      ComponentIds.DisclaimerSeparator,
      ComponentIds.DisclaimerSeparator,
      ComponentIds.BreakdownBox,
      ComponentIds.PriceAndDiscountMSB,
      ComponentIds.DefaultLangState,
      ComponentIds.SpecialLangState,
    ],
    widgetBuilder,
  );
};
