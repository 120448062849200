import {WidgetBuilder, ConnectedComponentsBuilder} from '../../editor.app.types';
import {getRole} from '../global-design/getRole';
import {SocialShareWidgetComponentIds as ComponentIds} from '../../../components/socialShareWidget/config/constants';
import {stylableButtonsPanel} from '../../panels/stylableButtonPanels';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {helpIds} from '../../../constants/app';

export const setSocialShareButtonsStageBehavior = (wb: WidgetBuilder, t: ILocaleKeys): void => {
  const innerBuilder = (innerWidgetBuilder: ConnectedComponentsBuilder, displayName: string) => {
    stylableButtonsPanel.setPanelWithIconAndLink(innerWidgetBuilder);
    innerWidgetBuilder.behavior().set({closed: {selectable: true}});
    innerWidgetBuilder.set({displayName});
    innerWidgetBuilder.gfpp().set('help', {id: helpIds.SHARE_BUTTONS});
  };

  wb.configureConnectedComponents(getRole(ComponentIds.Facebook), (innerWidgetBuilder) => {
    innerBuilder(innerWidgetBuilder, t.productPage.facebookButton.displayName());
  })
    .configureConnectedComponents(getRole(ComponentIds.Pinterest), (innerWidgetBuilder) => {
      innerBuilder(innerWidgetBuilder, t.productPage.pinterestButton.displayName());
    })
    .configureConnectedComponents(getRole(ComponentIds.WhatsApp), (innerWidgetBuilder) => {
      innerBuilder(innerWidgetBuilder, t.productPage.whatsappButton.displayName());
    })
    .configureConnectedComponents(getRole(ComponentIds.Twitter), (innerWidgetBuilder) => {
      innerBuilder(innerWidgetBuilder, t.productPage.twitterButton.displayName());
    });
};
