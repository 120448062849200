import {ConnectedComponentsBuilder} from '../editor.app.types';

// TODO - uncomment when type from editor-platfrom are ready
// type ControlGroups = ControlType<'CollapsibleText', 'settings', 'controlGroups'>;
// type Controls = ControlType<'CollapsibleText', 'settings', 'controls'>;
type ControlGroups = any;
type Controls = any;

const defaultHiddenSections: ControlGroups = {
  seoA11y: {
    hidden: true,
  },
};

const defaultHiddenControls: Controls = {
  expandMode: {
    hidden: true,
  },
  editText: {
    hidden: true,
  },
};

export const setCollapsibleTextSettingsSections = (
  componentBuilder: ConnectedComponentsBuilder,
  overrideSections: ControlGroups = {},
) => {
  componentBuilder
    .panel<'CollapsibleText'>('settings')
    .configureSections({...defaultHiddenSections, ...overrideSections});
};

export const setCollapsibleTextSettingsControls = (
  componentBuilder: ConnectedComponentsBuilder,
  overrideControls: Controls = {},
) => {
  componentBuilder
    .panel<'CollapsibleText'>('settings')
    .configureControls({...defaultHiddenControls, ...overrideControls});
};
