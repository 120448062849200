import {getRole} from './getRole';
import {SkuWidgetComponentIds} from '../../../components/skuWidget/config/constants';
import {helpIds} from '../../../constants/app';
import {WidgetDesignBuilderFn} from '../../editor.app.types';

export const skuDesignBuilderFn: WidgetDesignBuilderFn = (widgetDesignBuilder, t): void => {
  widgetDesignBuilder.set({
    title: t.productPage.skuWidget.design.panelHeader(),
    customHelpId: helpIds.SKU,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.skuWidget.design.label.sectionName(),
        })
        .groups()
        .set({
          roles: [SkuWidgetComponentIds.Label].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.skuWidget.design.code.sectionName(),
        })
        .groups()
        .set({
          roles: [SkuWidgetComponentIds.Value].map(getRole),
        });
    });
};
