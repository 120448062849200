export enum CustomTextFieldsWidgetComponentIds {
  TopContainer = '#box1',
  Repeater = '#repeater1',
}

export enum CustomTextFieldsItemComponentIds {
  FieldWrapper = '#fieldWrapper1',
  Label = '#customTextFieldLabel1',
  TextBox = '#customTextTextBox1',
  CharCounter = '#customTextCharCounter1',
  Error = '#errorMessage1',
}

export enum CustomTextFieldsWidgetEvents {
  Change = 'change',
}
