import {SocialShareWidgetComponentIds} from '../../../components/socialShareWidget/config/constants';
import {getRole} from './getRole';
import {helpIds} from '../../../constants/app';
import {WidgetDesignBuilderFn} from '../../editor.app.types';

export const socialShareDesignBuilderFn: WidgetDesignBuilderFn = (widgetDesignBuilder, t): void => {
  widgetDesignBuilder.set({
    title: t.productPage.shareButtonsWidget.design.panelHeader(),
    customHelpId: helpIds.SHARE_BUTTONS,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder.addTab((tabBuilder) => {
    tabBuilder
      .set({label: t.productPage.shareButtonsWidget.design.panelHeader()})
      .groups()
      .set({
        roles: [
          SocialShareWidgetComponentIds.Facebook,
          SocialShareWidgetComponentIds.Twitter,
          SocialShareWidgetComponentIds.WhatsApp,
          SocialShareWidgetComponentIds.Pinterest,
        ].map(getRole),
      });
  });
};
