import {AppEssentials} from '@wix/fe-essentials-editor';
import {STORES_PREMIUM} from '../../../constants/app';
import {query as getConfigQuery} from './getConfig.graphql';
import {createHeaders} from '@wix/headers';

export const getIsStoresPremium = async (
  ecomAppInstance: string,
  httpClient: AppEssentials['httpClient'],
): Promise<boolean> => {
  try {
    const headers = createHeaders({signedInstance: ecomAppInstance});
    const config: any = await httpClient?.post(
      '/_api/wix-ecommerce-storefront-web/api',
      {
        query: getConfigQuery,
        source: 'WixStoresWebClient',
        operationName: 'getConfig',
      },
      {headers},
    );
    const premiumFeatures = config.data.data?.premiumFeatures;
    return premiumFeatures.some((feature) => feature.name === STORES_PREMIUM);
  } catch (e) /* istanbul ignore next reason: only for edge case of fetch failure */ {
    // eslint-disable-next-line no-console
    console.error(e);
    return false;
  }
};
