import {WidgetBuilder} from '../../editor.app.types';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {getRole} from '../global-design/getRole';
import {SkuWidgetComponentIds as ComponentIds} from '../../../components/skuWidget/config/constants';
import {setRichTextSettingsSections, setRichTextSettingsControls} from '../../panels/richTextPanels';
import {setComponentsAsUnselectable} from './helpers/setComponentsAsUnselectable';

export const setSkuInnerComponentsWidgetStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder
    .configureConnectedComponents(getRole(ComponentIds.Label), (componentBuilder) => {
      setRichTextSettingsControls(componentBuilder);
      setRichTextSettingsSections(componentBuilder);
      componentBuilder.set({displayName: t.productPage.skuWidget.skuLabel.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds.Value), (componentBuilder) => {
      setRichTextSettingsControls(componentBuilder);
      setRichTextSettingsSections(componentBuilder);
      componentBuilder.set({displayName: t.productPage.skuWidget.skuCode.displayName()});
    });
  setComponentsAsUnselectable(ComponentIds, widgetBuilder);
};
