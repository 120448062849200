import {InfoSectionItemComponentIds} from '../../../components/infoSectionsWidget/config/constants';
import {getRole} from './getRole';
import {helpIds} from '../../../constants/app';
import {WidgetDesignBuilderFn} from '../../editor.app.types';

export const infoSectionDesignBuilderFn: WidgetDesignBuilderFn = (widgetDesignBuilder, t): void => {
  widgetDesignBuilder.set({
    title: t.productPage.infoSectionWidget.design.panelHeader(),
    customHelpId: helpIds.INFO_SECTIONS,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.infoSectionWidget.design.title.sectionName(),
        })
        .groups()
        .set({
          roles: [InfoSectionItemComponentIds.Title].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.infoSectionWidget.design.body.sectionName(),
        })
        .groups()
        .set({
          roles: [InfoSectionItemComponentIds.Paragraph].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({label: t.productPage.infoSectionWidget.design.divider.sectionName()})
        .groups()
        .set({
          roles: [
            InfoSectionItemComponentIds.SeparatorLineVertical,
            InfoSectionItemComponentIds.SeparatorLineHorizontal,
          ].map(getRole),
        });
    });
};
