import {WidgetBuilder} from '../../editor.app.types';
import {quantityDesignBuilderFn} from '../global-design/quantity.widget.design';
import {helpIds} from '../../../constants/app';
import {ActionIds} from '../../../constants/gfpp';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';

export const setQuantityWidgetStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder.set({displayName: t.productPage.quantityWidget.displayName()});

  widgetBuilder
    .gfpp()
    .set('settings', {actionId: ActionIds.QUANTITY_SETTINGS})
    .set('mainAction1', {label: t.productPage.quantityWidget.gfpp.design(), actionType: 'design', actionId: ''})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('connect', {behavior: 'HIDE'})
    .set('layout', {behavior: 'HIDE'})
    .set('add', {behavior: 'HIDE'})
    .set('help', {id: helpIds.QUANTITY});

  widgetBuilder
    .gfpp('mobile')
    .set('settings', {actionId: ActionIds.QUANTITY_SETTINGS})
    .set('mainAction1', {behavior: 'HIDE'})
    .set('mainAction2', {behavior: 'HIDE'})
    .set('layout', {behavior: 'HIDE'})
    .set('hide', {behavior: 'HIDE'});

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => quantityDesignBuilderFn(widgetDesignBuilder, t));
};
