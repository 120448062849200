export const RootWidgetComponentIds = {
  RootWidgetContainer: '#rootContainer',
  Quantity: '#productQuantity',
  CtaButtons: '#ctaButtons',
  ProductTitle: '#productTitle',
  CustomTextFields: '#customTextFields',
  Sku: '#sku',
  Price: '#productPrice',
  Info: '#infoSection',
  CollapsibleDescription: '#collapsibleDescription',
  RicoDescription: '#ricoDescription',
  SocialShare: '#socialShare',
  Subscription: '#subscription',
  OptionsNew: '#optionsWrapperRepeater1',
  Gallery: '#proGallery1',
  Navigation: '#navigation',
  Breadcrumbs: '#breadcrumbs1',
  StockIndicator: '#stockIndicator1',
  WishlistButtonSlot: '#slotsPlaceholder1',
  ReviewsSummarySlot: '#reviewsSummarySlot',
  ReviewsSectionSlot: '#reviewsSectionSlot',
  DetailsPlaceholder: '#detailsPlaceholder',
  BottomPlaceholder: '#bottomPlaceholder',
  ProductPageTopSlot: '#product-page-top',
  ProductPageBottomSlot: '#product-page-bottom',
  ProductPageDetails1Slot: '#product-page-details-1',
  ProductPageDetails2Slot: '#product-page-details-2',
  ProductPageDetails3Slot: '#product-page-details-3',
  ProductPageDetails4Slot: '#product-page-details-4',
  ProductPageDetails5Slot: '#product-page-details-5',
  ProductPageDetails6Slot: '#product-page-details-6',
  ProductPageDetails7Slot: '#product-page-details-7',
  ProductPageDetails8Slot: '#product-page-details-8',
  ProductPageDetails9Slot: '#product-page-details-9',
  ProductPageMedia1Slot: '#product-page-media-1',
} as const;

export const MAX_QUANTITY_INPUT = 9_999_9;
export const MIN_QUANTITY_INPUT = 1;
