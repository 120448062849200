export enum PriceWidgetComponentIds {
  PricePerUnit = '#perUnitPrice',
  TaxDisclaimer = '#taxDisclaimer',
  DisclaimerSeparator = '#disclaimerSeperator',
  ShippingDisclaimer = '#shippingDisclaimer',
  PricePerUnitBox = '#perUnitBox',
  TopContainer = '#box1',
  BreakdownBox = '#breakdownBox',
  PriceAndDiscountBox = '#priceAndDiscountBox',
  CashierBanner = '#cashierBanner',
  DiscountTitle = '#discountTitle',
  PriceAndDiscountMSB = '#priceAndDiscountMSB',
  FrequencyDefaultLang = '#frequencyDefaultLang',
  FrequencySpecialLang = '#frequencySpecialLang',
  OriginalPriceDefaultLang = '#originalPriceLang',
  OriginalPriceSpecialLang = '#originalPriceSpecialLang',
  ActualPriceDefaultLang = '#actualPriceDefaultLang',
  ActualPriceSpecialLang = '#actualPriceSpecialLang',
  FromDefaultLang = '#fromLabelLang',
  FromSpecialLang = '#fromLabelSpecialLang',
  DefaultLangState = '#defaultLangState',
  SpecialLangState = '#specialLangState',
}

export enum PriceAndDiscountMSBStates {
  DefaultLanguage = 'defaultLangState',
  SpecialLanguage = 'specialLangState',
}
