import {ConnectedComponentsBuilder} from '../editor.app.types';

export const stylableButtonsPanel = {
  setPanelWithHiddenLink(innerWidgetBuilder: ConnectedComponentsBuilder) {
    innerWidgetBuilder.panel<'StylableButton'>('settings').configureControls({
      link: {
        hidden: true,
      },
    } as any); // https://jira.wixpress.com/browse/ECL-5987
  },
  setPanelWithIconAndLink(innerWidgetBuilder: ConnectedComponentsBuilder) {
    innerWidgetBuilder.panel<'StylableButton'>('settings').configureControls({
      link: {
        hidden: true,
      },
      icon: {
        hidden: true,
      },
    } as any); // https://jira.wixpress.com/browse/ECL-5987
  },
};
