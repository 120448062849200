import {AppManifestEventHandler} from '@wix/app-manifest-builder/dist/types/events/eventHandlerTypes';
import {EditorSDK} from '@wix/platform-editor-sdk';

export const getOpenNativePanelWithHelpId =
  (editorSDK: EditorSDK, nativePanelType: string, helpId: string): AppManifestEventHandler<'componentGfppClicked'> =>
  (e) => {
    editorSDK.editor.openNativeComponentPanel('', nativePanelType, {
      componentRef: e.detail.componentRef,
      helpId,
    });
  };
