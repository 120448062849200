import {ControlType, ConnectedComponentsBuilder} from '../editor.app.types';

type ControlGroups = Partial<ControlType<'WRichText', 'settings', 'controlGroups'>>;
type Controls = Partial<ControlType<'WRichText', 'settings', 'controls'>>;

const defaultHiddenSections: ControlGroups = {
  verticalText: {
    hidden: true,
  },
  seoA11y: {
    hidden: true,
  },
};

const defaultHiddenControls: Controls = {
  link: {
    hidden: true,
  },
  bulletsAndNumbering: {
    hidden: true,
  },
};

export const setRichTextSettingsSections = (
  componentBuilder: ConnectedComponentsBuilder,
  overrideSections: ControlGroups = {},
) => {
  componentBuilder
    .panel<'WRichText'>('settings')
    .configureSections({...defaultHiddenSections, ...overrideSections} as any); // https://jira.wixpress.com/browse/ECL-5987
};

export const setRichTextSettingsControls = (
  componentBuilder: ConnectedComponentsBuilder,
  overrideControls: Controls = {},
) => {
  componentBuilder
    .panel<'WRichText'>('settings')
    .configureControls({...defaultHiddenControls, ...overrideControls} as any); // https://jira.wixpress.com/browse/ECL-5987
};
