import {WidgetBuilder, ConnectedComponentsBuilder} from '../../editor.app.types';
import {StockIndicatorWidgetComponentIds as ComponentIds} from '../../../components/stockIndicatorWidget/config/constants';
import {getRole} from '../global-design/getRole';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';

export const setStockIndicatorStageBehavior = (wb: WidgetBuilder, t: ILocaleKeys): void => {
  wb.configureConnectedComponents(
    getRole(ComponentIds.StockIndicatorText),
    (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.stockWidget.lowStock.displayName()});
      innerWidgetBuilder.behavior().set({closed: {selectable: false, hideFromHierarchy: true}});
    },
  ).configureConnectedComponents(
    getRole(ComponentIds.OutOfStockText),
    (innerWidgetBuilder: ConnectedComponentsBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.stockWidget.outOfStock.displayName()});
      innerWidgetBuilder.behavior().set({closed: {selectable: false, hideFromHierarchy: true}});
    },
  );
};
