import {editorReady as editorReadyWrapper} from './editor-app/editorReady';
import {getAppManifest as getAppManifestWrapper} from './editor-app/getAppManifest';
import {EditorReadyFn, FlowAPI, GetAppManifestFn, FlowEditorSDK, FlowPlatformOptions} from '@wix/yoshi-flow-editor';
import {STORES_APP_DEF_ID} from './constants/app';

export const editorReady: EditorReadyFn = async (editorSDK, appDefinitionId, platformOptions, flowAPI: FlowAPI) => {
  /* istanbul ignore next reason: temp workaround for cashier banner bug */
  return editorReadyWrapper(editorSDK, appDefinitionId, platformOptions, flowAPI);
};

export const getAppManifest: GetAppManifestFn = async (options, editorSDK, contextParams, flowAPI) => {
  return getAppManifestWrapper(options, editorSDK, contextParams, flowAPI);
};

export const exports = (editorSDK: FlowEditorSDK, options: FlowPlatformOptions, ...args) => {
  return {
    editor: {
      async removeAppCompleted({appDefinitionId}) {
        if (appDefinitionId === STORES_APP_DEF_ID) {
          await editorSDK.application.uninstall('', {openConfirmation: false});
        }
      },
    },
    public: {
      uninstallApp() {
        return editorSDK.application.uninstall('', {openConfirmation: false});
      },
    },
  };
};
