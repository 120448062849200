import {ConnectedComponentsBuilder, FlowEditorSDK} from '../../../editor.app.types';
import {setRichTextSettingsSections, setRichTextSettingsControls} from '../../../panels/richTextPanels';

export const setTextComponentStageBehavior = (
  componentBuilder: ConnectedComponentsBuilder,
  editorSDK: FlowEditorSDK,
  displayName: string,
  designGfppLabel: string,
  helpId?: string,
): void => {
  componentBuilder.behavior().set({dataEditOptions: 'TEXT_STYLE_ONLY'});
  componentBuilder.set({displayName});
  setRichTextSettingsSections(componentBuilder);
  setRichTextSettingsControls(componentBuilder);
  componentBuilder
    .gfpp()
    .set('mainAction1', {
      label: designGfppLabel,
      onClick: ({detail: {componentRef}}) => {
        void editorSDK.editor.openNativeComponentPanel('', 'settings', {
          componentRef,
        });
      },
    })
    .set('mainAction2', {behavior: 'HIDE'})
    .set('connect', {behavior: 'HIDE'})
    .set('layout', {behavior: 'HIDE'})
    .set('settings', {behavior: 'HIDE'});
  helpId && componentBuilder.gfpp().set('help', {id: helpId});

  componentBuilder
    .gfpp('mobile')
    .set('mainAction2', {behavior: 'HIDE'})
    .set('layout', {behavior: 'HIDE'})
    .set('hide', {behavior: 'HIDE'});
};
