export enum InfoSectionLayouts {
  ColumnsLeft = 'column left',
  ColumnsCenter = 'column center',
  StackedLeft = 'stacked left',
  StackedCenter = 'stacked center',
  CollapseLeft = 'collapse left',
  CollapseCenter = 'collapse center',
  CardsLeft = 'cards left',
  CardsCenter = 'cards center',
}

export enum InfoSectionLayoutTypes {
  Columns = 'column',
  Stacked = 'stacked',
  Collapse = 'collapse',
  Cards = 'cards',
}
