export const enum ActionIds {
  ADD_TO_CART_SETTINGS = 'ctaSettings',
  CTA_PRE_ORDER_SETTINGS = 'ctaPreOrderSettings',
  CTA_LAYOUT = 'ctaLayout',
  CTA_CHANGE_STATE = 'ctaChangeState',
  INFO_SECTIONS_LAYOUT_DESKTOP = 'infoSectionLayoutDesktop',
  INFO_SECTIONS_LAYOUT_MOBILE = 'infoSectionLayoutMobile',
  QUANTITY_SETTINGS = 'quantitySettings',
  SKU_SETTINGS = 'skuSettings',
  OPTIONS_SETTINGS = 'productOptionsSettings',
  STOCK_INDICATOR_SETTINGS = 'stockIndicatorSettings',
  SUBSCRIPTION_SETTINGS = 'subscriptionSettings',
  SUBSCRIPTION_LAYOUT = 'subscriptionLayout',
  ROOT_MANAGE_PRODUCTS = 'manageProducts',
  ROOT_LAYOUT_DESKTOP = 'productPageLayoutDesktop',
  ROOT_LAYOUT_MOBILE = 'productPageLayoutMobile',
  ROOT_ELEMENTS = 'openShowHidePanel',
  ROOT_UPGRADE = 'upgradeButtonAction',
  CUSTOM_TEXT_FIELD_SETTINGS = 'textFieldSettings',
}

export const enum PanelModularizationCustomActionIds {
  GALLERY_LAYOUT = 'openGalleryLayoutPanel',
}
