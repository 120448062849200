import {i18n} from 'i18next';
import {LocaleKeys} from '../../locale-keys/LocaleKeys';

export const initLocaleKeys = (i18nInstance: i18n) => {
  return LocaleKeys(i18nInstance.t.bind(i18nInstance));
};

export const initLocaleKeysWithTranslationFn = (fn: any) => {
  return LocaleKeys(fn);
};
