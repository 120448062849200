import {EditorSDK} from '@wix/platform-editor-sdk';

export const getCompRefByRole = async (editorSDK: EditorSDK, role: string) => {
  const [appRefComponent] = await editorSDK.components.refComponents.getAllAppRefComponents('');
  const [ppRef] = await editorSDK.components.getChildren('', {componentRef: appRefComponent});
  if (ppRef) {
    const [componentRef] = await editorSDK.document.components.findAllByRole('token', {
      controllerRef: ppRef,
      role,
    });
    return componentRef;
  }
  return null;
};

export const getCompPropsByRole = async (editorSDK: EditorSDK, role: string) => {
  const componentRef = await getCompRefByRole(editorSDK, role);
  return componentRef ? editorSDK.components.properties.get('token', {componentRef}) : null;
};

export const mapPresetToRTL = (preset: string) => {
  return `RTL ${preset}`;
};

export const enum PresetAlign {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

export const enum PresetDirection {
  Ltr = 'ltr',
  Rtl = 'rtl',
}

export const ltrThumbnail = {
  id: 'dataItem-ll4484ct1',
  type: 'SVGItem',
  svgId:
    '<?xml version="1.0" encoding="UTF-8"?>\r\n<svg data-bbox="41.617 18 31.147 16" viewBox="0 0 114 51" height="51" width="114" xmlns="http://www.w3.org/2000/svg" data-type="color">\n    <g>\n        <path d="m41.723 32.552 2.83-5.658a2.002 2.002 0 0 0 0-1.788l-2.83-5.658A1 1 0 0 1 42.618 18h5.764a1 1 0 0 1 .894.553l3.277 6.553a2.002 2.002 0 0 1 0 1.788l-3.277 6.553a1 1 0 0 1-.894.552h-5.764a1 1 0 0 1-.895-1.447z" fill="#3885FF" data-ds-class="illus-clr-2" data-color="1"/>\n        <path d="m54.553 26.894-2.83 5.658A1 1 0 0 0 52.618 34h5.764a1 1 0 0 0 .894-.552l3.277-6.553a2.002 2.002 0 0 0 0-1.788l-3.277-6.553a1 1 0 0 0-.894-.553h-5.764a1 1 0 0 0-.895 1.448l2.83 5.658a2.002 2.002 0 0 1 0 1.788z" fill="#80B1FF" data-ds-class="illus-clr-1" data-color="2"/>\n        <path d="m64.553 26.894-2.83 5.658A1 1 0 0 0 62.618 34h5.764a1 1 0 0 0 .894-.552l3.277-6.553a2.002 2.002 0 0 0 0-1.788l-3.277-6.553a1 1 0 0 0-.894-.553h-5.764a1 1 0 0 0-.895 1.448l2.83 5.658a2.002 2.002 0 0 1 0 1.788z" fill="#80B1FF" data-ds-class="illus-clr-1" data-color="2"/>\n    </g>\n</svg>\n',
  metaData: {
    isHidden: false,
    sig: 'ibg-79',
    schemaVersion: '1.0',
    isPreset: false,
  },
};

export const rtlThumbnail = {
  id: 'dataItem-ll4484ct3',
  type: 'SVGItem',
  svgId:
    '<?xml version="1.0" encoding="UTF-8"?>\r\n<svg data-bbox="40.236 18 31.147 16" viewBox="0 0 114 51" height="51" width="114" xmlns="http://www.w3.org/2000/svg" data-type="color">\n    <g>\n        <path d="m71.277 32.552-2.83-5.658a2.002 2.002 0 0 1 0-1.788l2.83-5.658A1 1 0 0 0 70.382 18h-5.764a.998.998 0 0 0-.894.553l-3.277 6.553a2.002 2.002 0 0 0 0 1.788l3.277 6.553a1 1 0 0 0 .894.552h5.764a1 1 0 0 0 .895-1.447z" fill="#3885FF" data-ds-class="illus-clr-2" data-color="1"/>\n        <path d="m48.447 26.894 2.83 5.658A1 1 0 0 1 50.382 34h-5.764a1 1 0 0 1-.894-.552l-3.277-6.553a2.002 2.002 0 0 1 0-1.788l3.277-6.553a.998.998 0 0 1 .894-.553h5.764a1 1 0 0 1 .895 1.448l-2.83 5.658a2.002 2.002 0 0 0 0 1.788z" fill="#80B1FF" data-ds-class="illus-clr-1" data-color="2"/>\n        <path d="m58.447 26.894 2.83 5.658A1 1 0 0 1 60.382 34h-5.764a1 1 0 0 1-.894-.552l-3.277-6.553a2.002 2.002 0 0 1 0-1.788l3.277-6.553a.998.998 0 0 1 .894-.553h5.764a1 1 0 0 1 .895 1.448l-2.83 5.658a2.002 2.002 0 0 0 0 1.788z" fill="#80B1FF" data-ds-class="illus-clr-1" data-color="2"/>\n    </g>\n</svg>\n',
  metaData: {
    isHidden: false,
    sig: 'ibg-77',
    schemaVersion: '1.0',
    isPreset: false,
  },
};

export const rightAlignThumbnail = {
  id: 'dataItem-ll2a4lsm5',
  type: 'SVGItem',
  svgId:
    '<?xml version="1.0" encoding="UTF-8"?>\r\n<svg data-bbox="10 9 17 20" viewBox="0 0 38 38" height="38" width="38" xmlns="http://www.w3.org/2000/svg" data-type="color">\n    <g data-ds-class="Thumbnails_Text Alignment/Align Right">\n        <path d="M12 27h9a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2h-9a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2Z" fill="#80B1FF" data-ds-class="illus-clr-1" data-color="1"/>\n        <path d="M21 18h-6a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2Zm4 11h2V9h-2v20Z" fill="#116DFF" data-ds-class="illus-clr-2" data-color="2"/>\n    </g>\n</svg>\n',
  metaData: {
    schemaVersion: '1.0',
    isPreset: false,
    isHidden: false,
    sig: 'wm5-23',
  },
};
