import {ConnectedComponentsBuilder} from '../editor.app.types';

export const setDropdownDesignPanelsWithHiddenErrorState = (innerWidgetBuilder: ConnectedComponentsBuilder) => {
  innerWidgetBuilder.panel<'Dropdown'>('design').configureStates({error: {hidden: true}} as any); // https://jira.wixpress.com/browse/ECL-5987
  innerWidgetBuilder.panel<'Dropdown'>('design').configureControls({
    fntprefix: {
      hidden: true,
    },
    txtlbl: {
      hidden: true,
    },
    fntlbl: {
      hidden: true,
    },
    txtlblrq: {
      hidden: true,
    },
  } as any);
};
