import {WidgetBuilder} from '../../../editor.app.types';
import {getRole} from '../../global-design/getRole';

export const setComponentsAsUnselectable = (
  componentsIds: {[key: string]: string} | string[],
  widgetBuilder: WidgetBuilder,
) => {
  for (const componentId in componentsIds) {
    if (componentsIds[componentId]) {
      widgetBuilder.configureConnectedComponents(getRole(componentsIds[componentId]), (innerWidgetBuilder) => {
        innerWidgetBuilder.behavior().set({
          closed: {
            selectable: false,
            hideFromHierarchy: true,
          },
        });
      });
    }
  }
};
