import {SPECS} from '../../specs';
import {EventType, FlowAPI, FlowEditorSDK} from '../editor.app.types';

export const registerDeviceTypeChangedEvents = async (editorSDK: FlowEditorSDK, flowAPI: FlowAPI): Promise<void> => {
  if (flowAPI.experiments.enabled(SPECS.RenderOnDeviceViewChanged)) {
    const refreshLivePreview = () =>
      editorSDK.application.livePreview.refresh('token', {
        shouldFetchData: false,
        source: 'AFTER_SWITCH_DEVICE_TYPE',
      });
    await editorSDK.addEventListener(EventType.switchedToDesktopView, refreshLivePreview);
    await editorSDK.addEventListener(EventType.switchedToMobileView, refreshLivePreview);
  }
};
